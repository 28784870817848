import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './backend/layouts/Navbar'
import Sidebar from './backend/layouts/Sidebar'

const AdminLayout = () => {
  useEffect(() => {
    // Helper function to add a stylesheet
    const addStylesheet = (href) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = href
      document.head.appendChild(link)
    }

    // Helper function to add a script
    const addScript = (src) => {
      const script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }

    // Adding stylesheets
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/bootstrap.min.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/app.min.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/style.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/components.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/custom.css`)

    // Adding scripts
    addScript(`${process.env.PUBLIC_URL}/admin_assets/js/bootstrap.min.js`)
    addScript(`${process.env.PUBLIC_URL}/admin_assets/js/app.min.js`)
    addScript(`${process.env.PUBLIC_URL}/admin_assets/js/page/index.js`)
    addScript(`${process.env.PUBLIC_URL}/admin_assets/js/scripts.js`)
    addScript(`${process.env.PUBLIC_URL}/admin_assets/js/custom.js`)
    addScript(`${process.env.PUBLIC_URL}/admin_assets/bundles/apexcharts/apexcharts.min.js`)

    // Cleanup on component unmount
    return () => {
      // Cleanup stylesheets
      document.head.querySelectorAll('link').forEach(link => {
        if (link.href.includes('/assets/css/')) {
          document.head.removeChild(link)
        }
      })

      // Cleanup scripts
      document.body.querySelectorAll('script').forEach(script => {
        if (script.src.includes('/assets/js/')) {
          document.body.removeChild(script)
        }
      })
    }
  }, [])

  return (
    <div>
        <Navbar />
        <Sidebar />
        <Outlet />
    </div>
  )
}

export default AdminLayout
