import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import swal from 'sweetalert';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { SetPermission } from '../../../../auth/SetPermission';


const CaseList = () => {

  const userPermission = SetPermission();
    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [termslist, setTerms] = useState([]);
  
    useEffect(() => {
        http.get('/get-case-study')
            .then(res => { 
                setTerms(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);
  
    useEffect(() => {
        if (termslist.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        }
    }, [termslist]);

    // delete category
    const handleDeleteCategory = (deleteID) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this item!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-case-study/${deleteID}`)
                    .then(response => {
                        if (response.data.status !== 201) {
                            setTerms(prevCategories => prevCategories.filter(category => category.id !== deleteID));
                        }
    
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                    })
                    .catch(error => {
                        swal('Oops! Something went wrong while deleting the item.', {
                            icon: 'error',
                        });
                    });
            } else {
                swal('The item is safe!');
            }
        });
    };


  return (
    <div>
      <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h5>Case Study List</h5>
                                        <div className="text-end">
                                            <Link to='/admin/pages/case-study-add' className="btn btn-primary btn-sm">Add New Case</Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped" id="table-1" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">#</th>
                                                    <th>Case Category Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {termslist.map((termslist, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{termslist.category}</td> {/* Display category name */}
                                                        <td>
                                                            {userPermission.includes('caseStudy_edit') && (<Link to={`/admin/pages/case-study-edit/${termslist.id}`} className="editbtn mr-1">
                                                                    <button className="btn btn-warning">
                                                                        <i className="fas me-2 fa-edit text-white"></i>
                                                                    </button>
                                                                </Link>
                                                                )}
                                                                {userPermission.includes('caseStudy_delete') && (<Link to='' className="editbtn">
                                                                    <button className="btn btn-danger" onClick={() => handleDeleteCategory(termslist.id)}>
                                                                        <i className="fas me-2 fa-trash text-white"></i>
                                                                    </button>
                                                                </Link>
                                                                )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default CaseList