import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import BackButton from '../../../tools/BackButton'
import swal from 'sweetalert';

const CreatePermission = () => {

    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');

    const [selectedGroupName, setSelectedGroupName] = useState("");
    const [newGroupName, setNewGroupName] = useState("");
    const [newGroupNameError, setNewGroupNameError] = useState("");
    const [permissionGroupName, setPermissionGroupName] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [tagArray, setTagArray] = useState([]);

    const handlePermissionChange = (e) => {
        setPermissions(e.target.value);
        // Split the input value by comma and trim each tag
        const tags = e.target.value.split(',').map(tag => tag.trim());
        setTagArray(tags);
        setPermissions(tags);
      };

    useEffect(() => {
        http.get('/get-all-permission')
          .then(res => { 
            const uniqueGroupNames = new Set(res.data.map(permission => permission.group_name));
            setPermissionGroupName(uniqueGroupNames);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
      }, []);

     

    const submitform = (history) => {
        setLoader('on');
        const formData = new FormData();
        formData.append('group_name_selected', selectedGroupName);
        formData.append('group_name_new', newGroupName);
        permissions.forEach(permissionsName => {
            formData.append('permissions[]', permissionsName);
        });
        http.post('/add-permission', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then(res => {
          if (res.data.status === '422') {
            setLoader('off');
            const errors = res.data.error;
            setNewGroupNameError(errors ? errors : '');
          } else {
            setLoader('off');
            setNewGroupName(""); 
            setSelectedGroupName(""); 
            setTagArray([null]);
            setPermissions([null]);
            swal('Success', res.data.msg, 'success');
          }
        }).catch(error => {
          console.error('Error:', error);
        });
      };

      const handleSelectChange = (e) => {
        setSelectedGroupName(e.target.value);
        setNewGroupName(""); 
      };
      
      const handleInputChange = (e) => {
        setNewGroupName(e.target.value);
        setSelectedGroupName(""); 
      };

  return (
    <div>
    <div className="main-content">
        <section className="section">
        <div className="section-body">
            <div className="row">
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h5>Create New Permission</h5>
                            <div className="">
                                <BackButton/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="card-body pb-0 px-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className='text-danger'>{newGroupNameError}</span>
                                        <div className="form-group">
                                            <label>Select Group </label>
                                            <div className="d-flex align-items-center">
                                                <select
                                                    value={selectedGroupName}
                                                    onChange={handleSelectChange}
                                                    className="form-control me-2"
                                                >
                                                    <option value="" disabled selected>Select an existing group</option>
                                                    {[...permissionGroupName].map((groupName, index) => (
                                                        <option key={index} value={groupName}>
                                                            {groupName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Or Create new Group</label>
                                            <input
                                                type="text"
                                                value={newGroupName}
                                                placeholder="Or type a new group"
                                                className="form-control"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Permission Names</label>
                                        <input
                                            value={permissions}
                                            className="form-control ms-0"
                                            onChange={handlePermissionChange}
                                            placeholder="Enter tags separated by comma"
                                        />
                                        <div>
                                            <p>
                                            {tagArray.map((tag, index) => (
                                                <span key={index}>{index}. {tag } </span>
                                            ))}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>

                        <div className="text-center">
                            <label className="col-form-label"></label>
                            {loader === 'off' &&
                                <button type="submit" onClick={submitform} className="btn btn-primary">Create</button>
                            }
                            {loader === 'on' &&
                                <div class="spinner-border text-dark" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    </div>
</div>
  )
}

export default CreatePermission