import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {ServerUrl} from '../../../../../api/ServerUrl';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const TestimoniulEdit = () => {
    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const { id } = useParams();
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [review, setReview] = useState('');
    const [image, setImage] = useState(null);
    const [testStatus, setTestStatus] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [loader, setLoader] = useState('off');
    const [nameError, setNameError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [reviewError, setReviewError] = useState('');

    useEffect(() => {
        // Fetch testimonial data to be edited
        http.get(`/get-single-testimoniul/${id}`)
            .then(res => {
                setName(res.data.name);
                setTitle(res.data.title);
                setReview(res.data.review);
                setTestStatus(res.data.status);
                const bannerImage = res.data.image;
                if (bannerImage) {
                    const userImageShow = `${httpCPage}/backend/img/testimoniul/${bannerImage}`;
                    setImagePreview(userImageShow);
                }
            })
            .catch(error => {
                console.error('Error fetching testimonial:', error);
            });
    }, [id]); // Dependency array ensures useEffect runs only when id changes

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('name', name);
        formData.append('title', title);
        formData.append('review', review);
        formData.append('image', image);
        formData.append('status', testStatus);

        http.post(`/update-testimoniul/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                const errors = res.data.error;
                setNameError(errors.name ? errors.name[0] : '');
                setTitleError(errors.title ? errors.title[0] : '');
                setReviewError(errors.review ? errors.review[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const handleStatusChange = (e) => {
        const { value } = e.target;
        setTestStatus(value);
    };

    return (
        <div>
            <div className="main-content">
                <section className="section userr">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-2">
                                            <h5>Edit Testimonial</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3 text-center mt-3">
                                                <label className='' htmlFor="userImage">
                                                    <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                                        {imagePreview ? (
                                                            <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                        ) : (
                                                            <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                                        )}
                                                    </div>

                                                    <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Image</span>
                                                </label>
                                            </div>

                                            <div className="col-md-9">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-4 p-1">
                                                            <label className="col-form-label">Name</label>
                                                            <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                                                            <span className='text-danger'>{nameError}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-4 p-1">
                                                            <label className="col-form-label">Title</label>
                                                            <input type="text" className="form-control" value={title} onChange={e => setTitle(e.target.value)} />
                                                            <span className='text-danger'>{titleError}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <select name="status" id="status" className='form-control' value={testStatus} onChange={handleStatusChange}>
                                                                <option value="" disabled>Select</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">De-Active</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group row mb-4 p-1">
                                                            <label className="col-form-label">Review</label>
                                                            <textarea className="form-control" value={review}  onChange={e => setReview(e.target.value)}></textarea>
                                                            <span className='text-danger'>{reviewError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ? (
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            ) : (
                                                <div className="spinner-border text-dark" role="status"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default TestimoniulEdit;
