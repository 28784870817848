import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../tools/BackButton';

const NoteAdd = () => {
    const { http } = ProtectAPi();
    const navigate = useNavigate();
    const [loader, setLoader] = useState('off');
    const [note, setNote] = useState('');
    const [noteError, setNoteError] = useState('');

    const submitForm = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        setLoader('on');
        const formData = new FormData();
        formData.append('note', note);

        http.post(`/note-add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setNoteError(errors.note ? errors.note[0] : '');
            } else {
                setLoader('off');
                navigate('/admin/pages/notice');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
            setLoader('off');
            swal('Error', 'An error occurred. Please try again later.', 'error');
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'note') {
            setNote(value);
        }
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Add Notice</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <form onSubmit={submitForm}> {/* Added form element */}
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Notice</label>
                                                            <textarea name="note" value={note} onChange={handleInputChange} className="form-control" rows="3"></textarea>
                                                            <span className='text-danger'>{noteError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-5">
                                                <label className="col-form-label"></label>
                                                {loader === 'off' &&
                                                    <button type="submit" className="btn btn-primary">Create</button>
                                                }
                                                {loader === 'on' &&
                                                    <div className="spinner-border text-dark" role="status"></div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NoteAdd;
