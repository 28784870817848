import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import {ServerUrl} from '../../../../api/ServerUrl';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import BackButton from '../../../tools/BackButton';

const NoteEdit = () => {
    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const { id } = useParams();
    const [loader, setLoader] = useState('off');
    const [note, setNote] = useState('');
    const [status, setStatus] = useState('');
    const [noteError, setNoteError] = useState('');

    useEffect(() => {
        http.get(`/get-single-note/${id}`)
            .then(res => { 
              setNote(res.data.note);
              setStatus(res.data.status);
            })
            .catch(error => {
                console.error('Error fetching permissions:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('note', note);
        formData.append('status', status);

        http.post(`/update-note/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setNoteError(errors.note ? errors.note[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'note') {
          setNote(value);
        }
    };

    const handleStatusChange = (e) => {
        const { value } = e.target;
        setStatus(value);
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Update Notice</h5>
                                            <div className="">
                                                <BackButton/>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Notice</label>
                                                        <textarea name="note" value={note} onChange={handleInputChange} className="form-control" rows="3"></textarea>
                                                        <span className='text-danger'>{noteError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <select name="status" id="status" className='form-control' value={status} onChange={handleStatusChange}>
                                                            <option value="" disabled>Select</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">De-Active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' &&
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            }
                                            {loader === 'on' &&
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NoteEdit;