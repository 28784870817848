import React, { useEffect } from 'react'
import LoginTo from './auth/LoginTo'

const AuthLayout = () => {
  useEffect(() => {
    // Helper function to add a stylesheet
    const addStylesheet = (href) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = href
      document.head.appendChild(link)
    }

    // Helper function to add a script
    const addScript = (src) => {
      const script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }

    // Adding stylesheets
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/bootstrap.min.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/app.min.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/style.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/components.css`)
    addStylesheet(`${process.env.PUBLIC_URL}/admin_assets/css/custom.css`)

   

    // Cleanup on component unmount
    return () => {
      // Cleanup stylesheets
      document.head.querySelectorAll('link').forEach(link => {
        if (link.href.includes('/assets/css/')) {
          document.head.removeChild(link)
        }
      })

      // Cleanup scripts
      document.body.querySelectorAll('script').forEach(script => {
        if (script.src.includes('/assets/js/')) {
          document.body.removeChild(script)
        }
      })
    }
  }, [])
  return (
    <div>
      <div className="main-content">
          <LoginTo/>
      </div>
    </div>
  )
}

export default AuthLayout
