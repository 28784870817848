import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../api/Api';
import { ServerUrl } from '../../api/ServerUrl';



const Navbar = () => {
 const httpCPage = ServerUrl;
 const [imagePreview, setImagePreview] = useState(null, true);
 const { http } = Api();
 const [userData, setUserData] = useState([]);
 useEffect(() => {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        setUserData(user);
        const userImageShow = httpCPage + '/backend/user_image/' + user.image;
        if(user.image){
          setImagePreview(userImageShow);
        }
        
      }
  }, []);

  const logOut = () => {
     
    http.get('/admin-log-out')
      .then(res => { 
        if(res.data.status === '200'){
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
        }
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };


  return (
    <div>
        <div className="navbar-bg"></div>
        <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li><Link to='/' data-toggle="sidebar" className="nav-link nav-link-lg
									collapse-btn"> <i className="fa fa-bars text-dark"></i></Link></li>
            <li><Link to='/' className="nav-link nav-link-lg fullscreen-btn">
                <i className="fa fa-expand text-dark"></i>
              </Link></li>
            <li>
              <form className="form-inline mr-auto">
                <div className="search-element">
                  <input className="form-control" type="search" placeholder="Search" aria-label="Search" data-width="200" />
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li>
          </ul>
        </div>
        
        <ul className="navbar-nav navbar-right">
          <li className="dropdown"><Link data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                 {imagePreview !== null ? (
                  <img src={imagePreview} className='user-img-radious-style' />
                  ) : (
                  <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='user-img-radious-style' />
                  )}
                 <span className="d-sm-none d-lg-inline-block"></span></Link>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Hello {userData.name}</div>
              <Link to='/admin/users-profile' className="dropdown-item has-icon"> <i className="far
										fa-user"></i> Profile
              </Link> 
              <div className="dropdown-divider"></div>
              <button type="button" onClick={logOut} className="dropdown-item has-icon text-danger"><i className="fas fa-sign-out-alt mt-2"></i>Logout</button>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar;