import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { Api } from '../../api/Api';
import { FrontUrl } from '../../api/FrontUrl';
import { ServerUrl } from '../../api/ServerUrl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaStar } from 'react-icons/fa';
import FadeInOut from '../../tools/FadeInOut';

const ProductDetails = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Product Details';
      }, []);


    const httpCPage = ServerUrl;
    const { http } = Api();
    const { id } = useParams();
    const [productsDetails, setProductDetails] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [productReviews, setProductReviews] = useState([]);
    const [activeTab, setActiveTab] = useState('#prod-reviews');
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get(`/front-get-single-product/${id}`)
            .then(res => { 
                setProductDetails(res.data.products);
                setProductImages(res.data.images);
                setProductReviews(res.data.reviews);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, [id]); 

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');
      const [rating, setRating] = useState(0);
      const [ratingError, setRatingError] = useState('');
      const [nameError, setNameError] = useState('');
      const [emailError, setEmailError] = useState('');
      const [messageError, setMessageError] = useState('');
      const [loader, setLoader] = useState('off');

      const handleSubmit = (e) => {
            setLoader('on');
            e.preventDefault();
            // Clear previous errors
            setNameError('');
            setEmailError('');
            setMessageError('');
            setRatingError('');
            if (!name) {
                setNameError('Name is required');
                setLoader('off');
                return;
            }
            if (!email) {
                setEmailError('Email is required');
                setLoader('off');
                return;
            }
            if (!message) {
                setMessageError('Message is required');
                setLoader('off');
                return;
            }
            if (!rating) {
                setRatingError('Ratting is required');
                setLoader('off');
                return;
            }

            // Form data object
            const formData = {
                name: name,
                email: email,
                message: message,
                rating: rating
            };

            // Send form data to server
            http.post(`/front-review-product/${id}`, formData)
                
                .then((res) => {
                    setLoader('off');
                    swal('Thank You', res.data.msg, 'success');
                    setName('');
                    setEmail('');
                    setMessage('');
                    setRating('');
                })
                .catch((error) => {
                    setLoader('off');
                    console.error('Error submitting form:', error);
                });
        };

        const handleStarClick = (selectedRating) => {
            setRating(selectedRating);
            console.log(rating)
        };

        const formatTimeDifference = (dateString) => {
            const now = new Date();
            const pastDate = new Date(dateString);
            const timeDifference = now - pastDate;
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
    
            if (days > 0) {
                return `${days} days ago`;
            } else if (hours > 0) {
                return `${hours} hours ago`;
            } else if (minutes > 0) {
                return `${minutes} minutes ago`;
            } else {
                return `a few seconds ago`;
            }
        };

        const formatDate = (dateString) => {
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', options);
        };

       
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const sliderRef = useRef(null);

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
        sliderRef.current.slickGoTo(index);
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        beforeChange: (current, next) => setCurrentImageIndex(next),
      };

        
        
        

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Product Details</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Product Details</li>
                    </ul>
                </div>
            </section>

            <section className="shop-single-section">
                <div className="auto-container">
                    <div className="shop-single">
                        <div className="product-details">
                            {/* Basic Details */}
                            <div className="basic-details">
                                <div className="row clearfix">
                                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                <FadeInOut>
                                    <figure className="image-box border">
                                    <div className="product-image-carousel">
                                        <div className='ProductDetailsImage'>
                                        {productImages.length === 1 ? (
                                            <div>
                                            <img 
                                                src={`${httpCPage}/backend/img/product/${productImages[0].image}`} 
                                                alt="Product Image" 
                                                className='img-fluid' 
                                            />
                                            </div>
                                        ) : (
                                            <Slider {...settings} ref={sliderRef}>
                                            {productImages.map((image, index) => (
                                                <div key={index}>
                                                <img 
                                                    src={`${httpCPage}/backend/img/product/${image.image}`} 
                                                    alt={`Product Image ${index}`} 
                                                    className='img-fluid' 
                                                />
                                                </div>
                                            ))}
                                            </Slider>
                                        )}
                                        </div>
                                        {productImages.length === 1 ? (
                                        <div></div>
                                        ) : (
                                            <div className="thumbnail-carousel border-top">
                                            {productImages.map((image, index) => (
                                            <div 
                                                key={index} 
                                                onClick={() => handleThumbnailClick(index)} 
                                                className={`thumbnail-item ${currentImageIndex === index ? 'active' : ''}`}
                                            >
                                                <img 
                                                src={`${httpCPage}/backend/img/product/${image.image}`} 
                                                alt={`Thumbnail ${index}`} 
                                                />
                                            </div>
                                            ))}
                                        </div>
                                        )}
                                    </div>
                                    </figure>
                                </FadeInOut>
                                </div>
                                    
                                    <div className="info-column col-lg-6 col-md-12 col-sm-12">
                                    <FadeInOut>
                                        <div className="inner-column">
                                           

                                            <div className="product-info-tabs border border-bottom-none">
                                                <div className="prod-tabs tabs-box">
                                                    {/* Tab Btns */}
                                                    {/* <ul className="tab-btns tab-buttons clearfix">
                                                        <li onClick={() => handleTabClick('#prod-details')} className={`tab-btn ${activeTab === '#prod-details' ? 'active-btn' : ''}`}>Description</li>
                                                    </ul> */}
                                                    <div className="tabs-content cusTab">
                                                        <div className={`tab ${activeTab === '#prod-reviews' ? 'active-tab' : ''}`} id="prod-details">
                                                            <div className="content">
                                                                <h4>{productsDetails.product_name}</h4>
                                                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: productsDetails.description }}></div>
                                                                <div className="other-options clearfix">
                                                                    <button type="button" className="theme-btn cart-btn">Add to cart</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </FadeInOut>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* Product Info Tabs */}
                            <FadeInOut>
                            <div className="product-info-tabs">
                                {/* Product Tabs */}
                                <div className="prod-tabs tabs-box">
                                    {/* Tab Btns */}
                                    <ul className="tab-btns tab-buttons clearfix">
                                        <li onClick={() => handleTabClick('#prod-reviews')} className={`tab-btn ${activeTab === '#prod-reviews' ? 'active-btn' : ''}`}>Review  ({productReviews.length})</li>
                                    </ul>
                                    {/* Tabs Container */}
                                    <div className="tabs-content">
                                        <div className={`tab ${activeTab === '#prod-reviews' ? 'active-tab' : ''}`} id="prod-reviews">
                                            {(productReviews).map((review, index) => ( 
                                                <div key={index} className="comments-area">
                                                    <div className="comment-box">
                                                        <div className="comment">
                                                            <div className="row">
                                                                <div className="col-2 col-md-1">
                                                                    <div className="author-thumb"><img src={`${FrontUrl}/assets/images/resource/review.png`} alt="" /></div>
                                                                </div>
                                                                <div className="col-10 col-md-11">
                                                                    <div className="comment-inner">
                                                                        <div className="comment-info clearfix">{review.name}</div>
                                                                        <small>{formatTimeDifference(review.created_at)} - {formatDate(review.created_at)}</small>
                                                                        <div className="rating">
                                                                        {Array.from({ length: review.rating }, (_, i) => i + 1).map((ratingValue) => (
                                                                            <FaStar
                                                                                className={'active activeRatting'}
                                                                            />
                                                                        ))}
                                                                        </div>
                                                                        <div className="text">{review.comments}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}                                     
                                            <div className="shop-comment-form">
                                            <h2>Add Your Review</h2>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row clearfix">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                                                       <div className="text-center">
                                                        <h4 className='fw-bold'>Rate it!</h4>
                                                        <div className="star-rating mb-4">
                                                                {Array.from({ length: 5 }, (_, i) => i + 1).map((ratingValue) => (
                                                                    <FaStar
                                                                        key={ratingValue}
                                                                        className={ratingValue <= rating ? 'active' : ''}
                                                                        onClick={() => handleStarClick(ratingValue)}
                                                                    />
                                                                ))}
                                                            </div>
                                                            {ratingError && <span className="error text-danger">{ratingError}</span>}
                                                       </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                                        <label>Name *</label>
                                                        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name"  />
                                                        {nameError && <span className="error text-danger">{nameError}</span>}
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                                        <label>Email *</label>
                                                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email"  />
                                                        {emailError && <span className="error text-danger">{emailError}</span>}
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                                                        <label>Your Comments *</label>
                                                        <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter your message"  />
                                                        {messageError && <span className="error text-danger">{messageError}</span>}
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                                                        <div className="text-center">
                                                            {loader === 'off' ? (
                                                                <button className="theme-btn btn-style-four" type="submit"><span className="txt">Submit now</span></button>
                                                            ) : (
                                                                <div className="spinner-border text-dark mt-4" role="status"></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </FadeInOut>
                            {/* End Product Info Tabs */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProductDetails;
