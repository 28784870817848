import React from 'react';
import { Link } from 'react-router-dom';
import FadeInOut from '../tools/FadeInOut';
import { FrontUrl } from '../api/FrontUrl';

const AppoinmentHome = () => {

    return (
        <div>
             <FadeInOut>
            <section className="apoinment-section">
                <div className="auto-container">
                    <div className="container pb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ApoinementImgBox">
                                    <img src={`${FrontUrl}/assets/images/bannerSvg/apoinemnt.svg`} className='ApoinmentImg' alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="apoinemnt-text-box">
                                    <h1>You can now book appointments online! Provide your details and confirm your booking. It's that easy!</h1>
                                    <div className="btns-box mt-2">
                                        <Link to="/book-appointment" className="theme-btn banner-button">
                                            <span className="txt me-3">Book Appointment</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </section>
            </FadeInOut>
        </div>
    );
}

export default AppoinmentHome;