import React from 'react'

const AdminHome = () => {
  return (
    <div>
        <section className="section">
            <p>ok</p>
        </section>
    </div>
  )
}

export default AdminHome