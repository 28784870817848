import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import {ServerUrl} from '../../../../api/ServerUrl';
import BackButton from '../../../tools/BackButton'
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const WebInfo = () => {

    const [id, setId] = useState('');
    const httpCPage = ServerUrl;
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [settings, setSettings] = useState({
        siteName: '',
        number: '',
        email: '',
        address: '',
        map_link: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        skype: '',
        shortDescription: '',
        logo: '',
        tab_logo: ''
    });
    const [siteNameError, setSiteNameError] = useState('');
    const [logo, setLogo] = useState(null);
    const [tab_logo, setFabLogo] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [imageFabPreview, setImageFavPreview] = useState('');

    useEffect(() => {
        http.get('/get-setting')
            .then(res => {
                const settingData = res.data[0];
                setSettings(settingData);
                setId(settingData.id);
                if (settingData.logo) {
                    const userImageShow = `${httpCPage}/backend/img/${settingData.logo}`;
                    setImagePreview(userImageShow);
                }
                if (settingData.tab_logo) {
                    const userImageShow = `${httpCPage}/backend/img/${settingData.tab_logo}`;
                    setImageFavPreview(userImageShow);
                }
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);

   

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('siteName', settings.siteName);
        formData.append('number', settings.number);
        formData.append('email', settings.email);
        formData.append('address', settings.address);
        formData.append('map_link', settings.map_link);
        formData.append('facebook', settings.facebook);
        formData.append('instagram', settings.instagram);
        formData.append('twitter', settings.twitter);
        formData.append('linkedin', settings.linkedin);
        formData.append('skype', settings.skype);
        formData.append('shortDescription', settings.shortDescription);
        formData.append('logo', logo);
        formData.append('tab_logo', tab_logo);

        http.post(`/update-setting/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setSiteNameError(errors.siteName ? errors.siteName[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setLogo(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const handleImageFabChange = (e) => {
        const selectedImage = e.target.files[0];
        setFabLogo(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImageFavPreview(previewURL);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [activeMenu, setActiveMenu] = useState('');
    
    const toggleMenu = (menu) => {
        if (activeMenu === menu) {
        setActiveMenu('');
        } else {
        setActiveMenu(menu);
        }
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                    <div className="card card-primary">
                                        <div className="card-body">
                                            <div className="row d-flex">
                                                <div className="mb-2">
                                                    <Link to="/admin/settings" className="btn btn-primary btn-sm mr-2">Web Info</Link>
                                                </div>
                                                <div className="mb-2">
                                                    <Link to="/admin/settings/banner-list"  className="btn border btn-sm mr-2">Banner</Link>
                                                </div>
                                                <div className="mb-2">
                                                    <Link to="/admin/settings/footer-list" className="btn border btn-sm mr-2">Footer</Link>
                                                </div>
                                                <div className="mb-2">
                                                    <Link to="/admin/settings/terms-list" className="btn border btn-sm mr-2">Terms & Conditions</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Web Settings</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Site Name</label>
                                                        <input type="text" id="siteName" name="siteName" value={settings.siteName} className="form-control ms-0" onChange={handleInputChange} />
                                                        <span className='text-danger'>{siteNameError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Number</label>
                                                        <input type="number" id="number" name="number" value={settings.number} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="email" id="email" name="email" value={settings.email} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Location</label>
                                                        <input type="text" id="address" name="address" value={settings.address} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Map Url</label>
                                                        <input type="url" id="map_link" name="map_link" value={settings.map_link} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Facebook</label>
                                                        <input type="url" id="facebook" name="facebook" value={settings.facebook} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Instagram</label>
                                                        <input type="url" id="instagram" name="instagram" value={settings.instagram} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Whatsapp</label>
                                                        <input type="url" id="twitter" name="twitter" value={settings.twitter} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Linkedin</label>
                                                        <input type="url" id="linkedin" name="linkedin" value={settings.linkedin} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Youtube</label>
                                                        <input type="url" id="skype" name="skype" value={settings.skype} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Short Description</label>
                                                        <textarea type="text" id="shortDescription" name="shortDescription" value={settings.shortDescription} className="form-control ms-0" onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="logo-box d-flex justify-content-around">
                                                        <div className="logo">
                                                            <label className='' htmlFor="userImage">
                                                                <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                                                    {imagePreview ? (
                                                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                                    ) : (
                                                                            <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                                                        )}
                                                                </div>

                                                                <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Logo</span>
                                                            </label>
                                                        </div>
                                                        <div className="fav-logo">
                                                            <label className='' htmlFor="fabImage">
                                                                <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                                                    {imageFabPreview ? (
                                                                        <img src={imageFabPreview} className='img-fluid' alt="Preview" />
                                                                    ) : (
                                                                            <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                                                        )}
                                                                </div>

                                                                <input type="file" id="fabImage" className="form-control d-none" onChange={handleImageFabChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Tab Logo</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' &&
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            }
                                            {loader === 'on' &&
                                                <div class="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default WebInfo;
