import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import BackButton from '../../../tools/BackButton';
import swal from 'sweetalert';

const ProductCategoryEdit = () => {
    const { id } = useParams();
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');

    const [newGroupName, setNewGroupName] = useState("");
    const [status, setStatus] = useState('');
    const [newGroupNameError, setNewGroupNameError] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");

    useEffect(() => {
        http.get(`/get-single-product-category/${id}`)
            .then(res => {
                setNewGroupName(res.data.permissions.group_name);
                setCategoryName(res.data.permissions.name);
                setStatus(res.data.permissions.home_show);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('group_name', newGroupName);
        formData.append('category_name', categoryName);
        formData.append('home_show', status);

        http.post(`/product-category-update/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                const errors = res.data.error;
                setNewGroupNameError(errors.group_name ? errors.group_name[0] : '');
                setCategoryNameError(errors.category_name ? errors.category_name[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleGroupNameChange = (e) => {
        setNewGroupName(e.target.value);
    };

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value);
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Edit Category</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="card-body pb-0 px-5">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Group Name</label>
                                                            <input
                                                                value={newGroupName}
                                                                className="form-control ms-0"
                                                                placeholder="Group Name"
                                                                onChange={handleGroupNameChange}
                                                            />
                                                            <span className='text-danger'>{newGroupNameError}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Category Name</label>
                                                            <input
                                                                value={categoryName}
                                                                className="form-control ms-0"
                                                                placeholder="Category Name"
                                                                onChange={handleCategoryNameChange}
                                                            />
                                                            <span className='text-danger'>{categoryNameError}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Category Name</label>
                                                            <select
                                                                className="form-control selectric rounded"
                                                                value={status}
                                                                onChange={e => setStatus(e.target.value)}
                                                                >
                                                                <option value="">Select</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">De-Active</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ProductCategoryEdit;
