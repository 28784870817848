import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import BackButton from '../../../tools/BackButton';
import swal from 'sweetalert';

const CreatePermission = () => {
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');

    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryNameError, setNewCategoryNameError] = useState("");
    const [categoryNames, setCategoryNames] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagArray, setTagArray] = useState([]);

    const handlePermissionChange = (e) => {
        const inputValue = e.target.value;
        setTags(inputValue);
        const tags = inputValue.split(',').map(tag => tag.trim());
        setTagArray(tags);
    };

    useEffect(() => {
        http.get('/get-all-product-category')
            .then(res => {
                const uniqueCategoryNames = new Set(res.data.map(data => data.group_name));
                setCategoryNames(uniqueCategoryNames);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('category_name_selected', selectedCategoryName);
        formData.append('category_name_new', newCategoryName);
        tagArray.forEach(tagName => {
            formData.append('categories[]', tagName);
        });
        http.post('/add-product-category', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                const errors = res.data.error;
                setNewCategoryNameError(errors ? errors : '');
            } else {
                setLoader('off');
                setNewCategoryName("");
                setSelectedCategoryName("");
                setTagArray([]);
                setTags("");
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleSelectChange = (e) => {
        setSelectedCategoryName(e.target.value);
        setNewCategoryName("");
    };

    const handleInputChange = (e) => {
        setNewCategoryName(e.target.value);
        setSelectedCategoryName("");
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Create New Permission</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="card-body pb-0 px-5">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span className='text-danger'>{newCategoryNameError}</span>
                                                        <div className="form-group">
                                                            <label>Select Category</label>
                                                            <div className="d-flex align-items-center">
                                                                <select
                                                                    value={selectedCategoryName}
                                                                    onChange={handleSelectChange}
                                                                    className="form-control me-2"
                                                                >
                                                                    <option value="" disabled>Select an existing category</option>
                                                                    {[...categoryNames].map((categoryName, index) => (
                                                                        <option key={index} value={categoryName}>
                                                                            {categoryName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Or Create new Category</label>
                                                            <input
                                                                type="text"
                                                                value={newCategoryName}
                                                                placeholder="Or type a new category"
                                                                className="form-control"
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Category Names</label>
                                                            <input
                                                                value={tags}
                                                                className="form-control ms-0"
                                                                onChange={handlePermissionChange}
                                                                placeholder="Enter tags separated by comma"
                                                            />
                                                            <div>
                                                                <p>
                                                                    {tagArray.map((tag, index) => (
                                                                        <span key={index}>{index}. {tag} </span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default CreatePermission;
