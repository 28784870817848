import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../tools/BackButton';
import Select from 'react-select';

const BackImageAdd = () => {
    const { http } = ProtectAPi();
    const navigate = useNavigate();
    const [loader, setLoader] = useState('off');
    const [layoutImage, setLayoutImage] = useState(null);
    const [layoutImageError, setLayoutImageError] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [pageName, setPageName] = useState(''); // State for page name
    const [pageNameError, setPageNameError] = useState('');

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('image', layoutImage);
        formData.append('page_name', pageName); // Append the page_name

        http.post(`/store-back-image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setLayoutImageError(errors.image ? errors.image[0] : '');
                setPageNameError(errors.page_name ? errors.page_name[0] : ''); // Update to page_name
            } else {
                setLoader('off');
                navigate('/admin/pages/background-image-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setLayoutImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const handlePageNameChange = (selectedOption) => {
        setPageName(selectedOption.value);
    };

    const options = [
        { value: 'About Us', label: 'About Us' },
        { value: 'Mission Vision', label: 'Mission Vision' },
        { value: 'Services', label: 'Services' },
        { value: 'Case Study', label: 'Case Study' },
        { value: 'Products', label: 'Products List' },
        { value: 'Our Factory', label: 'Our Factory' },
        { value: 'Testimoniul', label: 'Testimoniul' },
        { value: 'FHIH', label: 'FHIH' },
        { value: 'Contact Us', label: 'Contact Us' },
        { value: 'Search Result', label: 'Search Result' },
        { value: 'Publications', label: 'Publications' },
        { value: 'Appoinment', label: 'Appoinment' },
        { value: 'Award List', label: 'Award List' },
        { value: 'Blog List', label: 'Blog List' },
    ];

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Background Image </h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-6 mt-4">
                                                    <label>Background Image</label>
                                                    <small className='d-block'>Image size 1500*300 px</small>
                                                    <div className="logo-box mt-3">
                                                        <div className="logo">
                                                            <label className='' htmlFor="layoutImage">
                                                                <div className="image-box border dottet p-2 rounded">
                                                                    {imagePreview ? (
                                                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                                    ) : (
                                                                        <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='img-fluid' alt="Default" />
                                                                    )}
                                                                </div>
                                                                <input type="file" id="layoutImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Image</span>
                                                                <span className='text-danger'>{layoutImageError}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Page Name</label>
                                                        <Select
                                                            options={options}
                                                            onChange={handlePageNameChange} // Handle page name change
                                                        />
                                                        <span className='text-danger'>{pageNameError}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default BackImageAdd;
