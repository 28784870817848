import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../tools/BackButton';

const ProductAdd = () => {
    const { http } = ProtectAPi();
    const editor = useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState('off');
    const [productName, setProductName] = useState('');
    const [layoutDescription, setLayoutDescription] = useState('');
    const [layoutImages, setLayoutImages] = useState([]);
    const [layoutImageErrors, setLayoutImageErrors] = useState([]);
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [categoryNames, setCategoryNames] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState('');

    useEffect(() => {
        http.get('/get-all-product-category')
            .then(res => {
                const uniqueCategoryNames = new Set(res.data.map(data => data));
                setCategoryNames(Array.from(uniqueCategoryNames));
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('product_name', productName);
        formData.append('description', layoutDescription);
        layoutImages.forEach(image => formData.append('images[]', image.file));
        formData.append('category_name', selectedCategoryName);

        http.post(`/store-product`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setTitleError(errors.product_name ? errors.product_name[0] : '');
                setDescriptionError(errors.description ? errors.description[0] : '');
                setLayoutImageErrors(errors.images ? errors.images : []);
            } else {
                setLoader('off');
                navigate('/admin/product-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImages = e.target.files;
        const imagesArray = Array.from(selectedImages).map(file => ({
            file: file,
            preview: URL.createObjectURL(file)
        }));
        setLayoutImages(imagesArray);
        setLayoutImageErrors([]);
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...layoutImages];
        updatedImages.splice(index, 1);
        setLayoutImages(updatedImages);
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'productName') {
            setProductName(value);
        }
    };

    const handleSelectChange = (e) => {
        setSelectedCategoryName(e.target.value);
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Add Products</h5>
                                            <div className="">
                                                <BackButton/>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <label>Product Images</label>
                                                </div>
                                                {layoutImages.map((image, index) => (
                                                    <div key={index} className="col-md-2 mt-4">
                                                        <div className="image-box">
                                                            <img src={image.preview} className='img-thumbnail img-fluid' alt={`Preview ${index}`} />
                                                            <button onClick={() => handleImageRemove(index)} className="btn-close remove-image-button"></button>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col-md-12 mt-4">
                                                    <label className='' htmlFor="layoutImages">
                                                        <input type="file" id="layoutImages" className="form-control d-none" onChange={handleImageChange} multiple />
                                                        <span className='btn btn-primary mt-1 w-100'>Upload Product Images</span>
                                                        {layoutImageErrors.map((error, index) => (
                                                            <span key={index} className='text-danger'>{error}</span>
                                                        ))}
                                                    </label>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <div className="form-group">
                                                        <label>Select Category</label>
                                                        <select
                                                            value={selectedCategoryName}
                                                            onChange={handleSelectChange}
                                                            className="form-control"
                                                        >
                                                            <option value="" disabled>Select a category</option>
                                                            {categoryNames.map((categoryName, index) => (
                                                                <option key={index} value={categoryName.id}>
                                                                    {categoryName.name}  ( {categoryName.group_name} )
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <div className="form-group mt-4">
                                                        <label>Product Name</label>
                                                        <input type="text" name="productName" value={productName} className="form-control ms-0" placeholder='Product Name' onChange={handleInputChange} />
                                                        <span className='text-danger'>{titleError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <label>Description</label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={layoutDescription}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setLayoutDescription(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{descriptionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ProductAdd;
