import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Api } from '../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../api/ServerUrl';
import FadeInOut from '../tools/FadeInOut';

const TestimoniulShow = () => {
    const { http } = Api();
    const httpCPage = ServerUrl;
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        http.get(`/front-get-testimoniul`)
            .then(res => { 
                if (res.data.reviews && res.data.reviews.length > 0) {
                    setTestimonials(res.data.reviews);
                }
            })
            .catch(error => {
                console.error('Error fetching testimonial information:', error);
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Show 2 items by default
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 768, // Adjust based on your desired breakpoint
                settings: {
                    slidesToShow: 1, // Show 1 item on small screens
                }
            }
        ]
    };

    return (
        <div>
             
            <section className="testimonial-section">
                <div className="auto-container">
                <FadeInOut>
                    <div className="sec-title-two centered">
                        <h2 className='cusColorTitle'>What our customers say</h2>
                        <div className="title-text">Thousands of people have benefited from Foot Balance</div>
                    </div>
                </FadeInOut>
                <FadeInOut>
                    <Slider {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-block p-2 rounded">
                                <div className="inner-box  rounded">
                                    <div className="content  rounded">
                                        <div className="image-outer">
                                            <div className="image">
                                                <img src={`${httpCPage}/backend/img/testimoniul/${testimonial.image}`} alt="" />
                                            </div>
                                        </div>
                                        <h3>{testimonial.name}</h3>
                                        <div className="title">{testimonial.title}</div>
                                        <div className="text">{testimonial.review}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </FadeInOut>
                <FadeInOut>
                    <div className="text-center mt-5">
                        <div className="btns-box mt-2">
                            <Link to="/testimonial" className="theme-btn btn-style-one"><span className="txt">Give Review</span></Link>
                        </div>
                    </div> 
                </FadeInOut>
                </div>
            </section>
        </div>
    );
}

export default TestimoniulShow;
