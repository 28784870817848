import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import BackButton from '../../../tools/BackButton'
import swal from 'sweetalert';

const EditPermission = () => {
    const { id } = useParams();
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');

    const [newGroupName, setNewGroupName] = useState("");
    const [newGroupNameError, setNewGroupNameError] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [permissionsError, setPermissionsError] = useState("");


    useEffect(() => {
        http.get(`/get-single-permission/${id}`)
          .then(res => { 
            setNewGroupName(res.data.permissions.group_name);
            setPermissions(res.data.permissions.name)
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
      }, []);

     

    const submitform = (history) => {
        setLoader('on');
        const formData = new FormData();
        formData.append('group_name', newGroupName);
        formData.append('permission_name', permissions);
        
        http.post(`/permission-update/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then(res => {
          if (res.data.status === '422') {
            setLoader('off');
            const errors = res.data.error;
            setNewGroupNameError(errors.group_name ? errors.group_name[0] : '');
            setPermissionsError(errors.permission_name ? errors.permission_name[0] : '');
          } else {
            setLoader('off');
            swal('Success', res.data.msg, 'success');
          }
        }).catch(error => {
          console.error('Error:', error);
        });
      };

      const handlepermissionChange = (e) => {
        setPermissions(e.target.value);
      };
      const handleGroupNameChange = (e) => {
        setNewGroupName(e.target.value);
      };


  return (
    <div>
    <div className="main-content">
        <section className="section">
        <div className="section-body">
            <div className="row">
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h5>Edit Permission</h5>
                            <div className="">
                                <BackButton/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="card-body pb-0 px-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Group Name</label>
                                        <input
                                            value={newGroupName}
                                            className="form-control ms-0"
                                            placeholder="Group Name"
                                            onChange={handleGroupNameChange}
                                        />
                                        <span className='text-danger'>{newGroupNameError}</span>
                                        <div>
                                           
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Permission Name</label>
                                        <input
                                            value={permissions}
                                            className="form-control ms-0"
                                            placeholder="Permission Name"
                                            onChange={handlepermissionChange}
                                        />
                                        <span className='text-danger'>{permissionsError}</span>
                                        <div>
                                           
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>

                        <div className="text-center">
                            <label className="col-form-label"></label>
                            {loader === 'off' &&
                                <button type="submit" onClick={submitform} className="btn btn-primary">Update</button>
                            }
                            {loader === 'on' &&
                                <div class="spinner-border text-dark" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </section>
    </div>
</div>
  )
}

export default EditPermission