import React, { useState, useEffect } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { FrontUrl } from '../../api/FrontUrl';
import FadeInOut from '../../tools/FadeInOut';
import { ServerUrl } from '../../api/ServerUrl';

const MissionVission = () => {
    const httpFrontCPage = FrontUrl;
    const { http } = Api();
    const [mission, setMission] = useState('');
    const [vission, setVission] = useState('');
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get(`/get-about-mission`)
            .then(res => {
                setMission(res.data.data[0].mission);
                setVission(res.data.data[0].vission);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layout:', error);
            });
            document.title = 'Foot Balance Technology BD - Mission Vission';
    }, []);

  return (
    <div>
        <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Mission & Vision</h2>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    
                    <li>About Us</li>
                    <li>Mission & Vision</li>
                </ul>
            </div>
        </section>
<FadeInOut>
        <section className=" mission py-5">
            <div className="container">
                <div className="row" data-aos="fade-up" data-aos-duration={1500}>
                    <div className="row m-auto mt-4">
                        <div className="col-md-4 mt-2">
                            <img src={`${httpFrontCPage}/assets/images/icons/mission.gif`} className="img-fluid rounded-3" alt='' />
                        </div>
                        <div className="col-md-8 mb-3">
                        <p className="text-justify">
                        </p><h5 className="m_v fw-bold">Mission:</h5>
                            <div className="text mb-3 descriptionPcolor p-0 text-start justify-content-center" dangerouslySetInnerHTML={{ __html: mission }}></div>
                        <p /> 
                        </div>
                    </div> 
                </div>
            </div>
        </section>
</FadeInOut>
<FadeInOut>
        <section className=" mission py-5">
            <div className="container">
                <div className="row" data-aos="fade-up" data-aos-duration={1500}>
                    <div className="row mt-2 mb-3">
                        <div className="col-md-7 order-2 order-md-1 order-lg-1">
                            <div className="card-body mt-5">
                                <p className="mission">       
                                </p><h5 className="m_v fw-bold">Vision:</h5>   
                                    <div className="text mb-3 descriptionPcolor p-0 text-start pr-3" dangerouslySetInnerHTML={{ __html: vission }}></div>
                                <p />    
                            </div>
                        </div>
                        <div className="col-md-4 m-auto order-1 order-md-2 order-lg-2">
                            <img src={`${httpFrontCPage}/assets/images/icons/vission.gif`} className="img-fluid  rounded-3" alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
</FadeInOut>
    </div>
  )
}

export default MissionVission