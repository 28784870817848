import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import {ServerUrl} from '../../../../../api/ServerUrl';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const EditBanner = () => {
    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const { id } = useParams();
    const [loader, setLoader] = useState('off');
    const [bannerTitle, setBannerTitle] = useState('');
    const [bannerDescription, setBannerDescription] = useState(''); // Added state for Banner Description
    const [bannerStatus, setBannerStatus] = useState('');
    const [bannerTitleError, setBannerTitleError] = useState('');
    const [bannerDescriptionError, setBannerDescriptionError] = useState(''); // Added state for Banner Description error
    const [banner, setBanner] = useState(null);
    const [bannerError, setBannerError] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        http.get(`/get-single-banner/${id}`)
            .then(res => { 
                setBannerTitle(res.data.bannerTitle);
                setBannerDescription(res.data.bannerDescription); // Set Banner Description
                setBannerStatus(res.data.status);
                const bannerImage = res.data.banner;
                if (bannerImage) {
                    const userImageShow = `${httpCPage}/backend/img/${bannerImage}`;
                    setImagePreview(userImageShow);
                }
            })
            .catch(error => {
                console.error('Error fetching permissions:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('bannerTitle', bannerTitle);
        formData.append('bannerDescription', bannerDescription); // Append Banner Description to formData
        formData.append('banner', banner);
        formData.append('status', bannerStatus);

        http.post(`/update-single-banner/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setBannerError(errors.banner ? errors.banner[0] : '');
                setBannerTitleError(errors.bannerTitle ? errors.bannerTitle[0] : '');
                setBannerDescriptionError(errors.bannerDescription ? errors.bannerDescription[0] : ''); // Set Banner Description error
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setBanner(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'bannerTitle') {
            setBannerTitle(value);
        } else if (name === 'bannerDescription') {
            setBannerDescription(value); // Update Banner Description
        }
    };

    const handleStatusChange = (e) => {
        const { value } = e.target;
        setBannerStatus(value);
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Update Banner</h5>
                                            <div className="">
                                                <BackButton/>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Banner Title</label>
                                                        <input type="text" id="bannerTitle" name="bannerTitle" value={bannerTitle} className="form-control ms-0" onChange={handleInputChange} />
                                                        <span className='text-danger'>{bannerTitleError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Banner Description</label>
                                                        <textarea name="bannerDescription" value={bannerDescription} onChange={handleInputChange} className="form-control" rows="3"></textarea>
                                                        <span className='text-danger'>{bannerDescriptionError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <select name="status" id="status" className='form-control' value={bannerStatus} onChange={handleStatusChange}>
                                                            <option value="" disabled>Select</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">De-Active</option>
                                                        </select>
                                                        <span className='text-danger'>{bannerTitleError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="logo-box">
                                                        <div className="logo">
                                                            <label className='' htmlFor="userImage">
                                                                <div className="image-box border dottet p-2 rounded">
                                                                    {imagePreview ? (
                                                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                                    ) : (
                                                                        <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='img-fluid' alt="Default" />
                                                                    )}
                                                                </div>
                                                                <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Change Banner</span>
                                                                <span className='text-danger'>{bannerError}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' &&
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            }
                                            {loader === 'on' &&
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default EditBanner;
