import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../tools/BackButton';
import { ProtectAPi } from '../../../api/ProtectAPi';
import {ServerUrl} from '../../../api/ServerUrl';
import swal from 'sweetalert';

const UserEdit = () => {
  const { id } = useParams();
  const { http } = ProtectAPi();
  const httpCPage = ServerUrl;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [status, setStatus] = useState('');
  const [userRole, setUserRole] = useState('');
  const [statusError, setStatusError] = useState('');
  const [roles, setRoles] = useState('');
  const [rolesError, setRolesError] = useState('');
  const [loader, setLoader] = useState('off');
  const [allroles, setAllRoles] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await http.get(`/get-user/${id}`);
      setName(response.data.users.name);
      setEmail(response.data.users.email);
      setStatus(response.data.users.status.toString()); 
      setUserRole(response.data.userRole.name.toString()); 
      setRoles(response.data.userRole.name.toString()); 
      const userImage = response.data.users.image;
      if (userImage) {
        const userImageShow = httpCPage + '/backend/user_image/' + userImage;
        setImagePreview(userImageShow);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    http.get('/get-all-roles')
      .then(res => { 
        setAllRoles(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const submitForm = (history) => {
    setLoader('on');
    setNameError('');
    setEmailError('');
    setStatusError('');
    setRolesError('');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('image', image);
    formData.append('status', status);
    formData.append('roles', roles);

    http.post(`/update-user/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        if (res.data.status === '422') {
          const errors = res.data.error;
          setNameError(errors.name ? errors.name[0] : '');
          setEmailError(errors.email ? errors.email[0] : '');
          setStatusError(errors.status ? errors.status[0] : '');
          setRolesError(errors.roles ? errors.roles[0] : '');
          setLoader('off');
        } else {
            setLoader('off');
            swal('Success', 'User updated successfully!', 'success');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  //   Image preview
  const handleImageChange = (e) => {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      const previewURL = URL.createObjectURL(selectedImage);
      setImagePreview(previewURL);
  };

  

  return (
    <div>
      <div className="main-content">
        <section className="section userr">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-2">
                      <h5>Edit User</h5>
                      <div className="">
                        <BackButton />
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-md-3 text-center mt-3">
                            <label className='' htmlFor="userImage">
                              <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                  {imagePreview ? (
                                  <img src={imagePreview} className='img-fluid' alt="Preview" />
                                  ) : (
                                  <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                  )}
                              </div>
                            
                                <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Image</span>
                            </label>
                        </div>

                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={name}
                                  onChange={e => setName(e.target.value)}
                                />
                                <span className='text-danger'>{nameError}</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                />
                                <span className='text-danger'>{emailError}</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">Status</label>
                                <select
                                  className="form-control selectric rounded"
                                  value={status}
                                  onChange={e => setStatus(e.target.value)}
                                >
                                  <option value="">Select</option>
                                  <option value="1">Active</option>
                                  <option value="2">De-Active</option>
                                </select>
                                <span className='text-danger'>{statusError}</span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">Role</label>
                                <select
                                  className="form-control rounded selectric"
                                  value={userRole}
                                  onChange={e => setRoles(e.target.value)}
                                >
                                  <option value="" disabled selected>Select Role</option>
                                    {allroles.map((allrole, index) => (
                                        <option key={index} value={allrole.name}>
                                            {allrole.name}
                                        </option>
                                    ))}
                                </select>
                                <span className='text-danger'>{rolesError}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div className="text-center">
                      <label className="col-form-label"></label>
                      {loader === 'off' &&
                        <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                      }
                      {loader === 'on' &&
                            <div class="spinner-border text-dark" role="status"></div>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>
    </div>
  );
};

export default UserEdit;
