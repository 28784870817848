import React, { useEffect, useRef, useState } from 'react';

const FadeInOut = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.01, // Adjust as needed
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className={`fade-in-out ${isVisible ? 'visible' : ''}`} ref={ref}>
      <div className="content">{children}</div>
    </div>
  );
};

export default FadeInOut;
