import React, { useState, useEffect, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';
import { ProtectAPi } from '../../../../../api/ProtectAPi';

const AddUpdateMv = () => {
    const [id, setId] = useState('');
    const editor = useRef(null);
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [mission, setMission] = useState('');
    const [vission, setVission] = useState('');
    const [missionError, setMissionError] = useState('');
    const [vissionError, setVissionError] = useState('');

    useEffect(() => {
        http.get(`/get-pages-about-mission-vission`)
            .then(res => {
                setId(res.data[0].id);
                setMission(res.data[0].mission);
                setVission(res.data[0].vission);
            })
            .catch(error => {
                console.error('Error fetching layout:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('mission', mission);
        formData.append('vission', vission);

        http.post(`/update-about-mission-vission/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setMissionError(errors.mission ? errors.mission[0] : '');
                setVissionError(errors.vission ? errors.vission[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Mission Vission</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <label>Mission</label>
                                                     <JoditEditor
                                                        ref={editor}
                                                        value={mission}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setMission(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{missionError}</span>
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <label>Vission</label>
                                                     <JoditEditor
                                                        ref={editor}
                                                        value={vission}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setVission(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{vissionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AddUpdateMv;
