import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import BackButton from '../../../tools/BackButton';
import { format } from 'date-fns';

const ContactView = () => {
    const { http } = ProtectAPi();
    const { id } = useParams();
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [emailOrPhone, setEmailorPhone] = useState('');
    const [date, setDate] = useState('');
    const [message, setMessage] = useState('');
    useEffect(() => {
        http.get(`/get-pages-contact-list-single/${id}`)
            .then(res => {
                setName(res.data.name);
                setSubject(res.data.subject);
                setEmailorPhone(res.data.emailOrPhone);
                setDate(format(new Date(res.data.created_at), 'dd MMMM yyyy'));
                setMessage(res.data.message);
            })
            .catch(error => {
                console.error('Error fetching contact list:', error);
            });
    }, [id]);
    useEffect(() => {
        submitForm();
    }, []);

    const submitForm = () => {
        const formData = new FormData();
        formData.append('status', 1);
        http.post(`/update-pages-contact-list-single/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            
        }).catch(error => {
            console.error('Error:', error);
        });
    };


    return (
        <div>
            <div className="main-content">
                <section className="section userr">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-2">
                                            <h5>Message Box</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>

                                        <div className="row">
                                           <div className="col-md-12">
                                               <div className="view-mail border rounded pt-4 pb-2 px-3">
                                                <p>From : {name}</p>
                                                <p>Subject : {subject}</p>
                                                <p>Date : {date}</p>
                                                <p>Contact By : {emailOrPhone}</p>
                                                <p>Message</p>
                                                <p>{message}</p>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ContactView;
