import React, { useEffect, useState } from 'react';
import { Api } from '../api/Api';
import FadeInOut from '../tools/FadeInOut';


const WorkProcess = () => {
    const { http } = Api();
    const [workProcess, setWorkProcess] = useState([]);

    useEffect(() => {
        http.get(`/front-get-work-process`)
            .then(res => { 
                if (res.data && res.data.length > 0) {
                    setWorkProcess(res.data.map((process, index) => ({
                        ...process,
                        icon: index < icons.length ? icons[index] : '' // Assigning icons from the icons array
                    })));
                }
            })
            .catch(error => {
                console.error('Error fetching work process information:', error);
            });
    }, []);

    // Array containing icon classes
    const icons = ['flaticon-hand-shake', 'flaticon-answer', 'flaticon-sketch', 'flaticon-house-1'];

    return (
        <div>
            <section className="featured-section" style={{ backgroundImage: 'url(images/background/22.jpg)' }}>
                <div className="auto-container">
                <FadeInOut>
                    <div className="title-box">
                        <h2>Our Work Process</h2>
                    </div>
                   </FadeInOut> 
                   <FadeInOut>
                    <div className="row clearfix">
                        {workProcess.map((process, index) => (
                            <div key={index} className="feature-block col-lg-3 col-md-6 col-sm-12">
                                <div className="inner-box wow fadeInLeft" data-wow-delay={`${index * 250}ms`} data-wow-duration="1500ms">
                                    <div className="icon-outer"> 
                                        <div className="icon-box">
                                            <span className={`icon ${process.icon}`}></span> {/* Dynamically applying the icon class */}
                                        </div>
                                        <div className="feature-number">{index + 1}</div>
                                    </div>
                                    <div className="lower-content">
                                        <h3 className='cusColorTitle'>{process.title}</h3>
                                        <div className="text">{process.description}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </FadeInOut>
                </div>
            </section>
        </div>
    );
}

export default WorkProcess;
