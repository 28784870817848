import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import { SetPermission } from '../../../../../auth/SetPermission';

const FooterCategoryList = () => {
    
    const userPermission = SetPermission();
    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        http.get('/setting-get-category')
            .then(res => {
                const categoryNames = Object.keys(res.data); // Extract category names
                setCategoryList(categoryNames);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    useEffect(() => {
        if (categoryList.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        } else {
            // If no data, destroy DataTable
            if ($.fn.DataTable.isDataTable('#table-1')) {
                $(tableRef.current).DataTable().destroy();
            }
        }
    }, [categoryList]);


    // delete category
    const handleDeleteCategory = (categoryId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this category!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-single-category/${categoryId}`)
                    .then(response => {
                        if (response.data.status !== 201) {
                            setCategoryList(prevCategories => prevCategories.filter(category => category !== categoryId));
                        }
    
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                    })
                    .catch(error => {
                        swal('Oops! Something went wrong while deleting the category.', {
                            icon: 'error',
                        });
                    });
            } else {
                swal('The category is safe!');
            }
        });
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="row d-flex">
                                            <div className="mb-2">
                                                <Link to="/admin/settings" className="btn border btn-sm mr-2">Web Info</Link>
                                            </div>
                                            <div className="mb-2">
                                                <Link to="/admin/settings/banner-list" className="btn border btn-sm mr-2">Banner</Link>
                                            </div>
                                            <div className="mb-2">
                                                <Link to="/admin/settings/footer-list" className="btn btn-primary btn-sm mr-2">Footer</Link>
                                            </div>
                                            <div className="mb-2">
                                                <Link to="/admin/settings/terms-list" className="btn border btn-sm mr-2">Terms & Conditions</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-3">
                                            <h5>Category List</h5>
                                            <div className="text-end">
                                                <Link to='/admin/settings/footer-category-add' className="btn btn-primary btn-sm">Add New Category</Link>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped" id="table-1" ref={tableRef}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">#</th>
                                                        <th>Category Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {categoryList.map((categoryName, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{categoryName}</td> {/* Display category name */}
                                                            <td>
                                                                {userPermission.includes('edit_setting') && (<Link to={`/admin/settings/footer-category-edit/${categoryName}`} className="editbtn mr-1">
                                                                        <button className="btn btn-warning">
                                                                            <i className="fas me-2 fa-edit text-white"></i>
                                                                        </button>
                                                                    </Link>
                                                                    )}
                                                                    {userPermission.includes('edit_setting') && (<Link to='' className="editbtn">
                                                                        <button className="btn btn-danger" onClick={() => handleDeleteCategory(categoryName)}>
                                                                            <i className="fas me-2 fa-trash text-white"></i>
                                                                        </button>
                                                                    </Link>
                                                                    )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default FooterCategoryList;
