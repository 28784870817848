import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

const AppoinmentToday = () => {
  const { http } = ProtectAPi();
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [apoinmentDate, setApoinmentDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const today = format(new Date(), 'yyyy-MM-dd');
    getDataByDate(today);
  }, []);

  function getDataByDate(date) {
    http.get(`/get-pages-appoinment-today/${date}`)
      .then(res => {
        setFilteredData(res.data);
        setTableData(res.data);
        setApoinmentDate(formatDate(date));
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }

  useEffect(() => {
    if (tableData.length > 0 && !tableRef.current.DataTable) {
      $(tableRef.current).DataTable();
    } else {
      if ($.fn.DataTable.isDataTable('#table-1')) {
        $(tableRef.current).DataTable().destroy();
      }
    }
  }, [tableData]);

  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      $(tableRef.current).DataTable().destroy();
      getDataByDate(selectedDate);
    }
  };


  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list" className="btn border btn-sm mr-2">Pending List</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list-confirmed" className="btn border btn-sm mr-2">Appoinment Confirmed</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list-today" className="btn btn-primary btn-sm mr-2">Appoinment Today</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-scheduled-confirm" className="btn border btn-sm mr-2">Scheduled Confirm</Link>
                      </div>
                      <div className="mb-2">
                          <Link to="/admin/pages/sms-balance" className="btn border btn-sm mr-2">Check SMS</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <p className='btn btn-primary btn-sm text-white' variant="contained" onClick={handleExportExcel} >Excel</p>
                    <div className="row">
                      <div className="col-md-4">
                        <h5>Appointment ({apoinmentDate})</h5>
                      </div>
                      <div className="col-md-5"></div>
                      <div className="col-md-3">
                        <div className="form-group w-100">
                          {/* <Select options={options2} onChange={handleDateChange} /> */}
                          <input 
                            type="date" 
                            className="form-control" 
                            onChange={handleDateChange} 
                          />
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1" ref={tableRef}>
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Number</th>
                            <th>Request Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((data, index) => (
                            <tr key={data.id}>
                              <td>{index + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.location}</td>
                              <td>{data.number}</td>
                              <td>{formatDate(data.created_at)}</td>
                              <td>
                                {data.status === 1 && <span className='badge badge-primary'>Date Confirmed</span>}
                                {data.status === 0 && <span className='badge badge-danger'>Not Confirmed</span>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AppoinmentToday;
