import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Api } from '../../api/Api';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const AwardAll = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Award List';
      }, []);

    const { http } = Api();
    const [award, setAward] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);
    const httpCPage = ServerUrl;
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get(`/front-get-banner-image/${'Award List'}`)
        .then(res => {
            setBackImage(res.data.backImage);
        })
        .catch(error => {
            console.error('Error fetching blogs:', error);
        })
    }, []);

    useEffect(() => {
        http.get('/get-front-home-award')
            .then(res => {
                setAward(res.data);
            })
            .catch(error => {
                console.error('Error fetching awards:', error);
            });
    }, []);

    const images = award.map(award => ({
        src: `${httpCPage}/backend/img/award/${award.image}`,
        caption: award.name
    }));

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

  return (
    <div>
         <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Award List</h2>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    <li>Award List</li>
                </ul>
            </div>
        </section>

        <section className='container my-5'>
        <FadeInOut>
                    <div className="outer-container">
                        <div className="row">
                            {award.map((award, index) => (
                                <div key={index} className="col-md-4">
                                    <div className="gallery-item" onClick={() => openLightbox(index)}>
                                        <div className="inner-box">
                                            <figure className="image-box p-2 rounded award-imagebox">
                                                <img
                                                    src={`${httpCPage}/backend/img/award/${award.image}`}
                                                    className="award_img rounded"
                                                    alt=""
                                                    onLoad={handleImageLoad}
                                                />
                                                <div className="overlay"></div>
                                                <div className="certificate-info">
                                                   <div className="textBoxAward">
                                                        <p className='text-white pointer fw-bold'>{award.category}</p>
                                                   </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {isOpen && imageLoaded && (
                            <Lightbox
                                mainSrc={images[photoIndex].src}
                                nextSrc={images[(photoIndex + 1) % images.length].src}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                                onCloseRequest={closeLightbox}
                                onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                imageCaption={images[photoIndex].caption}
                            />
                        )}
                    </div>
                </FadeInOut>
        </section>
    </div>
  )
}

export default AwardAll