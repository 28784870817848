import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import {ServerUrl} from '../../../../api/ServerUrl';
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';

const NoteList = () => {

    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const tableRef = useRef(null);
    const [banners, setBanners] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/get-note')
        .then(res => { 
            setBanners(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    
    useEffect(() => {
        if (banners.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        } else {
            if ($.fn.DataTable.isDataTable('#table-1')) {
                $(tableRef.current).DataTable().destroy();
            }
        }
    }, [banners]);


    // delete roles
    const handleDeleteRole = (roleId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this Note!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-note/${roleId}`)
                   .then(response => {
                       if(response.data.status !== 201){
                        setBanners(prevRoles => prevRoles.filter(banners => banners.id !== roleId));
                       }
                        
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                   })
                   .catch(error => {
                        swal('Oops! Something went wrong while deleting the Note.', {
                            icon: 'error',
                        });
                   });
                swal('Poof! The Note has been deleted!', {
                    icon: 'success',
                });
            } else {
                swal('The Note is safe!');
            }
        });
    };

  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Notice List</h5>
                            <div class="text-end">
                                <Link to='/admin/pages/notice-add' className="btn btn-primary btn-sm">Add New Notice</Link>
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Title</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {banners.map((banner, index) => (
                              <tr key={banner.id}>
                                <td>{index + 1}</td>
                                <td>{banner.note}</td>
                                <td>
                                    {banner.status === 1 && <span className='badge badge-primary'>Active</span> }
                                    {banner.status === 0 && <span className='badge badge-danger'>De-Active</span> }
                                </td>
                                <td>
                                {userPermission.includes('home_all_edit') && (<Link to={`/admin/pages/notice-edit/${banner.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <i className="fas me-2 fa-edit text-white"></i>
                                      </button>
                                    </Link>
                                  )}
                                  {userPermission.includes('home_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(banner.id)}>
                                            <i className="fas me-2 fa-trash text-white"></i>
                                        </button>
                                    </Link>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  )
}

export default NoteList