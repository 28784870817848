import React, { useEffect, useState } from 'react';
import { Api } from '../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../api/ServerUrl';
import { FrontUrl } from '../api/FrontUrl';
import FadeInOut from '../tools/FadeInOut';


const Layout = () => {
    const httpCPage = ServerUrl;
    const [layouts, setLayouts] = useState([]);
    const { http } = Api();
    useEffect(() => {
        
        http.get('/get-all-pages-home-layouts')
            .then(res => { 
                setLayouts(res.data);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, []);

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

    return (
        <div>
           {/* Mission Section */}
           <FadeInOut>
            <section className="mission-section layoutTop">
                <div className="auto-container">
                    <div className="row clearfix">
                    {/* Image Column */}
                    <div className="image-column col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                        <div className="image rounded">
                            <img src={`${FrontUrl}/assets/images/img/ac.jpg`} className='rounded' alt='image' />
                        </div>
                        </div>
                    </div>
                    {/* Content Column */}
                    <div className="content-column col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                        <h2>We Can Help You Walk Again</h2>
                        <div className="bold-text">
                        Did you know Foot Balance Technology can help you fight your walking inability better?
                        </div>
                        <div className="text">
                        It is possible to enable people who have lower limb deformities caused by any accident, disease, or even congenital conditions to be back on their feet.
                        </div>
                        <h3 className="text">
                            Foot Balance Technology BD Ltd. is a specialized professional Pedorthic service provider based in Dhaka, Bangladesh. We provide care for feet & ankles through proper footwear for men, women, and children.
                        </h3>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            </FadeInOut>
            {/* End Mission Section */}

            
        
            {layouts.map((layout, index) => (
                <section key={index} className={`story-section pt-5 ${index % 2 !== 0 ? 'customBg' : 'customBg2'}`}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            {index % 2 === 0 ? (
                                <>
                                <FadeInOut>
                                    <div className="row">
                                    <div className="content-column col-lg-8 col-md-12 col-sm-12 order-2 order-md-1 order-lg-1">
                                        <div className="inner-column mt-5 mt-sm-0">
                                            <h2>{layout.title}</h2>
                                            <div className="bold-text">{layout.subtitle !== 'null' ? layout.subtitle : ''}</div>
                                            <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 500) }}></div>
                                            <Link to={`/layout/details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-1"></div>
                                    <div className="image-column col-lg-3 col-md-12 col-sm-12 order-1 order-md-2 order-lg-2">
                                        <div className="inner-column mt-4">
                                            <div className="image">
                                                <img alt="" src={`${httpCPage}/backend/img/pages/home/layout/${layout.image}`} className='rounded' width="35" />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </FadeInOut>
                                </>
                            ) : (
                                <>
                                <FadeInOut>
                                   <div className="row">
                                    <div className="image-column col-lg-3 col-md-12 col-sm-12 order-1 order-md-1 order-lg-1">
                                            <div className="inner-column mt-2">
                                                <div className="image rounded">
                                                    <img alt="" src={`${httpCPage}/backend/img/pages/home/layout/${layout.image}`} className='rounded' width="35" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="content-column col-lg-8 col-md-12 col-sm-12 order-2 order-md-2 order-lg-2">
                                            <div className="inner-column mt-3">
                                                <h2>{layout.title}</h2>
                                                <div className="bold-text">{layout.subtitle !== 'null' ? layout.subtitle : ''}</div>
                                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 500) }}></div>
                                                <Link to={`/layout/details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                            </div>
                                        </div>
                                   </div>
                                    </FadeInOut>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            ))}
        </div>
    );
}

export default Layout;
