import React, { useEffect, useState, useRef } from 'react';
import { Api } from '../../api/Api';
import swal from 'sweetalert';
import './Faq.css';
import FadeInOut from '../../tools/FadeInOut';
import ReCAPTCHA from 'react-google-recaptcha';
import { ServerUrl } from '../../api/ServerUrl';
import { Link } from 'react-router-dom';

const Contact = () => {
    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Contact Us';
    }, []);

    const { http } = Api();

    const [faq, setFaq] = useState([]);
    const [expandedId, setExpandedId] = useState(null);
    const [backImage, setBackImage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaRef = useRef();

    useEffect(() => {
        http.get('/get-front-faq')
            .then(res => {
                setFaq(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);

    const handleToggle = (id) => {
        setExpandedId(prevId => (prevId === id ? null : id));
    };

    const [settings, setSettings] = useState({
        address: '',
        email: '',
        number: '',
        map_link: '',
    });

    useEffect(() => {
        http.get('/front-get-setting')
            .then(res => {
                const settingData = res.data[0];
                setSettings(settingData);
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        emailOrPhone: '',
        subject: '',
        message: '',
    });

    const [loader, setLoader] = useState('off');
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            swal('Error', 'Please reload and complete the reCAPTCHA.', 'error');
            return;
        }
        setLoader('on');
        setErrors({});
        try {
            http.post('/verify-recaptcha', { token: recaptchaToken })
            .then(res => {
                //  recaptchaResponse = res.data;
                 if(res.data.success){
                    http.post('/contact-us-store', formData)
                        .then(res => {
                            if (res.data.status === '422') {
                                setLoader('off');
                                setErrors(res.data.error);
                                setRecaptchaToken(null);
                            } else if (res.data.status === '425') {
                                setLoader('off');
                                setRecaptchaToken(null);
                            } else {
                                setLoader('off');
                                setFormData({
                                    name: '',
                                    emailOrPhone: '',
                                    subject: '',
                                    message: '',
                                });
                                swal('Success', res.data.msg, 'success');
                                setRecaptchaToken(null);
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            setLoader('off');
                            setRecaptchaToken(null);
                        });
                 }else{
                    setLoader('off');
                    swal('Error', 'Failed to upload data.', 'error');
                    return;
                 }
            })
            .catch(error => {
                setLoader('off');
                swal('Error', 'reCAPTCHA verification failed. Please try again.', 'error');
                return;
            });
           
        } catch (error) {
            console.error('Error verifying reCAPTCHA:', error);
            setLoader('off');
            swal('Error', 'An error occurred while verifying reCAPTCHA. Please try again.', 'error');
        }
    };

    return (
        <div>
             <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Contact Us</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </section>

            <section className="contact-form-section">
                <div className="auto-container">
                    <FadeInOut>
                        <div className="row clearfix">
                            {/* Form Column */}
                            <div className="form-column col-lg-7 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="contact-form">
                                        <form onSubmit={submitForm}>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your name" />
                                                    <span className="text-danger">{errors.name}</span>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="text" name="emailOrPhone" value={formData.emailOrPhone} onChange={handleChange} placeholder="Email Or Phone" />
                                                    <span className="text-danger">{errors.emailOrPhone}</span>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <input type="text" name="subject" value={formData.subject} onChange={handleChange} placeholder="Subject" />
                                                    <span className="text-danger">{errors.subject}</span>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Message" />
                                                    <span className="text-danger">{errors.message}</span>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <ReCAPTCHA
                                                        sitekey="6LdLOwoqAAAAAPtD1b5H7wWOeiYpy9Qm88I7IuA-"
                                                        onChange={(token) => setRecaptchaToken(token)}
                                                        ref={recaptchaRef}
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    <label className="col-form-label"></label>
                                                    {loader === 'off' ? (
                                                        <button type="submit" name="submit-form" className="theme-btn btn-style-cus mt-4">Submit</button>
                                                    ) : (
                                                        <div className="spinner-border text-dark mt-4" role="status"></div>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="info-column col-lg-5 col-md-12 col-sm-12">
                                <div className="inner-column">
                                {/* Contact Info List */}
                                    <ul className="contact-info-list">
                                        <li><strong>Address :</strong><br />{settings.address}</li>
                                    </ul>
                                    {/* Contact Info List */}
                                    <ul className="contact-info-list">
                                        <li><strong>Phone : </strong><Link to={`tel:${settings.number}`}>{settings.number}</Link></li>
                                        <li><strong>Email : </strong><Link to={`mailto:${settings.email}`}>{settings.email}</Link></li>
                                    </ul>
                                    {/* Contact Info List */}
                                </div>
                            </div>
                            
                        </div>
                    </FadeInOut>
                </div>
            </section>

            <FadeInOut>
            <section className="section bg-pink-light mb-5">
                <div className="container mx-auto forcemt-5">
                    <h2 className="h2 text-center text-uppercase mb-4 fw-bolder">F.A.Q</h2>
                    <div className="accordions">
                        {faq.map((item, index) => (
                            <div className="accordion" key={index}>
                                <label className="accordion__title" htmlFor={`radio_${index}`}>
                                    <input id={`radio_${index}`} type="radio" name="radio" defaultChecked={index === 0} />{item.question}
                                </label>
                                <div className="accordion__text">
                                    <p>{item.answar}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            </FadeInOut>

            <section className="map-section">
                <div className="outer-container">
                    <div className="map-outer">
                    <iframe
                        title="Company Location"
                        width="100%"
                        height="450"
                        loading="lazy"
                        allowFullScreen
                        src={`${settings.map_link}`}
                    ></iframe>
                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default Contact;
