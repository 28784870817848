import React from 'react';
import { useEffect, useState } from 'react';
import { Api } from '../api/Api';


const LoginTo = () => {

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token === 'authorization') {
      window.location.href = '/admin/dashboard';
    }
  }, []);

  const { http } = Api();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [genericError, setGenericError] = useState('');
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitform = () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
  
    http.post('/admin-login', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(res => {
      if (res.data.status === '422') {
        const errors = res.data.error;
        setEmailError(errors.email ? errors.email[0] : '');
        setPasswordError(errors.password ? errors.password[0] : '');
      } else if (res.data.status === '423') {
        setEmailError('');
        setPasswordError('Invalid password');
      } else if (res.data.status === '424') {
        setEmailError('User not found');
        setPasswordError('');
      } else {
        const { id, name, email, image, role, permissions } = res.data.user;
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user', JSON.stringify({ id, name, email, image, role}));
        localStorage.setItem('userPermissions', JSON.stringify({ permissions }));
        window.location.href = '/admin/dashboard';
      }
    }).catch(error => {
      console.error('Error:', error);
      setGenericError('An unexpected error occurred. Please try again later.');
    });
  };
  

  return (
    <div>
        <section className="">
      <div className="container mt-5">
        <div className="row">
          <div className="mt-5 col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary">
                <div className="text-center">
                    <h3 className='mt-3'>Foot Balance</h3>
                </div>
              <div className="card-header">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input id="email" type="email" className="form-control" name="email" tabindex="1" autofocus onChange={e=>setEmail(e.target.value)} />
                    <span className='text-danger'>{emailError}</span>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label for="password" className="control-label">Password</label>
                      <div className="float-right">
                        <a href="auth-forgot-password.html" className="text-small">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    <div className="input-group">
                      <input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        name="password"
                        tabIndex="2"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          <i className='fa fa-eye'></i>
                        </button>
                      </div>

                    </div>
                    <span className='text-danger'>{passwordError}</span>
                  </div>
                  <div className="text-center text-danger">
                    <span>{genericError}</span>
                  </div>
                  <div className="form-group mt-3">
                    <button type="submit" onClick={submitform} className="btn btn-primary btn-lg btn-block" tabindex="4">
                      Login
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default LoginTo