import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ProtectAPi } from '../../../api/ProtectAPi';
import BackButton from '../../tools/BackButton';
import swal from 'sweetalert';
 



const UserCreate = () => {
  const navigate = useNavigate();
  const {http} = ProtectAPi();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [status, setStatus] = useState('');
  const [statusError, setStatusError] = useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [password_confirmation, setPassword_confirmation] = useState('');
  const [password_confirmationError, setPassword_confirmationError] = useState('');


  const [roles, setRoles] = useState('');
  const [rolesError, setRolesError] = useState('');

  const [loader, setLoader] = useState('off');
  const [allroles, setAllRoles] = useState([]);

  useEffect(() => {
    http.get('/get-all-roles')
      .then(res => { 
        setAllRoles(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const submitform = (history) => {
    setLoader('on');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('image', image);
    formData.append('status', status);
    formData.append('password', password);
    formData.append('password_confirmation', password_confirmation);
    formData.append('roles', roles);

    http.post('/add-user', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(res => {
      if (res.data.status === '422') {
        setLoader('off');
        const errors = res.data.error;
        setNameError(errors.name ? errors.name[0] : '');
        setEmailError(errors.email ? errors.email[0] : '');
        setStatusError(errors.status ? errors.status[0] : '');
        setPasswordError(errors.password ? errors.password[0] : '');
        setPassword_confirmationError(errors.password_confirmation ? errors.password_confirmation[0] : '');
        setRolesError(errors.roles ? errors.roles[0] : '');
      } else {
        navigate('/admin/users-list');
        swal('Success', res.data.msg, 'success');
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  };


    //   Image preview
    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

  return (
    <div>
       <div className="main-content">
        <section className="section userr">
        <div className="section-body">
            <div className="row">
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-2">
                            <h5>Create New User</h5>
                            <div className="">
                                <BackButton/>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-3 text-center mt-3">
                                <label className='' htmlFor="userImage">
                                    <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                        {imagePreview ? (
                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                        ) : (
                                        <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                        )}
                                    </div>
                                
                                    <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Image</span>
                                </label>
                            </div>

                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Name</label>
                                            <input type="text" className="form-control" onChange={e=>setName(e.target.value)} />
                                            <span className='text-danger'>{nameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Email</label>
                                            <input type="email" className="form-control" onChange={e=>setEmail(e.target.value)} />
                                            <span className='text-danger'>{emailError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Status</label>
                                            <select className="form-control selectric rounded" onChange={e=>setStatus(e.target.value)}>
                                            <option value="">Select</option>
                                            <option value="1">Active</option>
                                            <option value="2">De-Active</option>
                                            </select>
                                            <span className='text-danger'>{statusError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Role</label>
                                            <select className="form-control rounded selectric" onChange={e=>setRoles(e.target.value)}>
                                                <option value="" disabled selected>Select Role</option>
                                                {allroles.map((allrole, index) => (
                                                    <option key={index} value={allrole.name}>
                                                        {allrole.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className='text-danger'>{rolesError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Password</label>
                                        <div className="input-group">
                                            <input
                                            type={showPassword ? 'text' : 'password'}
                                            data-indicator="pwindicator"
                                            className="form-control"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                            >
                                                <i className='fa fa-eye'></i>
                                            </button>
                                            </div>
                                        </div>
                                        <span className='text-danger'>{passwordError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Confirm Password</label>
                                        <div className="input-group">
                                            <input
                                            type={showPasswordConfirmation ? 'text' : 'password'}
                                            className="form-control"
                                            value={password_confirmation}
                                            onChange={e => setPassword_confirmation(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={togglePasswordConfirmationVisibility}
                                            >
                                                <i className='fa fa-eye'></i>
                                            </button>
                                            </div>
                                        </div>
                                        <span className='text-danger'>{password_confirmationError}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <label className="col-form-label"></label>
                            {loader === 'off' &&
                                <button type="submit" onClick={submitform} className="btn btn-primary">Create</button>
                            }
                            {loader === 'on' &&
                                <div class="spinner-border text-dark" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </section>
      </div>
    </div>
  )
}

export default UserCreate;