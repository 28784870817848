import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';
import {ServerUrl} from '../../../../../api/ServerUrl';
import { ProtectAPi } from '../../../../../api/ProtectAPi';

const GroupImage = () => {
    const httpCPage = ServerUrl;
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [id, setID] = useState('');
    const [layoutImage, setLayoutImage] = useState(null);
    const [layoutImageError, setLayoutImageError] = useState('');
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        http.get(`/get-pages-about-group-image`)
            .then(res => {
                setID(res.data[0].id);
                const layoutImage = res.data[0].image;
                if (layoutImage) {
                    const layoutImageShow = `${httpCPage}/backend/img/pages/about/${layoutImage}`;
                    setImagePreview(layoutImageShow);
                }
            })
            .catch(error => {
                console.error('Error fetching layout:', error);
            });
    }, [id]);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('image', layoutImage);

        http.post(`/update-about-group-image/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setLayoutImageError(errors.image ? errors.image[0] : '');
            } else if(res.data.status === '423'){

            }else {
                setLoader('off');
                swal(res.data.alert, res.data.msg, res.data.alert);
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setLayoutImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5>Group Image</h5>
                                        <div className="">
                                            <BackButton />
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="row">
                                            <div className="col-md-6 mt-4">
                                                <label>Group Image</label>
                                                <div className="logo-box">
                                                    <div className="logo">
                                                        <label className='' htmlFor="layoutImage">
                                                            <div className="image-box border dottet p-2 rounded">
                                                                {imagePreview ? (
                                                                    <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                                ) : (
                                                                    <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='img-fluid' alt="Default" />
                                                                )}
                                                            </div>
                                                            <input type="file" id="layoutImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Group Image</span>
                                                            <span className='text-danger'>{layoutImageError}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <label className="col-form-label"></label>
                                        {loader === 'off' ?
                                            <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button> :
                                            <div className="spinner-border text-dark" role="status"></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default GroupImage;
