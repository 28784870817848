import React, { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const BlogAdd = () => {
    const { http } = ProtectAPi();
    const navigate = useNavigate();
    const editor = useRef(null);
    const [loader, setLoader] = useState('off');
    const [layoutTitle, setLayoutTitle] = useState('');
    const [layoutDescription, setLayoutDescription] = useState('');
    const [layoutImage, setLayoutImage] = useState(null);
    const [layoutImageError, setLayoutImageError] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('title', layoutTitle);
        formData.append('description', layoutDescription);
        formData.append('image', layoutImage);

        http.post(`/store-blog`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setLayoutImageError(errors.image ? errors.image[0] : '');
                setTitleError(errors.title ? errors.title[0] : '');
                setDescriptionError(errors.description ? errors.description[0] : '');
            } else {
                setLoader('off');
                navigate('/admin/pages/home/blog-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setLayoutImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'layoutTitle') {
            setLayoutTitle(value);
        } 
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Add Blog</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-6 mt-4">
                                                    <label>Blog Image</label>
                                                    <div className="logo-box">
                                                        <div className="logo">
                                                            <label className='' htmlFor="layoutImage">
                                                                <div className="image-box border dottet p-2 rounded">
                                                                    {imagePreview ? (
                                                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                                                    ) : (
                                                                        <img src="https://w7.pngwing.com/pngs/159/705/png-transparent-computer-computer-trademark-computer-logo.png" className='img-fluid' alt="Default" />
                                                                    )}
                                                                </div>
                                                                <input type="file" id="layoutImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Blog Image</span>
                                                                <span className='text-danger'>{layoutImageError}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <input type="text" name="layoutTitle" value={layoutTitle} className="form-control ms-0" placeholder='title' onChange={handleInputChange} />
                                                        <span className='text-danger'>{titleError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <label>Description</label>
                                                     <JoditEditor
                                                        ref={editor}
                                                        value={layoutDescription}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setLayoutDescription(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{descriptionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default BlogAdd;
