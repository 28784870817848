import React, { useEffect } from 'react';
import Banner from './homeLayouts/Banner';
import Layout from './homeLayouts/Layout';
import HomeProducts from './homeLayouts/HomeProducts';
import Award from './homeLayouts/Award';
import VideoAd from './homeLayouts/VideoAd';
import BlogShow from './homeLayouts/BlogShow';
import TestimoniulShow from './homeLayouts/TestimoniulShow';
import WorkProcess from './homeLayouts/WorkProcess';
import AppoinmentHome from './homeLayouts/AppoinmentHome';

const Home = () => {
  useEffect(() => {
    document.title = 'Foot Balance Technology BD - Home';
  }, []);
  return (
    <div>
      <Banner/>
      <Layout/>
      <HomeProducts/>
      <Award/>
      <VideoAd/>
      <TestimoniulShow/>
      <WorkProcess/>
      <AppoinmentHome/>
      <BlogShow/>
    </div>
  );
};

export default Home;
