import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const RdDetails = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Our Factory Details';
      }, []);

    const { http } = Api();
    const { id } = useParams();
    const httpbackPage = ServerUrl;
    const [singleLayouts, setSingleLayouts] = useState({});
    const [tableData, setTableData] = useState([]);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        http.get(`get-our-factory-rd/details/${id}`)
            .then(res => { 
                setSingleLayouts(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, [id]); 

    useEffect(() => {
        http.get('/get-our-factory-rd')
          .then(res => { 
            setTableData(res.data);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
          window.scrollTo(0, 0);
      }, []);

      const truncateString = (str, num) => {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + "...";
        }

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>{singleLayouts.title}</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Our Factory</li>
                        <li>{singleLayouts.title}</li>
                    </ul>
                </div>
            </section>
            <div className="sidebar-page-container">
                <div className="auto-container">
                <FadeInOut>
                    <div className="row clearfix">
                        {/*Content Side / Services Detail */}
                        <div className="content-side col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="services-detail">
                                <div className="row inner-box">
                                  
                                    <div className="col-md-8 mt-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="image">
                                                    {singleLayouts.url && (
                                                        <iframe
                                                        width="100%"
                                                        height="400"
                                                        src={singleLayouts.url}
                                                        title="FootBalance Medical Concept"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen
                                                        ></iframe>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="content-column  col-md-12">
                                                <div className="inner-column">
                                                    <div className="text mb-3" dangerouslySetInnerHTML={{ __html: singleLayouts.description }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="content-column  col-md-4 mt-4">
                                        <div className="inner-column">
                                            {tableData.map((data) => (
                                                <div key={data.id} className="mb-4 p-4">
                                                    <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div className="image">
                                                            <Link to={`/our-factory/research-development-details/${data.id}`}><img src={httpbackPage + '/backend/img/rd/' + data.image} alt='' /></Link>
                                                        </div>
                                                        <div className="lower-content">
                                                            <h3><Link to={`/our-factory/research-development-details/${data.id}`}>{data.title}</Link></h3>
                                                            <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(data.description, 100) }}></div>
                                                            <span><Link to={`/our-factory/research-development-details/${data.id}`} className="read-more">Read more</Link></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    </FadeInOut>
                </div>
            </div>
        </div>
    )
}

export default RdDetails;
