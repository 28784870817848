import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import FadeInOut from '../../tools/FadeInOut';
import { ServerUrl } from '../../api/ServerUrl';

const Case = () => {
    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Case Study';
      }, []);

    const { http } = Api();
    const { id } = useParams();
    const [caseCategory, setCaseCategory] = useState([]);
    const [singleLayouts, setSingleLayouts] = useState({});
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        // Fetch case categories
        http.get('/get-case-study-list')
            .then(res => { 
                setCaseCategory(res.data);
                // If id is not provided in the URL, fetch the first category
                if (!id && res.data.length > 0) {
                    fetchSingleLayout(res.data[0].id);
                }
            })
            .catch(error => {
                console.error('Error fetching case categories:', error);
            });
    }, []);

    useEffect(() => {
        // Fetch single layout when id changes
        if (id) {
            fetchSingleLayout(id);
        }
    }, [id]);

    const fetchSingleLayout = (categoryId) => {
        http.get(`get-case-study-list-single/${categoryId}`)
            .then(res => { 
                setSingleLayouts(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching single layout:', error);
            });
    }

    return (
        <div className='cusmaincolor'>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Case Study</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Services</li>
                        <li>Case Study</li>
                        <li>{singleLayouts.category}</li>
                    </ul>
                </div>
            </section>

            <section>
                <div className="auto-container mt-5 pb-5">
                <FadeInOut>
                    <div className="row">
                        <div className="col-md-3">
                            {caseCategory.map((data) => (
                                <p key={data.id}><Link to={`/case-study/${data.id}`} className={`nav-link border rounded p-2 ${data.id === singleLayouts.id ? 'activeCus' : ''}`}>{data.category}</Link></p>
                            ))}
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body p-5">
                                    <div className="text-center">
                                        <h3 className='bold-text text-uppercase'>{singleLayouts.category}</h3>
                                    </div>
                                    <div className="mt-3">
                                        <p className='fw-bold'>{singleLayouts.title}</p>
                                        <div className="text mb-3" dangerouslySetInnerHTML={{ __html: singleLayouts.description }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInOut>
                </div>
            </section>
        </div>
    )
}

export default Case;
