import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FadeInOut from '../tools/FadeInOut';
import { ServerUrl } from '../api/ServerUrl';
import { Api } from '../api/Api';
import { TbTriangleFilled } from "react-icons/tb";

const HomeProducts = () => {
    const { http } = Api();
    const [productCategory, SetProductCategory] = useState([]);
    const [allProduct, setAllProduct] = useState([]);
    const [productCategoryId, setProductCategoryId] = useState([]);
    const httpCPage = ServerUrl;

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activeDropdownByGroup, setActiveDropdownByGroup] = useState(null);
    const [activeDropdownByCategory, setActiveDropdownByCategory] = useState('');

    const toggleDropdown = (index) => {
      setActiveDropdown(activeDropdown === index ? null : index);
    };

    useEffect(() => {
        http.get('/front-product-category-home')
          .then(res => { 
            const productCategories = res.data.products_category;
            SetProductCategory(productCategories);
            const firstGroupName = Object.keys(productCategories)[0];
            if (firstGroupName) {
                const firstProduct = productCategories[firstGroupName][0];
                if (firstProduct) {
                    fetchData(firstProduct.id, null);
                }
            }
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
    }, []);
    const fetchData = (category, categoryName) => {
        setProductCategoryId(category);
        http.get(`/front-get-all-product_by_category?category_id=${category ? category : null}&category_name=${categoryName ? categoryName : null}`)
          .then(res => {
            setAllProduct(res.data.products || []);
          })
          .catch(error => {
            console.error('Error fetching products:', error);
          });
      };
    
      const getDataByCategory = (category, categoryName, groupName) =>  {
        setActiveDropdown(null);
        fetchData(category, categoryName);
        setActiveDropdownByGroup(groupName);
        setActiveDropdownByCategory(category);
      };

      

    return (
        <div>
            <FadeInOut>
                <section className="container mt-5">
                    <div className="outer-container">
                        <div className="sec-title centered light">
                            <h2 className='custom2ndColor'>Our Products</h2>
                        </div>
                        <div className="d-md-flex justify-content-center mt-1 mb-5 px-2 mx-3">
                        {Object.entries(productCategory).map(([groupName, products], index) => (
                            <div className="homeProductGroup me-3 p-0" key={index}>
                                <label onClick={() => toggleDropdown(index)} className={` me-3 homeProductGroup ProductBtn m-0 p-0 ${activeDropdown === index ? 'ProductButtonActive' : ''} ${activeDropdownByGroup === groupName ? 'ProductButtonActive' : ''}`}>
                                    {groupName}
                                </label> 
                                <div className={`sub-product-name ${activeDropdown === index ? 'show' : ''}`}>
                                    <TbTriangleFilled className='bottom-indicate' />
                                    <div className="sub-product-box">
                                        {products.map((product) => (
                                            <p key={product.id} onClick={() => getDataByCategory(product.id, product.name, groupName)} className={`${activeDropdownByCategory === product.name ? 'ProductButtonActive' : ''}`}>
                                                {product.name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        <div className="product-box-home">
                        <div className="row">
                                {/* Second product onwards in a row */}
                                <div className="col-md-12">
                                    <div className="row justify-content-center">
                                        <div className="col-md-11">
                                            <div className="row mb-5 ">
                                                {allProduct && allProduct.length >= 1 ? (
                                                    allProduct.map((product, index) => (
                                                        <div className="shop-item col-lg-4 col-md-4 col-sm-12 rounded" key={index}>
                                                            <div className="HomeProductBoxSmall rounded">
                                                                <FadeInOut>
                                                                    <div className="productBox2 rounded p-3">
                                                                        <div className="HomeImageProduct rounded">
                                                                            <p className='productCategoryNameTop'>{product.category_name}</p>
                                                                            <Link to={`/product-details/${product.id}`}>
                                                                                <img className='HomeProductImage' src={`${httpCPage}/backend/img/product/${product.images[0].image}`} alt={product.product_name} />
                                                                            </Link>
                                                                            <h3 className='d-grid'><Link className='mb-4 text-dark' to={`/product-details/${product.id}`}>{product.product_name}</Link></h3>
                                                                        </div>
                                                                    </div>
                                                                </FadeInOut>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : 'No Products'}

                                                <div className="text-center">
                                                    <div className="btns-box">
                                                        <Link to="/product-list" className="theme-btn btn-style-four"><span className="txt">More Products</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FadeInOut>
            
        </div>
    );
};

export default HomeProducts;
