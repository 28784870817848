import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const ProcessEdit = () => {
    const { http } = ProtectAPi();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loader, setLoader] = useState('off');
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');

    useEffect(() => {
        // Fetch process data to be edited
        http.get(`/get-single-work-process/${id}`)
            .then(res => {
                setTitle(res.data.title);
                setDescription(res.data.description);
            })
            .catch(error => {
                console.error('Error fetching process:', error);
            });
    }, [id]); // Dependency array ensures useEffect runs only when id changes

    const submitForm = () => {
        setLoader('on');
        setTitleError('');
        setDescriptionError('');
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);

        http.post(`/update-work-process/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                const errors = res.data.error;
                setTitleError(errors.title ? errors.title[0] : '');
                setDescriptionError(errors.description ? errors.description[0] : '');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div>
            <div className="main-content">
                <section className="section userr">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-2">
                                            <h5>Edit Process</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>

                                        <div className="row mx-3">
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-4 p-1">
                                                            <label className="col-form-label">Title</label>
                                                            <input type="text" className="form-control" value={title} onChange={e => setTitle(e.target.value)} />
                                                            <span className='text-danger'>{titleError}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group row mb-4 p-1">
                                                            <label className="col-form-label">Description</label>
                                                            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)}></textarea>
                                                            <span className='text-danger'>{descriptionError}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ? (
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            ) : (
                                                <div className="spinner-border text-dark" role="status"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ProcessEdit;
