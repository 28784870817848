import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import { SetPermission } from '../../../../../auth/SetPermission';

const ProcessList = () => {

  const { http } = ProtectAPi();
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const userPermission = SetPermission();

  useEffect(() => {
    http.get('/get-work-process')
      .then(res => { 
        setTableData(res.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  
  useEffect(() => {
      if (tableData.length > 0 && !tableRef.current.DataTable) {
          $(tableRef.current).DataTable();
      } else {
          if ($.fn.DataTable.isDataTable('#table-1')) {
              $(tableRef.current).DataTable().destroy();
          }
      }
  }, [tableData]);


  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Work Process List</h5>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Title</th>
                              <th>Review</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((data, index) => (
                              <tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.title}</td>
                                <td>{data.description}</td>
                                <td>
                                {userPermission.includes('home_all_edit') && (<Link to={`/admin/pages/home/work-process-edit/${data.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <i className="fas me-2 fa-edit text-white"></i>
                                      </button>
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default ProcessList