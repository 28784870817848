import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';

const ProductCategory = () => {

    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [data, SetData] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/get-all-product-category')
        .then(res => { 
            SetData(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    useEffect(() => {
      if (data.length > 0 && !tableRef.current.DataTable) {
        $(tableRef.current).DataTable();
      }
    }, [data]);


     // delete permission
     const handleDeleteRole = (id) => {
          swal({
              title: 'Are you sure?',
              text: 'Once deleted, you will not be able to recover this Category!, It can be harmful to your system',
              icon: 'warning',
              buttons: true,
              dangerMode: true,
          }).then((willDelete) => {
              if (willDelete) {
                  http.delete(`/delete-product-category/${id}`)
                    .then(response => {
                        if(response.data.status != 201){
                          SetData(prevRoles => prevRoles.filter(data => data.id !== id));
                        }
                          
                          swal(response.data.msg, {
                              icon: response.data.alert,
                          });
                    })
                    .catch(error => {
                          swal('Oops! Something went wrong while deleting the category.', {
                              icon: 'error',
                          });
                    });
                  swal('Poof! The Category has been deleted!', {
                      icon: 'success',
                  });
              } else {
                  swal('The Category is safe!');
              }
          });
        };


        


  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Product Category</h5>
                            <div class="text-end">
                              {userPermission.includes('permission_create') && (<Link to="/admin/product-category-add" class="btn btn-sm btn-primary"><i class="fas fa-plus"></i> Create Product Category</Link>)}
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Group Name</th>
                              <th>Category Name</th>
                              <th>Show in Home</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((data, index) => (
                              <tr key={data.id}>
                                <td>{index + 1}</td>
                                <td>{data.group_name}</td>
                                <td>{data.name}</td>
                                <td>
                                    {data.home_show === 1 && <span className='badge badge-primary'>Active</span> }
                                    {data.home_show === 0 && <span className='badge badge-danger'>De-Active</span> }
                                </td>
                                <td>
                                  {userPermission.includes('product_category') && (<Link to={`/admin/product-category-edit/${data.id}`} className="editbtn mr-1">
                                    <button className="btn btn-warning">
                                      <i className="fas me-2 fa-edit text-white"></i>
                                    </button>
                                  </Link>
                                  )}
                                  {userPermission.includes('product_category') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(data.id)}>
                                            <i className="fas me-2 fa-trash text-white"></i>
                                        </button>
                                    </Link>)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  )
}

export default ProductCategory