import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const Fhih = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Foot Health Information Hub';
      }, []);

    const { http } = Api();
    const httpbackPage = ServerUrl;
    const [layouts, setLayouts] = useState([]);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get('/foot-health-information-hub')
            .then(res => { 
                setLayouts(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, []);


    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

  return (
    <div>
        <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Foot Health
                    Information Hub (FHIH)</h2>
                <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                    <li>Foot Health
                        Information Hub</li>
                </ul>
            </div>
        </section>

        {layouts.map((layout, index) => (
                <section key={index} className={`story-section pt-5 ${index % 2 === 0 ? 'customBg' : 'customBg2'}`}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            {index % 2 !== 0 ? (
                                <>
                                 <FadeInOut>
                                    <div className="row mb-4">
                                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2 order-md-1 order-lg-1">
                                            <div className="inner-column mt-5">
                                                <h2>{layout.title}</h2>
                                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 500) }}></div>
                                                <Link to={`/foot-health-information-hub-details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                            </div>
                                        </div>
                                        <div className="image-column col-lg-6 col-md-12 col-sm-12 order-1 order-md-2 order-lg-2">
                                            <div className=" mt-4">
                                                <div className="">
                                                    <img alt="" src={`${httpbackPage}/backend/img/fhih/${layout.image}`} className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </FadeInOut>
                                </>
                            ) : (
                                <>
                                    <FadeInOut>
                                    <div className="row mb-4">
                                        <div className="image-column col-lg-6 col-md-12 col-sm-12  order-1 order-md-1 order-lg-1">
                                            <div className="mt-2 mb-4">
                                                <div className="">
                                                    <img alt="" src={`${httpbackPage}/backend/img/fhih/${layout.image}`} className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2 order-md-2 order-lg-2">
                                            <div className="inner-column mt-3">
                                                <h2>{layout.title}</h2>
                                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 500) }}></div>
                                                <Link to={`/foot-health-information-hub-details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                            </div>
                                        </div>
                                    </div>
                                    </FadeInOut>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            ))}

    </div>
  )
}

export default Fhih