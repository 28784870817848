import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../tools/BackButton';

const FaqAdd = () => {
    const { http } = ProtectAPi();
    const navigate = useNavigate();
    const [loader, setLoader] = useState('off');
    const [question, setQuestion] = useState('');
    const [answare, setAnsware] = useState('');
    const [layoutTitleError, setLayoutTitleError] = useState('');
    const [layoutDescriptionError, setLayoutDescriptionError] = useState('');

    const submitForm = () => {
        setLoader('on');
        const formData = { question, answare };

        http.post(`/faq-add`, formData)
            .then(res => {
                if (res.data.status === '422') {
                    const errors = res.data.error;
                    setLoader('off');
                    setLayoutTitleError(errors.question ? errors.question[0] : '');
                    setLayoutDescriptionError(errors.answare ? errors.answare[0] : '');
                } else {
                    setLoader('off');
                    navigate('/admin/pages/faq');
                    swal('Success', res.data.msg, 'success');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'question') {
            setQuestion(value);
        } else if (name === 'answare') {
            setAnsware(value);
        }
    };

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5>Add Foot Health Information Hub</h5>
                                        <div className="">
                                            <BackButton />
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <div className="form-group">
                                                    <label>Question</label>
                                                    <input type="text" name="question" value={question} className="form-control ms-0" placeholder='Question' onChange={handleInputChange} />
                                                    <span className='text-danger'>{layoutTitleError}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="form-group">
                                                    <label>Answer</label>
                                                    <textarea name="answare" value={answare} className="form-control" placeholder='Answer' onChange={handleInputChange}></textarea>
                                                    <span className='text-danger'>{layoutDescriptionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <label className="col-form-label"></label>
                                        {loader === 'off' ?
                                            <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button> :
                                            <div className="spinner-border text-dark" role="status"></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FaqAdd;
