import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const OurFactory = () => {

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Our Factory';
      }, []);

    const { http } = Api();
    const httpbackPage = ServerUrl;
    const [factoryInfo, setFactoryInfo] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get('/get-our-factory-info')
            .then(res => { 
                setFactoryInfo(res.data.data);
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, []);

    useEffect(() => {
        http.get('/get-our-factory-rd')
          .then(res => { 
            setTableData(res.data);
          })
          .catch(error => {
            console.error('Error fetching users:', error);
          });
      }, []);

      const truncateString = (str, num) => {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + "...";
        }

  return (
    <div>
        <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Our Factory</h2>
                <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                <li>Our Factory</li>
                </ul>
            </div>
        </section>

        <FadeInOut>
        <section className="our-factory style-two">
            <div className="auto-container mt-5">
                    {factoryInfo.map((data, index) => (
                        <React.Fragment key={index}>
                            {(index % 2 === 0) && ( 
                            <>
                                <div className="row">
                                    <div className="image-column col-xl-5 col-lg-4 col-md-12 col-sm-12 order-1 order-md-2 order-lg-2">
                                        <div className="inner-column m-0">
                                            <div className="image">
                                                {(data.info_type === 0) && ( 
                                                    <iframe width="100%" height={320} src={data.info_url} title="Foot Balance Technology - Experience The Pedorthic Difference" allowFullScreen />
                                                )}
                                                {(data.info_type === 1) && ( 
                                                    <img src={`${ServerUrl}/backend/img/factory/${data.image}`} alt="" />
                                                )}
                                                    
                                            </div>
                                        </div>
                                    </div>

                                    <div className="content-column col-xl-7 col-lg-8 col-md-12 col-sm-12 order-2 order-md-1 order-lg-1">
                                        <div className="inner-column m-0">
                                            <h2>{data.info_title}</h2>
                                            <div className="text mb-3" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                        </div>
                                    </div>
                                </div>
                              </>
                             )}

                            {(index % 2 !== 0) && ( 
                                <>
                                    <div className="row">
                                        <div className="content-column col-xl-7 col-lg-8 col-md-12 col-sm-12 order-2 order-md-2 order-lg-2">
                                            <div className="inner-column m-0">
                                                <h2>{data.info_title}</h2>
                                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                                            </div>
                                        </div>

                                        <div className="image-column col-xl-5 col-lg-4 col-md-12 col-sm-12 order-1 order-md-1 order-lg-1">
                                            <div className="inner-column m-0">
                                                <div className="image">
                                                {(data.info_type === 0) && ( 
                                                    <iframe width="100%" height={320} src={data.info_url} title="Foot Balance Technology - Experience The Pedorthic Difference" allowFullScreen />
                                                )}
                                                {(data.info_type === 1) && ( 
                                                    <img src={`${ServerUrl}/backend/img/factory/${data.image}`} alt="" />
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                             )}

                            

                            
                        </React.Fragment>
                    ))}
            </div>
        </section>
        </FadeInOut>

        <section className="services-page-section style-two">
            <div className="auto-container">
                {/* Sec Title */}
                <FadeInOut>
                <div className="sec-title centered">
                    <h2>Research &amp; Development</h2>
                </div>
                </FadeInOut>
                <div className="row clearfix">
            
                {tableData.map((data) => (
                    <div key={data.id} className="service-block-three style-two col-lg-4 col-md-6 col-sm-12">
                        <FadeInOut>
                        <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <div className="image">
                                <Link to={`/our-factory/research-development-details/${data.id}`}><img src={httpbackPage + '/backend/img/rd/' + data.image} alt='' /></Link>
                            </div>
                            <div className="lower-content">
                                <h3><Link to={`/our-factory/research-development-details/${data.id}`}>{data.title}</Link></h3>
                                <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(data.description, 100) }}></div>
                                <span><Link to={`/our-factory/research-development-details/${data.id}`} className="read-more">Read more</Link></span>
                            </div>
                        </div>
                        </FadeInOut>
                    </div>
                ))}
                    
                </div>
            </div>
        </section>

    
    </div>
  )
}

export default OurFactory