import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const ProductPagesAdd = () => {

  const navigate = useNavigate();
  const editor = useRef(null);
  const { http } = ProtectAPi();
  const [loader, setLoader] = useState('off');
  const [caseCategory, setCaseCategory] = useState('');
  const [caseTitle, setCaseTitle] = useState('');
  const [caseDescription, setCaseDescription] = useState('');
  const [caseCategoryError, setCaseCategoryError] = useState('');
  const [caseTitleError, setCaseTitleError] = useState('');
  const [caseDescriptionError, setCaseDescriptionError] = useState('');

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === 'caseCategory') {
          setCaseCategory(value);
      } else if (name === 'caseTitle') {
          setCaseTitle(value);
      }
  };


  const submitForm = () => {
      setLoader('on');
      const formData = new FormData();
      formData.append('priority', caseCategory);
      formData.append('title', caseTitle);
      formData.append('description', caseDescription);

      http.post(`/add-product-pages`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
      }).then(res => {
          if (res.data.status === '422') {
              const errors = res.data.error;
              setLoader('off');
              setCaseCategoryError(errors.category ? errors.category[0] : '');
              setCaseTitleError(errors.title ? errors.title[0] : '');
              setCaseDescriptionError(errors.description ? errors.description[0] : '');
          } else {
              setLoader('off');
              navigate('/admin/pages/product-pages-list');
              swal('Success', res.data.msg, 'success');
          }
      }).catch(error => {
          console.error('Error:', error);
      });
  };

  const placeholder = 'Start typing...'; // placeholder variable was missing
  const editorConfig = useMemo(
      () => ({
          readonly: false,
          placeholder: placeholder || 'Start typing...',
          uploader: {
              insertImageAsBase64URI: true // Fixed the syntax error
          }
      }),
      [placeholder]
  );

  return (
    <div>
       <div className="main-content">
              <section className="section">
                  <div className="section-body">
                      <div className="row">
                          <div className="col-12">
                              <div className="card card-primary">
                                  <div className="card-body">
                                      <div className="d-flex justify-content-between">
                                          <h5>Add New Product Pages</h5>
                                          <div className="">
                                              <BackButton />
                                          </div>
                                      </div>
                                      <div className="card-body pb-0">
                                          <div className="row mb-3">
                                              <div className="col-md-12">
                                                  <label>Priority</label>
                                                  <input type="number" id="caseCategory" name="caseCategory" value={caseCategory} className="form-control ms-0" placeholder='Case category' onChange={handleInputChange} />
                                                  <span className='text-danger'>{caseCategoryError}</span>
                                              </div>
                                              <div className="col-md-12 mt-2">
                                                  <label>Title</label>
                                                  <input type="text" id="caseTitle" name="caseTitle" value={caseTitle} className="form-control ms-0" placeholder='Case title' onChange={handleInputChange} />
                                                  <span className='text-danger'>{caseTitleError}</span>
                                              </div>
                                              <div className="col-md-12 mt-4">
                                                  <label>Description</label>
                                                <JoditEditor
                                                        ref={editor}
                                                        value={caseDescription}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setCaseDescription(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                  <span className='text-danger'>{caseDescriptionError}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="text-center mt-5">
                                          <label className="col-form-label"></label>
                                          {loader === 'off' &&
                                              <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button>
                                          }
                                          {loader === 'on' &&
                                              <div className="spinner-border text-dark" role="status"></div>
                                          }
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
    </div>
  )
}

export default ProductPagesAdd