import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { format } from 'date-fns';

const SmsBalance = () => {
  const { http } = ProtectAPi();
  const [thisMonth, setThisMonth] = useState('');
  const [totalSent, setTotalSent] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'yyyy-MM')); 

  useEffect(() => {
    const reversedMonth = selectedMonth.split('-').reverse().join('-');
    getDataByThisMonth(reversedMonth);
  }, [selectedMonth]);

  useEffect(() => {
    getDataByTotal();
  }, []);

  function getDataByThisMonth(month) {
    http.get(`/sent-sms-check-month/${month}`)
      .then(res => {
        setThisMonth(res.data.sms_sent);
      })
      .catch(error => {
        console.error('Error fetching SMS data for the month:', error);
      });
  }

  function getDataByTotal() {
    http.get(`/sent-sms-check`)
      .then(res => {
        setTotalSent(res.data.sms_sent);
      })
      .catch(error => {
        console.error('Error fetching total SMS data:', error);
      });
  }

  function handleMonthChange(e) {
    const selected = e.target.value;
    setSelectedMonth(selected);
  }

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list" className="btn border btn-sm mr-2">Pending List</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list-confirmed" className="btn border btn-sm mr-2">Appoinment Confirmed</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-list-today" className="btn border btn-sm mr-2">Appoinment Today</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/appoinment-scheduled-confirm" className="btn border btn-sm mr-2">Scheduled Confirm</Link>
                      </div>
                      <div className="mb-2">
                        <Link to="/admin/pages/sms-balance" className="btn btn-primary btn-sm mr-2">Check SMS</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="text-center my-3">
                    <h1>Sms Balance Check</h1>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <input
                          type="month"
                          className="form-control"
                          value={selectedMonth}
                          onChange={handleMonthChange} // Update the selected month when user selects a new one
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <p>This Month Total</p>
                            <h1>{thisMonth}</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <p>Life Time Total</p>
                            <h1>{totalSent}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SmsBalance;
