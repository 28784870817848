import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const FooterCategoryEdit = () => {
    const navigate = useNavigate();
    const { http } = ProtectAPi();
    const { id } = useParams();
    const [loader, setLoader] = useState('off');
    const [categoryName, setCategoryName] = useState('');
    const [categoryLinks, setCategoryLinks] = useState([]);
    const [categoryLinkTitles, setCategoryLinkTitles] = useState([]);

    useEffect(() => {
        http.get(`/setting-get-single-category/${id}`)
        .then(res => { 
            setCategoryName(res.data[0].categoryName);
            const links = res.data.map(item => item.categoryLink);
            const titles = res.data.map(item => item.categoryLinkTitle);
            setCategoryLinks(links);
            setCategoryLinkTitles(titles);
        })
        .catch(error => {
            console.error('Error fetching category:', error);
        });
    }, []);

    const handleNameChange = (e) => {
        setCategoryName(e.target.value);
    };

    const handleLinkChange = (index, e) => {
        const { value } = e.target;
        const newCategoryLinks = [...categoryLinks];
        newCategoryLinks[index] = value;
        setCategoryLinks(newCategoryLinks);
    };

    const handleTitleChange = (index, e) => {
        const { value } = e.target;
        const newCategoryLinkTitles = [...categoryLinkTitles];
        newCategoryLinkTitles[index] = value;
        setCategoryLinkTitles(newCategoryLinkTitles);
    };

    const addRow = () => {
        setCategoryLinks([...categoryLinks, '']);
        setCategoryLinkTitles([...categoryLinkTitles, '']);
    };
    
    const removeRow = (index) => {
        const newCategoryLinks = [...categoryLinks];
        newCategoryLinks.splice(index, 1);
    
        const newCategoryLinkTitles = [...categoryLinkTitles];
        newCategoryLinkTitles.splice(index, 1);
    
        setCategoryLinks(newCategoryLinks);
        setCategoryLinkTitles(newCategoryLinkTitles);
    };

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        categoryLinks.forEach((link, index) => {
            formData.append(`categoryLinks[${index}]`, link);
            formData.append(`categoryLinkTitles[${index}]`, categoryLinkTitles[index]);
        });

        formData.append('categoryName', categoryName);

        http.post(`/setting-update-single-category/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                swal('Error', res.data.msg, 'error');
            } else {
                setLoader('off');
                navigate('/admin/settings/footer-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

  return (
    <div>
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5>Edit Category</h5>
                                        <div className="">
                                            <BackButton />
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <input type="text" value={categoryName} className="form-control" placeholder='Category Name' onChange={handleNameChange} />
                                            </div>
                                        </div>
                                        {categoryLinks.map((link, index) => (
                                            <div key={index} className="row mb-3">
                                                <div className="col-md-6">
                                                    <input type="text" value={link} className="form-control" placeholder='Category Link' onChange={(e) => handleLinkChange(index, e)} />
                                                </div>
                                                <div className="col-md-4">
                                                    <input type="text" value={categoryLinkTitles[index]} className="form-control" placeholder='Category Link Title' onChange={(e) => handleTitleChange(index, e)} />
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-danger" onClick={() => removeRow(index)}>-</button>
                                                    {index === categoryLinks.length - 1 ? <button className="btn btn-primary ml-2" onClick={addRow}>+</button> : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="text-center mt-5">
                                        <label className="col-form-label"></label>
                                        {loader === 'off' ?
                                            <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                                            :
                                            <div className="spinner-border text-dark" role="status"></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  );
};

export default FooterCategoryEdit;
