import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import {ServerUrl} from "../../api/ServerUrl";
import {FrontUrl} from "../../api/FrontUrl";
import FadeInOut from '../../tools/FadeInOut';

const AboutUs = () => {
    const { http } = Api();
    const httpFrontCPage = FrontUrl;
    const httpbackPage = ServerUrl;
    const [layouts, setLayouts] = useState([]);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        http.get('/get-about-layouts')
            .then(res => { 
                const content = res.data.layout;
                setLayouts(res.data.layout);
                setBackImage(res.data.backImage);

                document.title = 'Foot Balance Technology BD - About Us';
                
                const metaTag = document.querySelector('meta[name="Foot Balance Technology BD"]');
                if (metaTag) {
                metaTag.setAttribute('content', content);
                }
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    
    }, []);

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

  return (
    <div>
        <section className="page-title pageTitleBg" style={{backgroundImage: `url(${httpbackPage}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>About us</h2>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    <li>About us</li>
                </ul>
            </div>
        </section>

        <FadeInOut>
        <section className="mission-section style-two pb-0">
            <div className="auto-container mt-5">
                <div className="row clearfix ">
                {/* Image Column */}
                <div className="image-column col-xl-5 col-lg-4 col-md-12 col-sm-12">
                    <div className="inner-column">
                    <div className="image">
                        <img src={`${httpFrontCPage}/assets/images/resource/members.jpg`} className="rounded" alt='foot balance team' />
                    </div>
                    </div>
                </div>
                {/* Content Column */}
                <div className="content-column col-xl-7 col-lg-8 col-md-12 col-sm-12">
                    <div className="inner-column">
                    <h2 className='mb-1'>Our Team</h2>
                    <div className="text text-justify">
                        Our team of experts specializes in recommending and producing the perfect shoes that are tailored to your unique needs. Whether you were born with foot health challenges, have acquired them from disease or poorly fitting shoes, or simply require extra support as you age - we're here to help.
                    </div>
                    <div className="text text-justify">
                    At Foot Balance Technology, our team is committed to providing you with the highest quality products that are designed to improve your mobility, enhance your confidence, and help you reach your goals. No matter what your circumstances or difficulties are, the Foot Balance Technology team is here to offer assistance.
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </FadeInOut>
        <FadeInOut>
        <section className="mission-section style-two">
            <div className="container mt-5">
                <div className="row clearfix ">
                <div className="content-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="inner-column">
                    <h2 className='mb-1'>Our Departments</h2>
                    <div className="text text-justify">
                    Our departments at Foot Balance Technology BD encompass a diverse range of functions essential for our operations:
                    </div>
                    <div className="text text-justify">
                        <ul>
                            <li>1. Marketing & Logistics</li>
                            <li>2. Accounts & Administration</li>
                            <li>3. Product Development</li>
                            <li>4. Upper Department</li>
                            <li>5. Bottom Department</li>
                            <li>6. Finishing Department</li>
                            <li>7. CAD (Computer Aided Design) & CAM (Computer Aided Manufacturing)</li>
                        </ul>
                        <br />
                        Each department plays a crucial role in ensuring the success and efficiency of our business processes.
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </FadeInOut>
            {layouts.map((layout, index) => (
                <section key={index} className={`story-section pt-5 ${index % 2 !== 0 ? 'customBg' : 'customBg2'}`}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            {index % 2 === 0 ? (
                                <>
                                    <FadeInOut>
                                    <div className="row">
                                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                        <div className="inner-column mt-5">
                                            <h2>{layout.title}</h2>
                                            <div className="bold-text">{layout.subtitle}</div>
                                            <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 800) }}></div>
                                            <Link to={`/about/details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                        </div>
                                    </div>
                                    <div className="image-column col-lg-4 col-md-12 col-sm-12">
                                        <div className="inner-column mt-4">
                                            <div className="image">
                                                <img alt="" src={`${httpbackPage}/backend/img/pages/about/${layout.image}`} className='rounded' width="35" />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </FadeInOut>
                                </>
                            ) : (
                                <>
                                    <FadeInOut>
                                   <div className="row">
                                   <div className="image-column col-lg-4 col-md-12 col-sm-12">
                                        <div className="inner-column mt-2">
                                            <div className="image">
                                                <img alt="" src={`${httpbackPage}/backend/img/pages/about/${layout.image}`} className='rounded' width="35" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                        <div className="inner-column mt-3">
                                            <h2>{layout.title}</h2>
                                            <div className="bold-text">{layout.subtitle}</div>
                                            <div className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(layout.description, 800) }}></div>
                                            <Link to={`/about/details/${layout.id}`} className="see-more"><b>Show More</b></Link>
                                        </div>
                                    </div>
                                   </div>
                                    </FadeInOut>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            ))}


    </div>
  )
}

export default AboutUs