import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SetPermission } from '../../auth/SetPermission';
import { FcHome } from "react-icons/fc";
import { FcAbout } from "react-icons/fc";
import { FcServices } from "react-icons/fc";
import { FcBriefcase } from "react-icons/fc";
import { FcFactory } from "react-icons/fc";
import { MdHealthAndSafety } from "react-icons/md";
import { FcContacts } from "react-icons/fc";
import { AiFillSchedule } from "react-icons/ai";
import { FaStore } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import { FaRobot } from "react-icons/fa";
import { MdNoteAdd } from "react-icons/md";
import { FaImages } from "react-icons/fa";
import { BiSolidDashboard } from "react-icons/bi";

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('');
  const userPermission = SetPermission();
 
  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      //setActiveMenu('');
    } else {
      setActiveMenu(menu);
    }
  };

  return (
    <div>
      <div className="main-sidebar sidebar-style-2">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <Link to="/admin/dashboard">
              <img alt="" src="http://footdashboard.ipsitacomputersltd.com/favicon.png" className="header-logo mr-2" />
              <span className="logo-name">Foot Balance</span>
            </Link>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header">Main</li>
            <li className={`dropdown ${activeMenu === 'dashboard' ? 'active' : ''}`}>
              <Link to="/admin/dashboard" className="nav-link" onClick={() => toggleMenu('dashboard')}>
                <BiSolidDashboard className='mt-1 ml-2 mr-2' /><span>Dashboard</span>
              </Link>
            </li>
            {userPermission.includes('home_all_view') && (
              <React.Fragment>
                <li className="menu-header">Pages</li>
                <li className={`dropdown ${activeMenu === 'home-pages' ? 'open' : ''} ${activeMenu === 'home-pages' ? 'active' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('home-pages')}>
                  <FcHome className='mt-1 ml-2 mr-2' /><span>Home</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/layouts" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Home Layouts
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/award-list" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Award List
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/video-ad" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Video Ad
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/testimoniul-list" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Testimoniuls
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/work-process-list" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Work Process
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('home_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/blog-list" className={`nav-link ${activeMenu === 'home-pages' ? 'active' : ''}`} onClick={() => toggleMenu('home-pages')}>
                          Blog List
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('about_all_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'about-pages' ? 'open' : ''} ${activeMenu === 'about-pages' ? 'active' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('about-pages')}>
                  <FcAbout className='mt-1 ml-2 mr-2'/><span>About</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {userPermission.includes('about_all_view') && (
                      <li>
                        <Link to="/admin/pages/about/layouts" className={`nav-link ${activeMenu === 'about-pages' ? 'active' : ''}`} onClick={() => toggleMenu('about-pages')}>
                          About Layouts
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('about_all_view') && (
                      <li>
                        <Link to="/admin/pages/about/mission-vission" className={`nav-link ${activeMenu === 'about-pages' ? 'active' : ''}`} onClick={() => toggleMenu('about-pages')}>
                          Mission Vission
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('about_all_view') && (
                      <li>
                        <Link to="/admin/pages/home/publication-list" className={`nav-link ${activeMenu === 'about-pages' ? 'active' : ''}`} onClick={() => toggleMenu('about-pages')}>
                          Publications
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('services_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'services-pages' ? 'active' : ''}`}>
                    <Link to="/admin/pages/home/services-list" className="nav-link" onClick={() => toggleMenu('services-pages')}>
                    <FcServices className='mt-1 ml-2 mr-2'/><span>Services</span>
                    </Link>
                  </li>
              </React.Fragment>
            )}

            {userPermission.includes('caseStudy_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'caseStudy-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/case-study-list" className="nav-link" onClick={() => toggleMenu('caseStudy-pages')}>
                  <FcBriefcase className='mt-1 ml-2 mr-2' /><span>Case Study List</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('factory_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'ourFactory-pages' ? 'open' : ''} ${activeMenu === 'ourFactory-pages' ? 'active' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('ourFactory-pages')}>
                  <FcFactory className='mt-1 ml-2 mr-2' /><span>Our Factory</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {userPermission.includes('factory_view') && (
                      <li>
                        <Link to="/admin/pages/our-factory-info-list" className={`nav-link ${activeMenu === 'ourFactory-pages' ? 'active' : ''}`} onClick={() => toggleMenu('ourFactory-pages')}>
                          Factory Info
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('research_development_view') && (
                      <li>
                        <Link to="/admin/pages/research-development" className={`nav-link ${activeMenu === 'ourFactory-pages' ? 'active' : ''}`} onClick={() => toggleMenu('ourFactory-pages')}>
                          Research & Development
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}
            {userPermission.includes('fhih_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'fhih-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/fhih" className="nav-link" onClick={() => toggleMenu('fhih-pages')}>
                  <MdHealthAndSafety className='mt-1 ml-2 mr-2' /><span>FHIH</span>
                  </Link>
                </li>
              </React.Fragment>
            )}
            {userPermission.includes('view_message') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'contact-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/contact-us" className="nav-link" onClick={() => toggleMenu('contact-pages')}>
                  <FcContacts className='mt-1 ml-2 mr-2' /><span>Contact Us</span>
                  </Link>
                </li>
              </React.Fragment>
            )}
            {userPermission.includes('confirm_date') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'appoinment-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/appoinment-list" className="nav-link" onClick={() => toggleMenu('appoinment-pages')}>
                  <AiFillSchedule className='mt-1 ml-2 mr-2' /><span>Appoinment</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('product_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'product-pages' ? 'open' : ''} ${activeMenu === 'product-pages' ? 'active' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('product-pages')}>
                  <FaStore className='mt-1 ml-2 mr-2' /><span>Products</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {userPermission.includes('product_view') && (
                      <li>
                        <Link to="/admin/products-list" className={`nav-link ${activeMenu === 'product-pages' ? 'active' : ''}`} onClick={() => toggleMenu('product-pages')}>
                          Product
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('product_category') && (
                      <li>
                        <Link to="/admin/product-category-list" className={`nav-link ${activeMenu === 'product-pages' ? 'active' : ''}`} onClick={() => toggleMenu('product-pages')}>
                          Product Category
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('product_view') && (
                      <li>
                        <Link to="/admin/product-review-list" className={`nav-link ${activeMenu === 'product-pages' ? 'active' : ''}`} onClick={() => toggleMenu('product-pages')}>
                          Request Review
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('product_view') && (
                      <li>
                        <Link to="/admin/pages/product-pages-list" className={`nav-link ${activeMenu === 'product-pages' ? 'active' : ''}`} onClick={() => toggleMenu('product-pages')}>
                          Product Pages
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('faq_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'faq-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/faq" className="nav-link" onClick={() => toggleMenu('faq-pages')}>
                  <FaQuestion className='mt-1 ml-2 mr-2' /><span>FAQ</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('bot_response_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'bot-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/bot" className="nav-link" onClick={() => toggleMenu('bot-pages')}>
                  <FaRobot className='mt-1 ml-2 mr-2' /><span>BOT</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('notice_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'notice-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/notice" className="nav-link" onClick={() => toggleMenu('notice-pages')}>
                  <MdNoteAdd className='mt-1 ml-2 mr-2' /><span>notice</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('image_view') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'backimage-pages' ? 'active' : ''}`}>
                  <Link to="/admin/pages/background-image-list" className="nav-link" onClick={() => toggleMenu('backimage-pages')}>
                  <FaImages className='mt-1 ml-2 mr-2' /><span>Background Image</span>
                  </Link>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('user_management') && (
              <React.Fragment>
                <li className="menu-header">Auth</li>
                <li className={`dropdown ${activeMenu === 'user-list' || activeMenu === 'add-user' ? 'open' : ''} ${activeMenu === 'user' ? 'active' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('user')}>
                  <i className="fa fa-user"></i><span>User</span>
                  </Link>
                  <ul className="dropdown-menu">
                    {userPermission.includes('user_view') && (
                      <li>
                        <Link to="/admin/users-list" className={`nav-link ${activeMenu === 'user' ? 'active' : ''}`} onClick={() => toggleMenu('user')}>
                          User List
                        </Link>
                      </li>
                    )}
                    {userPermission.includes('user_create') && (
                      <li>
                        <Link to="/admin/users-create" className={`nav-link ${activeMenu === 'user' ? 'active' : ''}`} onClick={() => toggleMenu('user')}>
                          Add User
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}

            
          {userPermission.includes('authentication') && (
              <React.Fragment>
                <li className={`dropdown ${activeMenu === 'authentication' ? 'active' : ''} ${activeMenu === 'role' || activeMenu === 'permission' ? 'open' : ''}`}>
                  <Link to="#" className="menu-toggle nav-link has-dropdown" onClick={() => toggleMenu('authentication')}>
                    <i class="fa fa-shield"></i><span>Authentication</span>
                  </Link>
                  <ul className="dropdown-menu">
                  {userPermission.includes('role_view') && (
                    <li>
                      <Link to="/admin/roles" className={`nav-link ${activeMenu === 'roles' ? 'active' : ''}`}  onClick={() => toggleMenu('authentication')}>
                        Role
                      </Link>
                    </li>
                    )}
                    {userPermission.includes('permission_view') && (
                    <li>
                      <Link to="/admin/permissions" className={`nav-link ${activeMenu === 'permission' ? 'active' : ''}`} onClick={() => toggleMenu('authentication')}>
                        Permission
                      </Link>
                    </li>
                    )}
                  </ul>
                </li>
              </React.Fragment>
            )}

            {userPermission.includes('view_setting') && (
              <React.Fragment>
                 <li className={`dropdown ${activeMenu === 'settings' ? 'active' : ''}`}>
                    <Link to="/admin/settings" className="nav-link" onClick={() => toggleMenu('settings')}>
                      <i className="fa fa-gear"></i><span>Settings</span>
                    </Link>
                  </li>
              </React.Fragment>
            )}
          </ul>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
