import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../api/Api';
import { FrontUrl } from '../api/FrontUrl';
import FadeInOut from '../tools/FadeInOut';

const VideoAd = () => {
    const { http } = Api();
    const httpCPage = FrontUrl;
    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        http.get(`/front-get-video-info`)
          .then(res => { 
            // Assuming res.data[0] contains the first video's information
            if (res.data && res.data.length > 0) {
                setVideoUrl(res.data[0].video_url);
                setDescription(res.data[0].description);
            }
          })
          .catch(error => {
            console.error('Error fetching video information:', error);
          });
    }, []);

    const handleVideoClick = (url) => {
        setVideoUrl(url);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setVideoUrl('');
    };

    return (
        <div>
            <FadeInOut>
            <section className="fluid-section-one">
                <div className="outer-container video-inner-column clearfix">
                    <div className="text-white position-relative">
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <div className="image-column">
                        <div className="inner-column ">
                            <img src={`${httpCPage}/assets/images/resource/video-img.jpg`} className='img-fluid' alt="Video" />
                            <Link to="#" className="overlay-link lightbox-image" onClick={() => handleVideoClick(videoUrl)}>
                                <div className="icon-box">
                                    <span className="icon flaticon-play-button"></span>
                                    <i className="ripple"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            </FadeInOut>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <iframe title="video" width="100%" height="315" src={videoUrl} frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VideoAd;
