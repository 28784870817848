import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { ProtectAPi } from '../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../tools/BackButton';

const BotResponseEdit = () => {
    const { http } = ProtectAPi();
    const { id } = useParams();
    const editor = useRef(null);
    const [loader, setLoader] = useState('off');
    const [question, setQuestion] = useState('');
    const [answare, setAnsware] = useState('');
    const [keyword, setKeyword] = useState('');
    const [layoutTitleError, setLayoutTitleError] = useState('');
    const [layoutDescriptionError, setLayoutDescriptionError] = useState('');
    const [keywordError, setKeywordError] = useState('');

    useEffect(() => {
        http.get(`/get-single-bot/${id}`)
            .then(res => {
                setQuestion(res.data.question);
                setAnsware(res.data.answar);
                setKeyword(res.data.keyword);
            })
            .catch(error => {
                console.error('Error fetching FAQ:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = { question, answare, keyword };

        http.post(`/update-bot/${id}`, formData)
            .then(res => {
                if (res.data.status === '422') {
                    const errors = res.data.error;
                    setLoader('off');
                    setLayoutTitleError(errors.question ? errors.question[0] : '');
                    setLayoutDescriptionError(errors.answare ? errors.answare[0] : '');
                    setKeywordError(errors.keyword ? errors.keyword[0] : '');
                } else {
                    setLoader('off');
                    swal('Success', res.data.msg, 'success');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'question') {
            setQuestion(value);
        } else if (name === 'keyword') {
          setKeyword(value);
        }
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );

    return (
        <div className="main-content">
            <section className="section">
                <div className="section-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5>Edit BOT Response</h5>
                                        <div className="">
                                            <BackButton />
                                        </div>
                                    </div>
                                    <div className="card-body pb-0">
                                        <div className="row">
                                            <div className="col-md-12 mt-4">
                                                <div className="form-group">
                                                    <label>Keyword</label>
                                                    <input type="text" name="keyword" value={keyword} className="form-control ms-0" placeholder='Keyword' onChange={handleInputChange} />
                                                    <span className='text-danger'>{keywordError}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="form-group">
                                                    <label>Question</label>
                                                    <input type="text" name="question" value={question} className="form-control ms-0" placeholder='Question' onChange={handleInputChange} />
                                                    <span className='text-danger'>{layoutTitleError}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="form-group">
                                                    <label>Answer</label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={answare}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setAnsware(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{layoutDescriptionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <label className="col-form-label"></label>
                                        {loader === 'off' ?
                                            <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button> :
                                            <div className="spinner-border text-dark" role="status"></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BotResponseEdit;