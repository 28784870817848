import React, { useEffect, useState } from 'react';

export const SetPermission = () => {
    const [userPermission, setUserPermission] = useState([]);
    useEffect(() => {
        const userPermissionsString = localStorage.getItem('userPermissions');
        if (userPermissionsString) {
            const getPermissions = JSON.parse(userPermissionsString);
            setUserPermission(getPermissions.permissions);
        }
    }, []);

    return userPermission;
}
