import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Api } from '../api/Api';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoIosSearch } from "react-icons/io";
import {ServerUrl} from "../api/ServerUrl";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaMicrophone } from "react-icons/fa";
import { MdOutlineRecordVoiceOver } from "react-icons/md";

function Header() {
    const navigate = useNavigate();
    const { pathname } = useLocation(); // Get the current pathname
    const { http } = Api();
    const httpCPage = ServerUrl;
    const [settings, setSettings] = useState({
        logo: '',
    });
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [offcanvasOpen, setOffcanvasOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownFHIH, setShowDropdownFHIH] = useState(false);

    useEffect(() => {
        http.get('/front-get-setting')
            .then(res => {
                const settingData = res.data[0];
                const logo = settingData.logo;
                setSettings({
                    logo: logo,
                });
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(currentScrollPos <= prevScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const navbarStyle = {
        transition: 'top 0.3s ease-in-out',
        top: visible ? '0' : '-80px', 
        position: 'fixed',
        width: '100%',
        zIndex: '1000'
    };

    const handleCloseOffcanvas = () => {
        setOffcanvasOpen(false); 
        setShowDropdown(false);
        setShowDropdownFHIH(false);
        window.scrollTo(0, 0);
    };

    const handleSearchClick = () => {
        setSearchOpen(!searchOpen);
    };
   
    const isActive = (path) => {
        return pathname === path ? 'ActiveMenu' : '';
    };

    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState('off');
    const [loaderVoice, setLoaderVoice] = useState('off');
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'search') {
            setSearch(value);
        }
    };

    const submitForm = (e, text) => {
        if (e) {
            e.preventDefault(); 
        }
        setLoader('on');
        setErrors({});
        const formData = new FormData();
        if(text) {
        formData.append('search', text);

        }else{
        formData.append('search', search);

        }
        http.post('/global-search', formData) 
            .then(res => {
                if (res.data.status === '422') {
                    setLoader('off');
                    setErrors(res.data.error);
                    setSearch('');
                } else {
                    setLoader('off');
                    setSearch('');
                    sessionStorage.removeItem('searchData');
                    sessionStorage.setItem('searchData', JSON.stringify(res.data));
                    sessionStorage.removeItem('searchKeyword');
                    sessionStorage.setItem('searchKeyword', text);
                    if (window.location.pathname === '/search') {
                        window.location.reload();
                    }
                    navigate('/search');
                    setSearchOpen(false);
                    setOffcanvasOpen(false); 
                    window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    
    };


    const handleInputChangeVoice = (event) => {
        setSearch(event.target.value);
    };

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const startVoiceRecognition = () => {
        if ('webkitSpeechRecognition' in window) {
            const recognition = new window.webkitSpeechRecognition();
            recognition.lang = 'en-US';
            
            recognition.onstart = () => {
                setLoaderVoice('on');
            };
    
            recognition.onend = () => {
                setLoaderVoice('off');
            };
    
            recognition.onresult = (event) => {
                const text = event.results[0][0].transcript.trim();
                recognition.stop();
                submitForm(null, text);
            };
            recognition.start();
        } else {
            alert('Voice search is not supported in this browser.');
        }
    };

    return (
        <>
            <Navbar expand="md" style={navbarStyle} className="bg-body-tertiary justify-content-center HeaderMenu">
                <Container>
                    <Navbar>
                        <Link to="/"><img alt="" src={httpCPage + '/backend/img/' + settings.logo} className="navbar-logo" /></Link>
                    </Navbar>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" className="navbar-toggler" onClick={() => setOffcanvasOpen(!offcanvasOpen)} />
                    <Navbar.Offcanvas id="offcanvasNavbar" show={offcanvasOpen} onHide={handleCloseOffcanvas} placement="end"
                        className="navbar-offcanvas" style={{ width: '80vw' }}>
                        <Offcanvas.Header closeButton className="navbar-offcanvas-header">
                            <Offcanvas.Title id="offcanvasNavbarLabel" className="navbar-offcanvas-title">
                                <Link to="/" onClick={handleCloseOffcanvas}><img alt="" src={httpCPage + '/backend/img/' + settings.logo} className="navbar-logo" /></Link>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="navbar-offcanvas-body CustomNavber">
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav onClick={handleCloseOffcanvas}>
                                    <Link to="/" className={`nav-link ${isActive('/')}`}>Home</Link>
                                </Nav>

                                <NavDropdown
                                    title={
                                        <span className={isActive('/about-us') || isActive('/mission-vission') ? 'ActiveMenu' : ''}>
                                            About us <IoMdArrowDropdown />
                                        </span>
                                    }
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => setShowDropdown(!showDropdown)}
                                    id="about-dropdown"
                                    className="mt-2"
                                    show={showDropdown}
                                >
                                    <NavDropdown.Item>
                                        <Link to="/about-us" className={`nav-link ${isActive('/about-us')}`}>About Us</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleCloseOffcanvas}>
                                        <Link to="/mission-vission" className={`nav-link ${isActive('/mission-vission')}`}>Mission & Vision</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleCloseOffcanvas}>
                                        <Link to="/publication-list" className={`nav-link ${isActive('/publication-list')}`}>Publications</Link>
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <Nav onClick={handleCloseOffcanvas}><Link to="/our-services" className={`nav-link ${isActive('/our-services')}`}>Services</Link></Nav>
                                <Nav onClick={handleCloseOffcanvas}><Link to="/case-study" className={`nav-link ${isActive('/case-study')}`}>Case Study</Link></Nav>
                                <Nav onClick={handleCloseOffcanvas}><Link to="/product-list" className={`nav-link ${isActive('/product-list')}`}>Products</Link></Nav>
                                <Nav onClick={handleCloseOffcanvas}><Link to="/our-factory" className={`nav-link ${isActive('/our-factory')}`}>Our Factory</Link></Nav>
                                <Nav onClick={handleCloseOffcanvas}><Link to="/testimonial" className={`nav-link ${isActive('/testimonial')}`}  onClick={handleCloseOffcanvas}>Testimonial</Link></Nav>
                                <NavDropdown
                                    title={<span className={isActive('/foot-health-information-hub') ? 'ActiveMenu' : ''}>FHIH <IoMdArrowDropdown /></span>}
                                    id="fhih-dropdown"
                                    className="mt-2"
                                    show={showDropdownFHIH}
                                    onMouseEnter={() => setShowDropdownFHIH(true)}
                                    onMouseLeave={() => setShowDropdownFHIH(false)}
                                    onClick={() => setShowDropdownFHIH(!showDropdown)}
                                >
                                    <NavDropdown.Item onClick={handleCloseOffcanvas}>
                                        <Link to="/foot-health-information-hub" className={`nav-link ${isActive('/foot-health-information-hub')}`}>Foot Health Information Hub </Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav onClick={handleCloseOffcanvas}><Link to="/contact-us" className={`nav-link ${isActive('/contact-us')}`}>Contact</Link></Nav>
                                <Nav className="">
                                    <div className="input-group mt-1 smallScreenSearch">
                                        <form onSubmit={submitForm}> {/* Wrap your input fields in a form tag */}
                                            <div className="input-group mt-1">
                                                <input type="text" className='form-control border searchBoxSmall' name="search" value={search} onChange={handleInputChange} placeholder="Search...." />
                                                <div className="input-group-append">
                                                    {loader === 'off' ? (
                                                        <button type="submit" name="submit-form" className="input-group-text border-squre"><IoIosSearch /></button>
                                                    ) : (
                                                        <div className="spinner-border text-dark ms-2" role="status"></div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-light mb-5">{errors.search}</div>
                                            <div className="text-center">
                                                {loaderVoice === 'off' ? (
                                                    <FaMicrophone className='voiceIcon' onClick={startVoiceRecognition} />
                                                ) : (
                                                    <span><MdOutlineRecordVoiceOver className='voiceIcon'/> <span className='voiceListening'>Listening...</span></span>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                    <Nav.Link onClick={handleSearchClick} className="nav-link ">
                                        <div className="bigScreenSearch">
                                            <div className="SearchBoxBig">
                                                <span className='SearchBoxText'>Search</span> <IoIosSearch className='SearchBoxIcon'/>
                                            </div>
                                        </div>

                                    </Nav.Link>
                                </Nav>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <Offcanvas className="BigScreenSearchOpen" show={searchOpen} onHide={() => setSearchOpen(false)} placement="bottom" style={{ width: '50%', bottom: 0 }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      <div className="searcTitle">
                        <h2>What are you looking for?
                      Search here...</h2>
                      </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <form onSubmit={submitForm}>
                    <div className="input-group mt-1">
                        <input
                            type="text"
                            className='form-control border searchBoxSmall'
                            name="search"
                            value={search}
                            onChange={handleInputChangeVoice}
                            placeholder="Search...."
                        />
                        <div className="input-group-append">
                            {loader === 'off' ? (
                                <button type="submit" name="submit-form" className="input-group-text border-squre"><IoIosSearch /></button>
                            ) : (
                                <div className="spinner-border text-dark" role="status"></div>
                            )}
                        </div>
                    </div>
                    <div className="text-light mb-5">{errors.search}</div>
                    <div className="text-center">
                        {loaderVoice === 'off' ? (
                            <FaMicrophone className='voiceIcon' onClick={startVoiceRecognition} />
                        ) : (
                            <span><MdOutlineRecordVoiceOver className='voiceIcon'/> <span className='voiceListening'>Listening...</span></span>
                        )}
                    </div>
                </form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;
