import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaComments, FaPaperPlane, FaUser } from 'react-icons/fa';
import { AiFillMessage } from "react-icons/ai";
import { Api } from '../api/Api';

const ChatBot = () => {
  const { http } = Api();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [userResponse, setUserResponse] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const chatHistoryRef = useRef(null);
  const [loader, setLoader] = useState('off');

  useEffect(() => {
    // Scroll to the bottom of the chat history when chatHistory changes
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = () => {
    
    if (message.trim() !== '') {
      const userMessage = { sender: 'user', message };
      setChatHistory(prevChatHistory => [...prevChatHistory, userMessage]);
      generateResponse(message);
      setMessage('');
    }
  };

  const generateResponse = (query) => {
    setLoader('on');
    const formData = { userResponse };
        http.post(`/get-bot-response`, formData)
            .then(res => {
                if (res.data.status === '422') {
                   
                } else {
                    // setLoader('off');
                  //  console.log(res.data.bot_response);
                  if(res.data.bot_response !== null){
                        const botMessage = {
                          sender: 'bot',
                          message: res.data.bot_response.answar
                        };
                        setLoader('off');
                        setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
                        return;
                    } else {
                        const botMessage = {
                          sender: 'bot',
                          message: "<p>Sorry, I couldn't find information on that topic. Please contact us by <a href='whatsapp://send?phone=+8801944003077'>WhatsApp</a></p>"
                        };
                        setLoader('off');
                        setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
                        return;
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
  };

  return (
    <div className="chatbot">
      {isOpen && (
        <div className="chatbox">
          <button className="close-btn" onClick={toggleChat}><FaTimes /></button>
          <div className="d-flex justify-content-center customHelpBox">
            <div className="SupportCenterImage">
              <img src="https://i.pinimg.com/736x/c1/f6/3a/c1f63a105cd09ac494907045aa30d4f7.jpg" alt="" width={70} />
            </div>
          </div>
          <div className="text-center"><p className='fw-bold'>Foot Balance Technology Customer Support</p></div>
          <div className="chat-history" ref={chatHistoryRef}>
            {chatHistory.map((chat, index) => (
              <div key={index} className={`row message ${chat.sender === 'user' ? 'user-message' : 'bot-message'}`}>
                {chat.sender !== 'user' ? <div className='col-2'><img className='botGirl' src="https://i.pinimg.com/736x/c1/f6/3a/c1f63a105cd09ac494907045aa30d4f7.jpg" alt="" width={35} /></div> : ''}
                  <div className="col-10"><span className='textMessage' dangerouslySetInnerHTML={{ __html: chat.message }} /> </div>
                {chat.sender === 'user' ? <div className='col-1'><FaUser /></div> : ''}
              </div>
              
            ))}
            
            {loader === 'on' ? 
              <div className="loadingBox">
                <p class="spinner-grow text-primary loadingBot" role="status"></p> 
                <p class="spinner-grow text-primary loadingBot" role="status"></p> 
                <p class="spinner-grow text-primary loadingBot" role="status"></p> 
                <p class="spinner-grow text-primary loadingBot" role="status"></p> 
              </div> : ''
            }

          </div>
          <div className="chat-input">
          <input
              className='form-control'
              type="text"
              placeholder="Type your message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setUserResponse(e.target.value);
              }}
              onKeyDown={handleKeyDown}
            />
            <button className="send-btn" onClick={sendMessage}><FaPaperPlane style={{ color: '#95df52', fontSize: '30px', background: '#fff' }} /></button>
          </div>
        </div>
      )}
      <button className="chat-btn" onClick={toggleChat}><AiFillMessage /></button>
    </div>
  );
};

export default ChatBot;
