import React, { useEffect, useState } from 'react';
import { Api } from '../api/Api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../api/ServerUrl';

const HomeLayoutDescription = () => {

    const { http } = Api();
    const { id } = useParams();
    const httpCPage = ServerUrl;
    const [layouts, setLayouts] = useState([]);
    const [singlelayouts, setSingleLayouts] = useState([]);

    useEffect(() => {
        scrollToTop();   
    }, []);

    const fetchFullDescription = () => {
        http.get(`home-full-description/${id}`)
            .then(res => {
                setSingleLayouts(prevLayout => ({
                    ...prevLayout,
                    description: res.data.description,
                }));
            })
            .catch(error => {
                console.error('Error fetching full description:', error);
            });
    };
    
    useEffect(() => {
        http.get(`front-get-single-home-layout/${id}`)
            .then(res => { 
                setSingleLayouts(res.data);
                document.title = `${res.data?.title} - Foot Balance Technology BD`;
                fetchFullDescription();
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, [id]);

    useEffect(() => {
        http.get('/get-all-pages-home-layouts')
            .then(res => { 
                setLayouts(res.data);
            })
            .catch(error => {
                console.error('Error fetching layouts:', error);
            });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

  return (
    <div>
        <div className="container CusTopmargin">
            <div className="row">
                <div className="col-md-9 col-xl-9">
                    
                    <div className="singleBox mt-5 text-center">
                        <h2>{singlelayouts.title}</h2>
                        <p className='mt-3'>{singlelayouts.subtitle !== 'null' ? singlelayouts.subtitle : ''}</p>
                        <img src={`${httpCPage}/backend/img/pages/home/layout/${singlelayouts.image}`} className='img-fluid' alt="" />
                       
                        <div className="text mb-3 mt-3 descriptionPcolor text-start pl-2 pr-3" dangerouslySetInnerHTML={{ __html: singlelayouts.description }}></div>
                    </div>
                </div>
                
                <div className="col-md-3 col-xl-3">
                    <div className="mt-5">
                        {layouts.slice(0, 5).map((layout, index) => (
                            <div key={index} className="news-block p-2">
                                <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="image descriptionImage">
                                        <Link to={`/layout/details/${layout.id}`} onClick={scrollToTop}><img src={`${httpCPage}/backend/img/pages/home/layout/${layout.image}`} height="100" alt="" /></Link>
                                    </div>
                                    <div className="lower-content">
                                        <h3><Link to={`/layout/details/${layout.id}`}>{layout.title}</Link></h3>
                                        <Link to={`/layout/details/${layout.id}`} className="read-more" onClick={scrollToTop}>Read more <span className="icon flaticon-right-arrow-1"></span></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
         
    </div>
  )
}

export default HomeLayoutDescription;
