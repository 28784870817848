import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import { useNavigate } from 'react-router-dom';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';

const TermsAdd = () => {
    const navigate = useNavigate();
    const editor = useRef(null);
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [termsName, setTermsName] = useState('');
    const [termsDescription, setTermsDescription] = useState('');
    const [termsNameError, setTermsNameError] = useState('');
    const [termsDescriptionError, setTermsDescriptionError] = useState('');

    // Handle input change for regular input fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'termsName') {
            setTermsName(value);
        }
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('termsName', termsName);
        formData.append('description', termsDescription);

        http.post(`/setting-add-terms`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setTermsNameError(errors.termsName ? errors.termsName[0] : '');
                setTermsDescriptionError(errors.description ? errors.description[0] : '');
            } else {
                setLoader('off');
                navigate('/admin/settings/terms-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Add New Terms</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <label>Terms Name</label>
                                                    <input type="text" id="termsName" name="termsName" value={termsName} className="form-control ms-0" placeholder='Terms name' onChange={handleInputChange} />
                                                    <span className='text-danger'>{termsNameError}</span>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <label>Terms Description</label>
                                                     <JoditEditor
                                                        ref={editor}
                                                        value={termsDescription}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setTermsDescription(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{termsDescriptionError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' &&
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button>
                                            }
                                            {loader === 'on' &&
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TermsAdd;
