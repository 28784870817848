import React from 'react';
import ReactPlayer from 'react-player';
import { MdClose } from "react-icons/md";

const CanvasModal = ({ onClose, videoUrl }) => {
  const qualities = [
    { label: '240p', file: `${videoUrl}_240p.mp4` },
    { label: '480p', file: `${videoUrl}_480p.mp4` },
    { label: '720p', file: `${videoUrl}_720p.mp4` },
    { label: '1080p', file: `${videoUrl}_1080p.mp4` }
  ];

  return (
    <div className="bluroverlay">
      <div className="modal-overlay">
        <div className="modal-content2">
          <button onClick={onClose} className="close-button">
            <MdClose />
          </button>
          <div className="video-container">
            <ReactPlayer
              url={videoUrl}
              controls={true}
              width="100%" // Adjusted width to fit the container
              height="100%" // Adjusted height to fit the container
              className="ReactVideoPlayer"
              playing={true}
              config={{
                file: {
                  qualities,
                },
                hls: {
                  enable: true,
                  minAutoBitrate: 240,
                  maxAutoBitrate: 1080,
                  smoothing: true
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasModal;
