import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import BackButton from '../../../../tools/BackButton';
import { ProtectAPi } from '../../../../../api/ProtectAPi';

const FooterCategory = () => {
    const navigate = useNavigate();
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [categoryName, setCategoryName] = useState('');
    const [categoryNameError, setCategoryNameError] = useState('');
    const [categoryLink, setCategoryLink] = useState(['']);
    const [categoryLinkTitles, setCategoryLinkTitles] = useState(['']);

    const handleNameChange = (e) => {
        setCategoryName(e.target.value);
        setCategoryNameError('');
    };

    const handleLinkChange = (index, e) => {
        const { value } = e.target;
        const newCategoryLinks = [...categoryLink];
        newCategoryLinks[index] = value;
        setCategoryLink(newCategoryLinks);
    };

    const handleTitleChange = (index, e) => {
        const { value } = e.target;
        const newCategoryLinkTitles = [...categoryLinkTitles];
        newCategoryLinkTitles[index] = value;
        setCategoryLinkTitles(newCategoryLinkTitles);
    };

    const addRow = () => {
        setCategoryLink([...categoryLink, '']);
        setCategoryLinkTitles([...categoryLinkTitles, '']);
    };

    const removeRow = (index) => {
        const newCategoryLinks = [...categoryLink];
        newCategoryLinks.splice(index, 1);
        setCategoryLink(newCategoryLinks);

        const newCategoryLinkTitles = [...categoryLinkTitles];
        newCategoryLinkTitles.splice(index, 1);
        setCategoryLinkTitles(newCategoryLinkTitles);
    };

    const submitForm = () => {
        if (!categoryName.trim()) {
            setCategoryNameError('Category Name is required');
            return;
        }

        setLoader('on');
        const formData = new FormData();
        categoryLink.forEach((link, index) => {
            formData.append(`categoryLinks[${index}]`, link);
        });

        categoryLinkTitles.forEach((title, index) => {
            formData.append(`categoryLinkTitles[${index}]`, title);
        });

        formData.append('categoryName', categoryName);

        http.post(`/setting-add-category`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setCategoryNameError(errors.categoryName ? errors.categoryName[0] : '');
            } else {
                setLoader('off');
                navigate('/admin/settings/footer-list');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
            setLoader('off');
            swal('Error', 'An error occurred while processing your request', 'error');
        });
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Add New Category</h5>
                                            <div className="">
                                                <BackButton />
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <input type="text" value={categoryName} className="form-control" placeholder='Category Name' onChange={handleNameChange} />
                                                    <span className='text-danger'>{categoryNameError}</span>
                                                </div>
                                            </div>
                                            {categoryLink.map((link, index) => (
                                                <div key={index} className="row mb-3">
                                                    <div className="col-md-6">
                                                        <input type="text" value={link} className="form-control" placeholder='Title' onChange={(e) => handleLinkChange(index, e)} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input type="text" value={categoryLinkTitles[index]} className="form-control" placeholder='Title Link' onChange={(e) => handleTitleChange(index, e)} />
                                                    </div>
                                                    <div className="col-md-2">
                                                        {index === categoryLink.length - 1 ? <button className="btn btn-primary" onClick={addRow}>+</button> : <button className="btn btn-danger" onClick={() => removeRow(index)}>-</button>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' &&
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Create</button>
                                            }
                                            {loader === 'on' &&
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default FooterCategory;
