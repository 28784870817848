import React, { useEffect, useState } from 'react';
import { Api } from '../../api/Api';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const TermsCondition = () => {

    useEffect(() => {
        document.title = `Foot Balance Technology BD - Terms And Conditions`;
      }, []);

    const { http } = Api();
    const { id } = useParams();
    const [caseCategory, setCaseCategory] = useState([]);
    const [singleLayouts, setSingleLayouts] = useState({});

    useEffect(() => {
        // Fetch case categories
        http.get('/get-terms-condition-list')
            .then(res => { 
                setCaseCategory(res.data);
                // If id is not provided in the URL, fetch the first category
                if (!id && res.data.length > 0) {
                    fetchSingleLayout(res.data[0].id);
                }
            })
            .catch(error => {
                console.error('Error fetching case categories:', error);
            });
    }, []);

    useEffect(() => {
        // Fetch single layout when id changes
        if (id) {
            fetchSingleLayout(id);
        }
    }, [id]);

    const fetchSingleLayout = (categoryId) => {
        http.get(`get-terms-condition-list-single/${categoryId}`)
            .then(res => { 
                setSingleLayouts(res.data);
            })
            .catch(error => {
                console.error('Error fetching single layout:', error);
            });
    }

    return (
        <div>
            <section className="page-title pageTitleBg">
                <div className="auto-container">
                    <h2>Terms & Conditions</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Terms & Conditions</li>
                        <li>{singleLayouts.category}</li>
                    </ul>
                </div>
            </section>

            <section>
                <div className="auto-container my-5">
                    <div className="row">
                        <div className="col-md-3">
                            {caseCategory.map((data) => (
                                <p key={data.id}><Link to={`/terms-and-conditions/${data.id}`} className={`nav-link border rounded p-2 ${data.id === singleLayouts.id ? 'activeCus' : ''}`}>{data.termsName}</Link></p>
                            ))}
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h2 className='bold-text text-uppercase'>{singleLayouts.termsName}</h2>
                                    </div>
                                    <div className="mt-3">
                                        <div className="text mb-3" dangerouslySetInnerHTML={{ __html: singleLayouts.description }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TermsCondition;