import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Api } from '../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../api/ServerUrl';
import FadeInOut from '../tools/FadeInOut';

const BlogShow = () => {
    const { http } = Api();
    const [blog, setBlog] = useState([]);
    const httpCPage = ServerUrl;

    useEffect(() => {
        http.get('/front-get-blog-list')
            .then(res => {
                setBlog(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    // Function to format time difference between two dates
    const formatTimeDifference = (dateString) => {
        const now = new Date();
        const pastDate = new Date(dateString);
        const timeDifference = now - pastDate;

        // Calculate time difference in seconds, minutes, hours, and days
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `a few seconds ago`;
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, // Auto play enabled
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

    return (
        <div>
            <section className="news-section">
                <div className="auto-container">
                    <FadeInOut>
                    <div className="text-center sec-title">
                        <h2 className='cusColorTitle'>Our Blogs and News</h2>
                    </div>
                    </FadeInOut>
                    <div className="row clearfix">
                    <FadeInOut>
                        <Slider {...settings}>
                            {blog.map((blog, index) => (
                                <div key={index} className="news-block p-2">
                                    <div className="inner-box wow fadeInRight  rounded" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <Link to={`/blog-list-details/${blog.id}`}>
                                    <div className="image">
                                            <img src={`${httpCPage}/backend/img/blog/${blog.image}`} alt="" />
                                        </div>
                                        <div className="lower-content">
                                            <ul className="post-meta">
                                                <li>{formatTimeDifference(blog.created_at)}</li>
                                            </ul>
                                            <h3>{truncateString(blog.title, 60)}</h3>
                                            <p className="read-more">Read more <span className="icon flaticon-right-arrow-1"></span></p>
                                        </div>
                                    </Link>
                                        
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        </FadeInOut>
                    </div>
                    <FadeInOut>
                    <div className="text-center mt-5">
                        <div className="btns-box mt-2">
                            <Link to="/blog-list" className="theme-btn btn-style-one"><span className="txt">Show more Blogs</span></Link>
                        </div>
                    </div> 
                    </FadeInOut>
                </div>
            </section>
        </div>
    );
}

export default BlogShow;
