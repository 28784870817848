import React, { useState, useEffect } from 'react';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const PublicationList = () => {
    const { http } = Api();
    const [blog, setBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 6;
    const httpCPage = ServerUrl;
    const [loading, setLoading] = useState(false);
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Publications List';
      }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = () => {
        setLoading(true);
        const page = currentPage; // Current page
        const limit = itemsPerPage; // Items per page
    
        http.get(`/front-get-publication-list-all?page=${page}&limit=${limit}`)
            .then(res => {
                setBlog(res.data.blogs || []);
                setTotalPages(Math.ceil(res.data.totalCount / limit));
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const formatTimeDifference = (dateString) => {
        const now = new Date();
        const pastDate = new Date(dateString);
        const timeDifference = now - pastDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `a few seconds ago`;
        }
    };

    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderPaginationButtons = () => {
        const visiblePages = [];
        const numVisibleButtons = 5;
        const halfVisibleButtons = Math.floor(numVisibleButtons / 2);
    
        if (totalPages <= numVisibleButtons) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }
        let startPage = Math.max(1, currentPage - halfVisibleButtons);
        let endPage = Math.min(totalPages, startPage + numVisibleButtons - 1);
        while (endPage - startPage + 1 < numVisibleButtons) {
            if (startPage > 1) {
                startPage--;
            } else if (endPage < totalPages) {
                endPage++;
            }
        }
        for (let i = startPage; i <= endPage; i++) {
            visiblePages.push(i);
        }
        return visiblePages;
    };

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Publication</h2>
                    <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                        <li>Publication List</li>
                    </ul>
                </div>
            </section>

            <section className='container'>
                <div className="auto-container">
                    <FadeInOut>
                    <div className="row">
                        {loading ? (
                            <div>Loading...</div>
                        ) : blog.length === 0 ? (
                            <div>No blogs available.</div>
                        ) : (
                            blog.map((blogItem, index) => (
                                <div key={index} className="col-md-4 news-block p-2">
                                    <div className="inner-box wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <Link to={`/publication-details/${blogItem.id}`}><img src={`${httpCPage}/backend/img/publications/${blogItem.image}`} alt="" /></Link>
                                        </div>
                                        <div className="lower-content">
                                            <ul className="post-meta">
                                                <li>{formatTimeDifference(blogItem.created_at)}</li>
                                            </ul>
                                            <h3><Link to={`/publication-details/${blogItem.id}`}> <div dangerouslySetInnerHTML={{ __html: truncateString(blogItem.title, 60) }}></div> </Link></h3>
                                            <Link to={`/publication-details/${blogItem.id}`} className="read-more">Read more <span className="icon flaticon-right-arrow-1"></span></Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    </FadeInOut>
                </div>
                <FadeInOut>
                <div className="d-flex justify-content-center mb-4">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="pegibutton NxtPrevButton me-1" onClick={prevPage}>Previous</button>
                            </li>
                            {renderPaginationButtons().map((page, index) => (
                                <li key={index} className={` page-item ${currentPage === page ? 'paginateButton' : ''}`}>
                                    <button className="pagiItem pegibutton" onClick={() => paginate(page)}>{page}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className="pegibutton NxtPrevButton ms-1" onClick={nextPage}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
                </FadeInOut>
            </section>
        </div>
    );
};

export default PublicationList