import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 
import { ProtectAPi } from '../../../../api/ProtectAPi';
import swal from 'sweetalert';
import { SetPermission } from '../../../../auth/SetPermission';

const ViewRole = () => {
    const { http } = ProtectAPi();
    const tableRef = useRef(null);
    const [roles, setRoles] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/get-all-roles')
        .then(res => { 
          setRoles(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    useEffect(() => {
      if (roles.length > 0 && !tableRef.current.DataTable) {
        $(tableRef.current).DataTable();
      }
    }, [roles]);


    // delete roles
    const handleDeleteRole = (roleId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this role!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-roles/${roleId}`)
                   .then(response => {
                       if(response.data.status != 201){
                        setRoles(prevRoles => prevRoles.filter(role => role.id !== roleId));
                       }
                        
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                   })
                   .catch(error => {
                        swal('Oops! Something went wrong while deleting the role.', {
                            icon: 'error',
                        });
                   });
                swal('Poof! The role has been deleted!', {
                    icon: 'success',
                });
            } else {
                swal('The role is safe!');
            }
        });
    };
  
    return (
      <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Role List</h5>
                            <div class="text-end">
                              {userPermission.includes('role_create') && (<Link to="/admin/roles-create" class="btn btn-sm btn-primary"><i class="fas fa-plus"></i> Create Role</Link>)}
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Role</th>
                              <th>Permision</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {roles.map((roles, index) => (
                              <tr key={roles.id}>
                                <td>{index + 1}</td>
                                <td>{roles.name}</td>
                                <td>
                                    <p style={{ width: '500px' }}>
                                    {roles.permissions.map((permission, idx) => (
                                        <span className='btn btn-sm btn-primary ml-1' key={idx}>{permission.name}</span>
                                    ))}
                                    </p>
                                </td>
                                <td>
                                {userPermission.includes('role_edit') && (<Link to={`/admin/roles-edit/${roles.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <i className="fas me-2 fa-edit text-white"></i>
                                      </button>
                                    </Link>
                                  )}
                                  {userPermission.includes('role_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(roles.id)}>
                                            <i className="fas me-2 fa-trash text-white"></i>
                                        </button>
                                    </Link>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
}

export default ViewRole
