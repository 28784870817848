import React, { useEffect, useState } from 'react';
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { useParams } from 'react-router-dom';
import BackButton from '../../../tools/BackButton'
import swal from 'sweetalert';

const EditRole = () => {
    const { id } = useParams();
    const { http } = ProtectAPi();
    const [loader, setLoader] = useState('off');
    const [permissionsByGroup, setPermissionsByGroup] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const allPermissions = Object.values(permissionsByGroup).flatMap(group => group.map(permission => permission.id));
    const [selectedAllGroupName, setSelectedAllGroupName] = useState([]);


    useEffect(() => {
      getAllPermission();
      getSinglePermission();
    }, []);

      function getAllPermission(){
        http.get('/get-all-permission')
        .then(res => { 
          const groupedPermissions = {};
          res.data.forEach(permission => {
            if (!groupedPermissions[permission.group_name]) {
              groupedPermissions[permission.group_name] = [];
            }
            groupedPermissions[permission.group_name].push(permission);
          });
          setPermissionsByGroup(groupedPermissions);
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
        });
      }

      function getSinglePermission(){
        http.get(`/get-single-roles/${id}`)
        .then(res => { 
          setRoleName(res.data.role.name);
          setSelectedPermissions(res.data.permission_ids);
          setSelectedAllGroupName(res.data.permission_groups);
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
        });
      }
    
      const handleCheckboxChange = (id, type, name) => {
        if (type === 'group') {
          const groupPermissions = permissionsByGroup[id].map(permission => permission.id);
          const isGroupChecked = selectedAllGroupName.includes(id);
          setPermissionName(permissionName => {
            if (isGroupChecked) {
              return permissionName.filter(permission => !groupPermissions.includes(permission));
            } else {
              return [...permissionName, ...permissionsByGroup[id].map(permission => permission.name)];
            }
          });
          setSelectedPermissions(selectedPermissions => {
            if (isGroupChecked) {
              return selectedPermissions.filter(permissionId => !groupPermissions.includes(permissionId));
            } else {
              return [...selectedPermissions, ...groupPermissions];
            }
          });
          setSelectedAllGroupName(selectedAllGroupName => {
            if (!isGroupChecked) {
              return [...selectedAllGroupName, id];
            } else {
              return selectedAllGroupName.filter(group => group !== id);
            }
          });
        } else if (type === 'permission') {
            const groupIds = Object.keys(permissionsByGroup).filter(groupId =>
                permissionsByGroup[groupId].some(permission => permission.id === id)
            );
            setPermissionName(permissionName => {
                if (selectedPermissions.includes(id)) {
                return permissionName.filter(permission => permission !== name);
                } else {
                return [...permissionName, name];
                }
            });
            setSelectedPermissions(selectedPermissions => {
                if (selectedPermissions.includes(id)) {
                return selectedPermissions.filter(permissionId => permissionId !== id);
                } else {
                return [...selectedPermissions, id];
                }
            });
            setSelectedAllGroupName(selectedAllGroupName => {
                const allGroupPermissions = permissionsByGroup[groupIds[0]].map(permission => permission.id);
                const isAllGroupSelected = allGroupPermissions.every(permissionId => selectedPermissions.includes(permissionId));
                if (isAllGroupSelected) {
                return selectedAllGroupName.filter(group => group !== groupIds[0]);
                } else {
                return [...selectedAllGroupName, groupIds[0]];
                }
            });
  
        } else if (type === 'all') {
          if (selectedPermissions.length === allPermissions.length) {
            setPermissionName([]);
            setSelectedPermissions([]);
            setSelectedAllGroupName([]);
          } else {
            const allPermissionNames = Object.values(permissionsByGroup).flatMap(group => group.map(permission => permission.name));
            setPermissionName(allPermissionNames);
            const allPermissions = Object.values(permissionsByGroup).flatMap(group => group.map(permission => permission.id));
            setSelectedPermissions(allPermissions);
            const allGroupNames = Object.keys(permissionsByGroup);
            setSelectedAllGroupName(allGroupNames);
          }
        }
      };
   
    //   form submit 
    const [roleName, setRoleName] = useState('');
    const [permissionName, setPermissionName] = useState([]);
    const [roleNameError, setRoleNameError] = useState('');
    const [permissionNameError, setPermissionNameError] = useState([]);
    const submitform = (history) => {
        setLoader('on');
        const formData = new FormData();
        formData.append('name', roleName);
        permissionName.forEach(permissionId => {
            formData.append('permissions[]', permissionId);
        });
        selectedPermissions.forEach(selectedPermissions => {
            formData.append('permissions_id[]', selectedPermissions);
        });

        http.post(`/update-roles/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).then(res => {
          if (res.data.status === '422') {
            setLoader('off');
            const errors = res.data.error;
            setRoleNameError(errors.name ? errors.name[0] : '');
            setPermissionNameError(errors.permission ? errors.permission[0] : '');
          } else {
            setLoader('off');
           
            swal('Success', res.data.msg, 'success');
          }
        }).catch(error => {
          console.error('Error:', error);
        });
      };



  return (
    <div>
        <div className="main-content">
            <section className="section">
            <div className="section-body">
                <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h5>Edit Role</h5>
                                <div className="">
                                    <BackButton/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="card-body pb-0 px-5">
                                    <div className="form-group">
                                        <label>Role Name</label>
                                        <input type="text" id="name" name="name" value={roleName} className="form-control ms-0" onChange={e=>setRoleName(e.target.value)} />
                                        <span className='text-danger'>{roleNameError}</span>
                                    </div>

                                    <div className=" mt-4 mb-4">
                                        <h6 className="fw-bold">Permissions</h6>
                                        <span className='text-danger'>{permissionNameError}</span>
                                    </div>
                                    <div className="allSelection">
                                    <input
                                        className="form-check-input"
                                        value="AllSelect"
                                        type="checkbox"
                                        id="allSelection"
                                        onChange={() => handleCheckboxChange(null, 'all')}
                                        checked={selectedPermissions.length === allPermissions.length}
                                    />
                                    <label className="form-check-label" htmlFor="allSelection">
                                        Select All
                                    </label>
                                    
                                    </div>
                                    <div className="row">
                                    {Object.keys(permissionsByGroup).map(groupName => (
                                        <div className="col-md-3" key={groupName}>
                                        <div className="group-container">
                                            <div className="my-4">
                                            <input
                                                className="form-check-input"
                                                value={groupName}
                                                type="checkbox"
                                                id={`groupName_${groupName}`}
                                                onChange={() => handleCheckboxChange(groupName, 'group')}
                                                checked={selectedAllGroupName.includes(groupName)}
                                            />
                                            <label className="form-check-label" htmlFor={`groupName_${groupName}`}>
                                                {groupName}
                                            </label>
                                            </div>
                                            {permissionsByGroup[groupName].map(permission => (
                                            <div key={permission.id} className="form-check">
                                                <input
                                                className="form-check-input mt-1"
                                                value={permission.name}
                                                type="checkbox"
                                                id={`permission_${permission.id}`}
                                                onChange={() => handleCheckboxChange(permission.id, 'permission', permission.name)}
                                                checked={selectedPermissions.includes(permission.id)}
                                                />
                                                <label className="form-check-label" htmlFor={`permission_${permission.id}`}>
                                                {permission.name}
                                                </label>
                                            </div>
                                            ))}
                                        </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <label className="col-form-label"></label>
                                {loader === 'off' &&
                                    <button type="submit" onClick={submitform} className="btn btn-primary">Update</button>
                                }
                                {loader === 'on' &&
                                    <div class="spinner-border text-dark" role="status"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
    </div>
  )
}

export default EditRole