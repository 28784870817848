import React, { useEffect, useState  } from 'react';
import { ProtectAPi } from '../../../api/ProtectAPi';
import {ServerUrl} from '../../../api/ServerUrl';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ShowUsers = () => {

    const { http } = ProtectAPi();
    const { id } = useParams();
    const httpCPage = ServerUrl;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [userRole, setUserRole] = useState('');

    const [country, setCountry] = useState('');
    const [number, setNumber] = useState('');
    const [about, setAbout] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [twitter, setTwitter] = useState('');
    const [bio, setBio] = useState('');

    useEffect(() => {
        
            try {
                http.get(`/get-user/${id}`)
                    .then(response => {
                        setName(response.data.users.name);
                        setEmail(response.data.users.email);
                        setCountry(response.data.users.country);
                        setNumber(response.data.users.phone);
                        setAbout(response.data.users.about);
                        setAddress(response.data.users.address);
                        setCompany(response.data.users.company);
                        setFacebook(response.data.users.facebook);
                        setInstagram(response.data.users.instagram);
                        setLinkedin(response.data.users.linkedin);
                        setTwitter(response.data.users.twitter);
                        setBio(response.data.users.bio);
                        setUserRole(response.data.userRole.name.toString()); 
                        const userImage = response.data.users.image;
                        console.log(userImage);
                        if (userImage) {
                          const userImageShow = httpCPage + '/backend/user_image/' + userImage;
                          setImagePreview(userImageShow.toString());
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user:', error);
                    });
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    , []);

  

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="padding-20">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="home-tab2" data-toggle="tab" href="#about" role="tab"
                          aria-selected="true">Profile</a>
                      </li>
                    </ul>
                    <div className="tab-content tab-bordered" id="myTab3Content">
                      <div className="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab2">
                        <div className="row">
                            <div className="col-md-12 text-center mt-3 mb-5">
                                <div className="d-flex justify-content-center">
                                    <div className="image-box border dottet p-2 rounded rounded-circle mb-5" style={{ width: '270px', height: '270px' }}>
                                        {imagePreview ? (
                                        <img src={imagePreview} className='img-fluid rounded-circle' alt="Preview" />
                                        ) : (
                                        <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='rounded-circle' style={{ width: '240px', height: '240px' }} alt="Default" />
                                        )}
                                       <h3 className="mt-3 mb-0">{name}</h3>
                                       <p className='p-0 m-0'>( {about} )</p>
                                       <p className='p-0 m-0'>At {company}</p>
                                       <div className="d-flex justify-content-center mt-2">
                                            <Link to={facebook} className='bg-primary p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-facebook"></i></Link>
                                            <Link to={linkedin} className='bg-primary p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-linkedin"></i></Link>
                                            <Link to={instagram} className='bg-danger p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-instagram"></i></Link>
                                            <Link to={twitter} className='bg-primary p-1 rounded-circle'><i class="px-2 text-primary fa fa-twitter"></i></Link>
                                       </div>
                                    </div>
                                </div>
                            </div>

                          <div className="col-md-12 mt-5">
                            <div className="row">
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Mobile</strong>
                                    <br/>
                                    <p className="text-muted">{number}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Email</strong>
                                    <br/>
                                    <p className="text-muted">{email}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Address</strong>
                                    <br/>
                                    <p className="text-muted">{address}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Country</strong>
                                    <br/>
                                    <p className="text-muted">{country}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Role</strong>
                                    <br/>
                                    <p className="text-muted">{userRole}</p>
                                </div>
                            </div>
                          </div>
            
                        </div>
                        <strong className='my-3'>Bio</strong>
                        <div dangerouslySetInnerHTML={{ __html: bio }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ShowUsers