import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import swal from 'sweetalert';
import { Api } from '../../api/Api';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';

const Testimoniul = () => {

    useEffect(() => {
        document.title = `Foot Balance Technology BD - Testimoniul`;
      }, []);

    const { http } = Api();
    const httpCPage = ServerUrl;
    const [testimonials, setTestimonials] = useState([]);
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [review, setReview] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [loader, setLoader] = useState('off');
    const [nameError, setNameError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [reviewError, setReviewError] = useState('');
    const [backImage, setBackImage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        http.get(`/front-get-testimoniul`)
            .then(res => { 
                if (res.data.reviews && res.data.reviews.length > 0) {
                    setTestimonials(res.data.reviews);
                    setBackImage(res.data.backImage);
                }
            })
            .catch(error => {
                console.error('Error fetching testimonial information:', error);
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Show 2 items by default
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 768, // Adjust based on your desired breakpoint
                settings: {
                    slidesToShow: 1, // Show 1 item on small screens
                }
            }
        ]
    };

    const submitForm = () => {
        setLoader('on');
        setNameError('');
        setTitleError('');
        setReviewError('');
        const formData = new FormData();
        formData.append('name', name);
        formData.append('title', title);
        formData.append('review', review);
        formData.append('image', image);

        http.post('/store-testimoniul-from-customer', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                setLoader('off');
                const errors = res.data.error;
                setNameError(errors.name ? errors.name[0] : '');
                setTitleError(errors.title ? errors.title[0] : '');
                setReviewError(errors.review ? errors.review[0] : '');
            } else {
                setLoader('off');
                setName('');
                setTitle('');
                setReview('');
                setImagePreview('');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
            <div className="auto-container">
                <h2>Testimonials</h2>
                <ul className="page-breadcrumb">
                <li><Link to="/">home</Link></li>
                    <li>Testimonials</li>
                </ul>
            </div>
        </section>
        
        <section className="testimonial-section">
            <div className="auto-container">
            <FadeInOut>
                <div className="sec-title-two centered">
                    <h2 className='cusColorTitle'>What our customers say</h2>
                    <div className="title-text">Thousands have benefited from Foot Balance Technology</div>
                </div>
                </FadeInOut>

                <FadeInOut>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-block p-2">
                            <div className="inner-box">
                                <div className="content">
                                    <div className="image-outer">
                                        <div className="image">
                                            <img src={`${httpCPage}/backend/img/testimoniul/${testimonial.image}`} alt="" />
                                        </div>
                                    </div>
                                    <h3>{testimonial.name}</h3>
                                    <div className="title">{testimonial.title}</div>
                                    <div className="text">{testimonial.review}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                </FadeInOut>
            </div>
        </section>

        <FadeInOut>
        <section className='container'>
            <div className="text-center mt-5">
                <a href="https://maps.app.goo.gl/H6m3g56n6HZBCY9F6" target="_blank" type="submit" className="theme-btn btn-style-four border" rel="noopener noreferrer"><span className="txt">Review us on Google</span></a>
            </div>
        </section>
        </FadeInOut>
            <section className="testimonial-form-section mb-5">
                <div className="auto-container">
                    {/* Sec Title */}
                    <FadeInOut>
                    <div className="sec-title mt-5">
                        <h2>Review Us</h2>
                        <div className="text">Do you have anything in your mind to let us know?</div>
                    </div>
                    </FadeInOut>
                    <FadeInOut>
                    <div className="row">
                        <div className="text-center my-3">
                            <label className='' htmlFor="userImage">
                                <div className="image-box border dottet p-2 rounded" style={{ width: '170px', height: '170px', overflow: 'hidden' }}>
                                    {imagePreview ? (
                                        <img src={imagePreview} className='img-fluid' alt="Preview" />
                                    ) : (
                                        <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='' style={{ width: '140px', height: '140px' }} alt="Default" />
                                    )}
                                </div>
<br />
                                <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='theme-btn btn-style-cus2 text-dark mt-1 '>Upload</span>
                            </label>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-2">
                                <input className='form-control' type="text" name="username" placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)} />
                                <span className="text-danger">{nameError}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-2">
                                <input className='form-control' type="text" name="title" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                <span className="text-danger">{titleError}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea className='form-control' name="review" placeholder='Review' id="review" cols="10" rows="5" value={review} onChange={(e) => setReview(e.target.value)}></textarea>
                                <span className="text-danger">{reviewError}</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <label className="col-form-label"></label>
                            {loader === 'off' ? (
                                <button type="submit" name="submit-form" onClick={submitForm} className="theme-btn btn-style-cus mt-4">Submit</button>
                            ) : (
                                <div className="spinner-border text-dark mt-4" role="status"></div>
                            )}
                        </div>
                    </div>
                    </FadeInOut>
                </div>
            </section>
        </div>
    )
}

export default Testimoniul;
