import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { ProtectAPi } from '../../../../api/ProtectAPi';
import { ServerUrl } from '../../../../api/ServerUrl';
import swal from 'sweetalert';
import BackButton from '../../../tools/BackButton';
import { GiCrossMark } from "react-icons/gi";
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net'; 

const ProductEdit = () => {
    const { http } = ProtectAPi();
    const editor = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState('off');
    const [productName, setProductName] = useState('');
    const [productStatus, setProductStatus] = useState('');
    const [productHomeShow, setProductHomeShow] = useState('');
    const [layoutDescription, setLayoutDescription] = useState('');
    const [layoutImages, setLayoutImages] = useState([]);
    const [oldImage, setOldImages] = useState([]);
    const [layoutImageErrors, setLayoutImageErrors] = useState([]);
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [categoryNames, setCategoryNames] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState('');

    const tableRef = useRef(null);
    const [tableData, setTableData] = useState([]);


    useEffect(() => {
        http.get('/get-all-product-category')
            .then(res => {
                const uniqueCategoryNames = new Set(res.data.map(data => data));
                setCategoryNames(Array.from(uniqueCategoryNames));
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    useEffect(() => {
        http.get(`/get-single-product/${id}`)
            .then(res => {
                setProductName(res.data.data.product_name);
                setLayoutDescription(res.data.data.description);
                setProductStatus(res.data.data.status);
                setProductHomeShow(res.data.data.home_show);
                setSelectedCategoryName(res.data.data.category_id);
                setOldImages(res.data.images)
                setTableData(res.data.review);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, []);

    const submitForm = () => {
        setLoader('on');
        const formData = new FormData();
        formData.append('product_name', productName);
        formData.append('description', layoutDescription);
        layoutImages.forEach(image => formData.append('images[]', image.file));
        formData.append('category_id', selectedCategoryName);
        formData.append('status', productStatus);
        formData.append('home_show', productHomeShow);

        http.post(`/update-product/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.data.status === '422') {
                const errors = res.data.error;
                setLoader('off');
                setTitleError(errors.product_name ? errors.product_name[0] : '');
                setDescriptionError(errors.description ? errors.description[0] : '');
                setLayoutImageErrors(errors.images ? errors.images : []);
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
        }).catch(error => {
            console.error('Error:', error);
        });
    };

    const handleImageChange = (e) => {
        const selectedImages = e.target.files;
        const imagesArray = Array.from(selectedImages).map(file => ({
            file: file,
            preview: URL.createObjectURL(file)
        }));
        setLayoutImages(imagesArray);
        setLayoutImageErrors([]);
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...layoutImages];
        updatedImages.splice(index, 1);
        setLayoutImages(updatedImages);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'productName') {
            setProductName(value);
        }
    };

    const handleSelectChange = (e) => {
        setSelectedCategoryName(e.target.value);
    };

    const handleImageDelete = (id) => {
      swal({
          title: 'Are you sure?',
          text: 'Once deleted, you will not be able to recover this Image!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
      }).then((willDelete) => {
          if (willDelete) {
              http.delete(`/delete-product-review-single/${id}`)
                .then(response => {
                    if(response.data.status != 201){
                      setOldImages(prevRoles => prevRoles.filter(data => data.id !== id));
                    }
                      
                      swal(response.data.msg, {
                          icon: response.data.alert,
                      });
                })
                .catch(error => {
                      swal('Oops! Something went wrong while deleting the image.', {
                          icon: 'error',
                      });
                });
              swal('Poof! The Image has been deleted!', {
                  icon: 'success',
              });
          } else {
              swal('The Image is safe!');
          }
      });
    };

    const placeholder = 'Start typing...'; // placeholder variable was missing
    const editorConfig = useMemo(
        () => ({
            readonly: false,
            placeholder: placeholder || 'Start typing...',
            uploader: {
                insertImageAsBase64URI: true // Fixed the syntax error
            }
        }),
        [placeholder]
    );


    useEffect(() => {
        if (tableData.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        } else {
            if ($.fn.DataTable.isDataTable('#table-1')) {
                $(tableRef.current).DataTable().destroy();
            }
        }
    }, [tableData]);

    const handleDeleteRole = (roleId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this item!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-product-review-single/${roleId}`)
                   .then(response => {
                       if(response.data.status !== 201){
                        setTableData(prevRoles => prevRoles.filter(data => data.id !== roleId));
                       }
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                   })
                   .catch(error => {
                        swal('Oops! Something went wrong while deleting this item.', {
                            icon: 'error',
                        });
                   });
                swal('Poof! This item has been deleted!', {
                    icon: 'success',
                });
            } else {
                swal('This item is safe!');
            }
        });
    };

    return (
        <div>
            <div className="main-content">
                <section className="section">
                    <div className="section-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5>Edit Product</h5>
                                            <div className="">
                                                <BackButton/>
                                            </div>
                                        </div>
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <label>Product Images</label>
                                                </div>
                                                {oldImage.map((oldimage, index) => (
                                                    <div key={index} className="col-md-2 mt-4">
                                                        <div className="image-box">
                                                            <img src={`${ServerUrl}/backend/img/product/${oldimage.image}`} className='img-thumbnail img-fluid' alt={`Preview ${index}`} />
                                                            <button onClick={() => handleImageDelete(oldimage.id)} className="mt-1 rounded"><GiCrossMark className='text-danger'/></button>
                                                        </div>
                                                    </div>
                                                ))}
                                                {layoutImages.map((image, index) => (
                                                    <div key={index} className="col-md-2 mt-4">
                                                        <div className="image-box">
                                                            <img src={image.preview} className='img-thumbnail img-fluid' alt={`Preview ${index}`} />
                                                            <button onClick={() => handleImageRemove(index)} className="btn-close remove-image-button"></button>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col-md-12 mt-4">
                                                    <label className='' htmlFor="layoutImages">
                                                        <input type="file" id="layoutImages" className="form-control d-none" onChange={handleImageChange} multiple />
                                                        <span className='btn btn-primary mt-1 w-100'>Upload Product Images</span>
                                                        {layoutImageErrors.map((error, index) => (
                                                            <span key={index} className='text-danger'>{error}</span>
                                                        ))}
                                                    </label>
                                                </div>
                                                <div className="col-md-6 mt-4">
                                                    <div className="form-group">
                                                        <label>Select Category</label>
                                                        <select
                                                            value={selectedCategoryName}
                                                            onChange={handleSelectChange}
                                                            className="form-control"
                                                        >
                                                            <option value="" disabled>Select a category</option>
                                                            {categoryNames.map((categoryName, index) => (
                                                                <option key={index} value={categoryName.id}>
                                                                    {categoryName.name} ( {categoryName.group_name} )
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Status</label>
                                                            <select
                                                            className="form-control selectric rounded"
                                                            value={productStatus}
                                                            onChange={e => setProductStatus(e.target.value)}
                                                            >
                                                            <option value="">Select</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">De-Active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Home Show</label>
                                                            <select
                                                            className="form-control selectric rounded"
                                                            value={productHomeShow}
                                                            onChange={e => setProductHomeShow(e.target.value)}
                                                            >
                                                            <option value="">Select</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">De-Active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <div className="form-group mt-4">
                                                        <label>Product Name</label>
                                                        <input type="text" name="productName" value={productName} className="form-control ms-0" placeholder='Product Name' onChange={handleInputChange} />
                                                        <span className='text-danger'>{titleError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <label>Description</label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={layoutDescription}
                                                        config={editorConfig}
                                                        tabIndex={1}
                                                        onBlur={newContent => setLayoutDescription(newContent)}
                                                        onChange={newContent => {}}
                                                    />
                                                    <span className='text-danger'>{descriptionError}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="text-center mt-5">
                                            <label className="col-form-label"></label>
                                            {loader === 'off' ?
                                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button> :
                                                <div className="spinner-border text-dark" role="status"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="d-flex justify-content-between mb-3">
                                            <h5>Review List</h5>
                                        </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped" id="table-1" ref={tableRef}>
                                        <thead>
                                            <tr>
                                            <th className="text-center">#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Review</th>
                                            <th>Ratings</th>
                                            <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, index) => (
                                            <tr key={data.id}>
                                                <td>{index + 1}</td>
                                                <td>{data.name}</td>
                                                <td>{data.email}</td>
                                                <td>{data.comments}</td>
                                                <td>{data.rating}</td>
                                                <td>
                                                <Link to='' className="editbtn">
                                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(data.id)}>
                                                            <i className="fas me-2 fa-trash text-white"></i>
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>



                    </div>
                </section>
            </div>
        </div>
    )
}

export default ProductEdit;