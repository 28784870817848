import React from 'react';

const BackButton = () => {
  const handleBack = () => {
    window.history.back(); // Navigate back using window.history
  };

  return (
    <button onClick={handleBack} style={{ background: 'none', border: 'none' }}>
      <i className="material-icons text-white rounded-circle bg-primary p-1">keyboard_backspace</i>
    </button>
  );
};

export default BackButton;
