import React, { useEffect, useState } from 'react';
import { Api } from '../api/Api';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa6";
import { ImYoutube } from "react-icons/im";
const Footer = () => {
    const { http } = Api();
    const [settings, setSettings] = useState({
        siteName: '',
        number: '',
        email: '',
        address: '',
        map_link: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        skype: '',
        shortDescription: '',
        logo: '',
        fab_logo: '',
        copyrightBy: '',
    });
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        http.get('/front-get-setting')
        .then(res => {
            const settingData = res.data[0];
            setSettings(settingData);
        })
        .catch(error => {
            console.error('Error fetching settings:', error);
        });
    }, []);

    useEffect(() => {
        http.get('/footer-get-category')
            .then(res => {
                if (typeof res.data === 'object') {
                    const flattenedCategoryList = Object.values(res.data).flat();
                    setCategoryList(flattenedCategoryList);
                } else {
                    console.error('Category data is not an object:', res.data);
                }
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);
        
    const addProtocol = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            return 'https://' + url;
        }
        return url;
    };

    return (
        <div>
            <footer className="main-footer">
                <div className="auto-container">
                    <div className="widgets-section">
                        <div className="row clearfix">
                            <div className="big-column col-lg-6 col-md-12 col-sm-12">
                                <div className="row clearfix">
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget logo-widget">
                                            <h2>{settings.siteName}</h2>
                                            <div className="text">{settings.shortDescription}</div>
                                            <ul className="social-icons">
                                                <li>
                                                    <Link to={settings.facebook !== null ? addProtocol(settings.facebook) : ''} target="_blank" rel="noopener noreferrer">
                                                        <FaFacebookF className='mb-1 social-icons-list' />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={settings.linkedin !== null ? addProtocol(settings.linkedin) : ''} target="_blank" rel="noopener noreferrer">
                                                        <FaLinkedinIn className='mb-1 social-icons-list' />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`tel:${settings.twitter}`} target="_blank" rel="noopener noreferrer">
                                                        <RiWhatsappFill className='mb-1 social-icons-list' />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={settings.instagram !== null ? addProtocol(settings.instagram) : ''} target="_blank" rel="noopener noreferrer">
                                                         <FaInstagram className='mb-1 social-icons-list' />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={settings.skype !== null ? addProtocol(settings.skype) : ''} target="_blank" rel="noopener noreferrer">
                                                        <ImYoutube className='mb-1 social-icons-list' />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget contact-widget">
                                            <h2>Contact Info</h2>
                                            <div className="widget-content">
                                                <ul>
                                                    <li>Number :<Link to={`tel:${settings.number}`}> {settings.number}</Link></li>
                                                    <li>Email :<Link to={`mailto:${settings.email}`}> {settings.email}</Link></li>
                                                    <li>{settings.address}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {categoryList.map((category, index) => (
                                (index === 0 || category.categoryName !== categoryList[index - 1].categoryName) && (
                                    <div key={index} className="big-column col-lg-3 col-md-12 col-sm-12">
                                        <div className="row clearfix">
                                            <div key={index} className="footer-column">
                                                <div className="footer-widget links-widget">
                                                    <div>
                                                        <h2>{category.categoryName}</h2>
                                                        <div className="widget-content">
                                                            <ul className="list">
                                                                {categoryList.filter(item => item.categoryName === category.categoryName).map((item, idx) =>
                                                                (
                                                                <li key={idx}>
                                                                    <Link to={item.categoryLinkTitle}>{item.categoryLink}</Link>
                                                                </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                ))}
                                

                            <div className="big-column col-lg-3 col-md-12 col-sm-12">
                                <div className="row clearfix">
                                    <div className="footer-column">
                                        <div className="footer-widget newsletter-widget">
                                            <h2>Newsletter</h2>
                                            <div className="text">Get Special Offers and Discounts!</div>
                                            <div className="newsletter-form">
                                                <div>
                                                    <div className="form-group">
                                                        <input type="email" name="email" placeholder="Enter your email address" required />
                                                        <button type="submit" className="theme-btn btn-style-one"><span className="txt">Subscribe</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="footer-bottom clearfix">
                        <div className="pull-left">
                            <div className="copyright"><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
                        </div>
                        <div className="pull-right">
                            <span>Design and Developed By </span><Link to="http://ipsitasoft.com/" target='_blank'>{settings.copyrightBy}</Link>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
