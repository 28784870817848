import React, { useEffect, useState, useRef } from 'react';
import { Api } from '../../api/Api';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import FadeInOut from '../../tools/FadeInOut';
import { ServerUrl } from '../../api/ServerUrl';
import ReCAPTCHA from 'react-google-recaptcha';

const Appoentment = () => {
    useEffect(() => {
        document.title = 'Foot Balance Technology BD - Appoinment';
      }, []);
    const { http } = Api();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const generateRandomToken = () => {
        return Math.floor(10000 + Math.random() * 90000).toString();
    };
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        number:'',
    });

    const [loader, setLoader] = useState('off');
    const [errors, setErrors] = useState({});
    const [backImage, setBackImage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaRef = useRef();

    useEffect(() => {
        http.get(`/front-get-banner-image/${'Appoinment'}`)
        .then(res => {
            setBackImage(res.data.backImage);
        })
        .catch(error => {
            console.error('Error fetching blogs:', error);
        })
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            swal('Error', 'Please reload and complete the reCAPTCHA.', 'error');
            return;
        }
        setLoader('on');
        setErrors({});
        try {
            http.post('/verify-recaptcha', { token: recaptchaToken })
            .then(res => {
                 if(res.data.success){
                    http.post('/appointment-store', formData)
                        .then(res => {
                            if (res.data.status === '422') {
                                setLoader('off');
                                setErrors(res.data.error);
                                setFormData({
                                    name: formData.name,
                                    location:formData.location,
                                    number: formData.number,
                                });
                            } else if(res.data.status === '425'){
                                setLoader('off');
                                setFormData({
                                    name: formData.name,
                                    location:formData.location,
                                    number: formData.number,
                                });
                            }else {
                                setLoader('off');
                                setFormData({
                                    name: '',
                                    location:'',
                                    number: '',
                                });
                                swal('Success', res.data.msg, 'success');
                                setRecaptchaToken(null);
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            setLoader('off');
                            setRecaptchaToken(null);
                        });
                 }else{
                    setLoader('off');
                    swal('Error', 'Failed to upload data.', 'error');
                    return;
                 }
            })
            .catch(error => {
                setLoader('off');
                swal('Error', 'reCAPTCHA verification failed. Please try again.', 'error');
                return;
            });
           
        } catch (error) {
            console.error('Error verifying reCAPTCHA:', error);
            setLoader('off');
            swal('Error', 'An error occurred while verifying reCAPTCHA. Please try again.', 'error');
        }
    };

    const [isOpen, setIsOpen] = useState([false, false, false, false]);

  const toggleAnswer = (index) => {
    setIsOpen(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
    

    return (
        <div>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Book Appoinment</h2>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Book Appoinment</li>
                    </ul>
                </div>
            </section>

            <section className="contact-form-section">
                <div className="auto-container">
                    {/* Sec Title */}
                    <FadeInOut>
                    <div className="sec-title">
                        <h2>Request For Appoinment </h2>
                        <div className="text">To fix an appoinment, please fill up the form below</div>
                    </div>
                    </FadeInOut>
                    <FadeInOut>
                    <div className="row clearfix">
                        {/* Form Column */}
                        <div className="form-column col-lg-7 col-md-7 col-sm-12">
                            <div className="inner-column">
                                <div className="contact-form">
                                    <form onSubmit={submitForm}>
                                        <div className="row clearfix">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" />
                                                <span className="text-danger">{errors.name}</span>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <input type="text" name="location" value={formData.location} onChange={handleChange} placeholder="Your Location" />
                                                <span className="text-danger">{errors.location}</span>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <input type="text" name="number" value={formData.number} onChange={handleChange} placeholder="Your Phone Number" />
                                                <span className="text-danger">{errors.number}</span>
                                            </div>
                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                <ReCAPTCHA
                                                    sitekey="6LdLOwoqAAAAAPtD1b5H7wWOeiYpy9Qm88I7IuA-"
                                                    onChange={(token) => setRecaptchaToken(token)}
                                                    ref={recaptchaRef}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <label className="col-form-label"></label>
                                                {loader === 'off' ? (
                                                    <button type="submit" name="submit-form" className="theme-btn btn-style-cus mt-4">Submit</button>
                                                ) : (
                                                    <div className="spinner-border text-dark mt-4" role="status"></div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="info-column col-lg-5 col-md-5 col-sm-12">
                            <div className="inner-column">
                            {/* Contact Info List */}
                                <ul className="contact-info-list">
                                    <li><strong>Appoinment Confirmation :</strong><br />You will be notified via call or SMS once the appointment is confirmed and scheduled.</li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                    </FadeInOut>
                </div>
            </section>
        </div>
    );
};

export default Appoentment;
