import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ServerUrl } from '../../api/ServerUrl';
import FadeInOut from '../../tools/FadeInOut';
import { Api } from '../../api/Api';

const GlobalSearch = () => {
    useEffect(() => {
        document.title = `Foot Balance Technology BD - Search Results`;
      }, []);

      const { http } = Api();
      const httpCPage = ServerUrl;
      const [searchData, setSearchData] = useState(null);
      const [searchKey, setSearchKey] = useState(null);
      const [backImage, setBackImage] = useState('');

    useEffect(() => {
        const storedData = sessionStorage.getItem('searchData');
        const searchKeyword = sessionStorage.getItem('searchKeyword');
        setSearchKey(searchKeyword);
        if (storedData) {
            setSearchData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        http.get(`/get-search-back-image`)
            .then(res => {
                setBackImage(res.data.backImage);
            })
            .catch(error => {
                console.error('Error fetching layout:', error);
            });
    }, []);

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }

    return (
        <div className='cusmaincolor'>
            <section className="page-title pageTitleBg" style={{backgroundImage: `url(${ServerUrl}/backend/img/back_image/${backImage.image})`}}>
                <div className="auto-container">
                    <h2>Search Results for {searchKey}</h2>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Search</li>
                    </ul>
                </div>
            </section>

            <section className="container">
                <div className="auto-container py-5">
                    <FadeInOut>
                    <h2 className='mb-3'>Search Results for {searchKey}</h2>
                    </FadeInOut>
                    {searchData !== null ? (
                        <div className="search-results">
                            {searchData['homeLayout'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/layout/details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['aboutLayout'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/about/details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['blog'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/blog-list-details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['service'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/our-services/details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['workProcess'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link >{item.title}</Link></strong>
                                    <p className="text mb-3">{item.description}</p>
                                </li>
                            ))}
                            {searchData['fhih'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/foot-health-information-hub-details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['caseStudy'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/case-study/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                            {searchData['rd'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/our-factory/research-development-details/${item.id}`}>{item.title}</Link></strong>
                                    <p className="text mb-3" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                </li>
                            ))}
                             {searchData['missionVission'].map((item, itemIndex) => (
                                <li key={itemIndex}>
                                    <strong><Link to={`/mission-vission`}>
                                        <p className="text mb-3 cusColorTitle" dangerouslySetInnerHTML={{ __html: truncateString(item.mission, 100) }}></p>
                                        </Link></strong>
                                    <strong><Link to={`/mission-vission`}>
                                        <p className="text mb-3 cusColorTitle" dangerouslySetInnerHTML={{ __html: truncateString(item.vission, 100) }}></p>
                                        </Link></strong>
                                    
                                </li>
                            ))}
                             {searchData['products'].map((item, itemIndex) => (
                                <li className='mt-3' key={itemIndex}>
                                    <strong><Link to={`/product-details/${item.id}`}>
                                        {item.product_name}
                                        </Link></strong>
                                        <p className="text mb-3 cusColorTitle" dangerouslySetInnerHTML={{ __html: truncateString(item.description, 100) }}></p>
                                        <Link to={`/product-details/${item.id}`}><img src={`${httpCPage}/backend/img/product/${item.images[0].image}`} alt="" className='border' width={100} /></Link>
                                </li>
                            ))}
                        </div>
                    ) : (
                        <p>No Data Found</p>
                    )}
                </div>
            </section>
        </div>
    );
}

export default GlobalSearch;
