import React, { useEffect, useState  } from 'react';
import { ProtectAPi } from '../../../api/ProtectAPi';
import {ServerUrl} from '../../../api/ServerUrl';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UserProfile = () => {

    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [status, setStatus] = useState('');
    const [userRole, setUserRole] = useState('');
    const [roles, setRoles] = useState('');
    const [loader, setLoader] = useState('off');

    const [country, setCountry] = useState('');
    const [number, setNumber] = useState('');
    const [about, setAbout] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [twitter, setTwitter] = useState('');
    const [bio, setBio] = useState('');
    const [id, setId] = useState('');


    useEffect(() => {
        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString);
            try {
                http.get(`/get-user/${user.id}`)
                    .then(response => {
                        setId(response.data.users.id);
                        setName(response.data.users.name);
                        setEmail(response.data.users.email);
                        setCountry(response.data.users.country);
                        setNumber(response.data.users.phone);
                        setAbout(response.data.users.about);
                        setAddress(response.data.users.address);
                        setCompany(response.data.users.company);
                        setFacebook(response.data.users.facebook);
                        setInstagram(response.data.users.instagram);
                        setLinkedin(response.data.users.linkedin);
                        setTwitter(response.data.users.twitter);
                        setBio(response.data.users.bio);
                        setStatus(response.data.users.status.toString()); 
                        setUserRole(response.data.userRole.name.toString()); 
                        setRoles(response.data.userRole.name.toString()); 
                        const userImage = response.data.users.image;
                        console.log(userImage);
                        if (userImage) {
                          const userImageShow = httpCPage + '/backend/user_image/' + userImage;
                          setImagePreview(userImageShow.toString());
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user:', error);
                    });
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    }, []);

  

    //   Image preview
    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        const previewURL = URL.createObjectURL(selectedImage);
        setImagePreview(previewURL);
    };

    const submitForm = (history) => {
        setLoader('on');
        setNameError('');
        setEmailError('');
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('image', image);
        formData.append('status', status);
        formData.append('roles', roles);
        formData.append('about', about);
        formData.append('phone', number);
        formData.append('country', country);
        formData.append('company', company);
        formData.append('address', address);
        formData.append('facebook', facebook);
        formData.append('instagram', instagram);
        formData.append('linkedin', linkedin);
        formData.append('bio', bio);
    
        http.post(`/update-user/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            if (res.data.status === '422') {
              const errors = res.data.error;
              setNameError(errors.name ? errors.name[0] : '');
              setEmailError(errors.email ? errors.email[0] : '');
              setLoader('off');
            } else {
                setLoader('off');
                swal('Success', 'User updated successfully!', 'success');
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

      const handleChange = (html) => {
        setBio(html);
      };


      // User Password
      const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
      };
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
    
      const togglePasswordConfirmationVisibility = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
      };

      const [currentPassword, setCurrentPassword] = useState('');
      const [showCurrentPassword, setShowCurrentPassword] = useState(false);

      const [password, setPassword] = useState('');
      const [showPassword, setShowPassword] = useState(false);

      const [password_confirmation, setPassword_confirmation] = useState('');
      const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
      const [password_confirmationError, setPassword_confirmationError] = useState('');

      const submitPasswordForm = (history) => {
        setLoader('on');
        setPassword_confirmationError('');
        const formData = new FormData();
        formData.append('current_password', currentPassword);
        formData.append('new_password', password);
        formData.append('confirm_password', password_confirmation);
        
    
        http.post(`/change-user-password/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(res => {
            if (res.data.status === '425') {
              setPassword_confirmationError(res.data.error);
              setLoader('off');
            } else {
                setLoader('off');
                swal('Success', res.data.msg, 'success');
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

  return (
    <div>
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="card">
                  <div className="padding-20">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="home-tab2" data-toggle="tab" href="#about" role="tab"
                          aria-selected="true">Profile</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="profile-tab2" data-toggle="tab" href="#settings" role="tab"
                          aria-selected="false">Edit Profile</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="change-tab3" data-toggle="tab" href="#ChangePassword" role="tab"
                          aria-selected="false">Change Password</a>
                      </li>
                    </ul>
                    <div className="tab-content tab-bordered" id="myTab3Content">
                      <div className="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab2">
                        <div className="row">
                            <div className="col-md-12 text-center mt-3 mb-5">
                                <div className="d-flex justify-content-center">
                                    <div className="image-box border dottet p-2 rounded rounded-circle mb-5" style={{ width: '270px', height: '270px' }}>
                                        {imagePreview ? (
                                        <img src={imagePreview} className='img-fluid rounded-circle' alt="Preview" />
                                        ) : (
                                        <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='rounded-circle' style={{ width: '240px', height: '240px' }} alt="Default" />
                                        )}
                                       <h3 className="mt-3 mb-0">{name}</h3>
                                       <p className='p-0 m-0'>( {about} )</p>
                                       <p className='p-0 m-0'>At {company}</p>
                                       <div className="d-flex justify-content-center mt-2">
                                            <Link to={facebook} className='bg-primary p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-facebook"></i></Link>
                                            <Link to={linkedin} className='bg-primary p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-linkedin"></i></Link>
                                            <Link to={instagram} className='bg-danger p-1 rounded-circle mr-1'><i class="px-2 text-primary fa fa-instagram"></i></Link>
                                            <Link to={twitter} className='bg-primary p-1 rounded-circle'><i class="px-2 text-primary fa fa-twitter"></i></Link>
                                       </div>
                                    </div>
                                </div>
                            </div>

                          <div className="col-md-12 mt-5">
                            <div className="row">
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Mobile</strong>
                                    <br/>
                                    <p className="text-muted">{number}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Email</strong>
                                    <br/>
                                    <p className="text-muted">{email}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Address</strong>
                                    <br/>
                                    <p className="text-muted">{address}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Country</strong>
                                    <br/>
                                    <p className="text-muted">{country}</p>
                                </div>
                                <div className="col-md-3 col-6 b-r">
                                    <strong>Role</strong>
                                    <br/>
                                    <p className="text-muted">{userRole}</p>
                                </div>
                            </div>
                          </div>
            
                        </div>
                        <strong className='my-3'>Bio</strong>
                        <div dangerouslySetInnerHTML={{ __html: bio }} />
                      </div>
                      <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="profile-tab2">
                        <div className="card-header">
                            <h4>Edit Profile</h4>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-3 text-center mt-3">
                            <label className='' htmlFor="userImage">
                              <div className="image-box border dottet p-2 rounded" style={{ width: '270px', height: '270px' }}>
                                  {imagePreview ? (
                                  <img src={imagePreview} className='img-fluid' alt="Preview" />
                                  ) : (
                                  <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png" className='' style={{ width: '240px', height: '240px' }} alt="Default" />
                                  )}
                              </div>
                            
                                <input type="file" id="userImage" className="form-control d-none" onChange={handleImageChange} /> <span className='btn btn-primary mt-1 w-100'>Upload Image</span>
                            </label>
                        </div>

                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Name</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        />
                                        <span className='text-danger'>{nameError}</span>
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Email</label>
                                        <input
                                        type="email"
                                        className="form-control"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        />
                                        <span className='text-danger'>{emailError}</span>
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">About</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={about}
                                        onChange={e => setAbout(e.target.value)}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Company</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={company}
                                        onChange={e => setCompany(e.target.value)}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Address</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={address}
                                        onChange={e => setAddress(e.target.value)}
                                        />
                                    </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="form-group row mb-4 p-1">
                                        <label className="col-form-label">Country</label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        value={country}
                                        onChange={e => setCountry(e.target.value)}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 px-4">
                                <div className="row">
                                    <div className="col-md-4 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Phone Number</label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            value={number}
                                            onChange={e => setNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Facebook</label>
                                            <input
                                            type="url"
                                            className="form-control"
                                            value={facebook}
                                            onChange={e => setFacebook(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Twitter</label>
                                            <input
                                            type="url"
                                            className="form-control"
                                            value={twitter}
                                            onChange={e => setTwitter(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Instagram</label>
                                            <input
                                            type="url"
                                            className="form-control"
                                            value={instagram}
                                            onChange={e => setInstagram(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">LinkedIn</label>
                                            <input
                                            type="url"
                                            className="form-control"
                                            value={linkedin}
                                            onChange={e => setLinkedin(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 ">
                                        <div className="form-group row mb-4 p-1">
                                            <label className="col-form-label">Bio</label>
                                            <ReactQuill
                                                theme="snow"
                                                value={bio}
                                                onChange={handleChange}
                                                style={{ width: '100%' }}
                                                />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="text-center mt-5">
                            <label className="col-form-label"></label>
                            {loader === 'off' &&
                                <button type="submit" onClick={submitForm} className="btn btn-primary">Update</button>
                            }
                            {loader === 'on' &&
                                    <div class="spinner-border text-dark" role="status"></div>
                            }
                            
                        </div>
                      </div>
                      <div className="tab-pane fade show px-4" id="ChangePassword" role="tabpane" aria-labelledby="change-tab3">
                        <div className="card-header">
                            <h4>Chnage Your Password</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                                <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">Current Password</label>
                                <div className="input-group">
                                    <input
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    data-indicator="pwindicator"
                                    className="form-control"
                                    value={currentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={toggleCurrentPasswordVisibility}
                                    >
                                        <i className='fa fa-eye'></i>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group row mb-4 p-1">
                                <label className="col-form-label">New Password</label>
                                <div className="input-group">
                                    <input
                                    type={showPassword ? 'text' : 'password'}
                                    data-indicator="pwindicator"
                                    className="form-control"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className='fa fa-eye'></i>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group row mb-4 p-1">
                                  <label className="col-form-label">Confirm New Password</label>
                                  <div className="input-group">
                                      <input
                                      type={showPasswordConfirmation ? 'text' : 'password'}
                                      className="form-control"
                                      value={password_confirmation}
                                      onChange={e => setPassword_confirmation(e.target.value)}
                                      />
                                      <div className="input-group-append">
                                      <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          onClick={togglePasswordConfirmationVisibility}
                                      >
                                          <i className='fa fa-eye'></i>
                                      </button>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="text-center mt-5">
                                    <p className='text-danger'>{password_confirmationError}</p>
                                    <label className="col-form-label">
                                    </label>
                                    {loader === 'off' &&
                                        <button type="submit" onClick={submitPasswordForm} className="btn btn-primary">Update</button>
                                    }
                                    {loader === 'on' &&
                                            <div class="spinner-border text-dark" role="status"></div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default UserProfile