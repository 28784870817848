import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import $ from 'jquery'; 
import 'datatables.net';
import { ProtectAPi } from '../../../../../api/ProtectAPi';
import {ServerUrl} from '../../../../../api/ServerUrl';
import swal from 'sweetalert';
import { SetPermission } from '../../../../../auth/SetPermission';

const BannerList = () => {

    const { http } = ProtectAPi();
    const httpCPage = ServerUrl;
    const tableRef = useRef(null);
    const [banners, setBanners] = useState([]);
    const userPermission = SetPermission();
  
    useEffect(() => {
      http.get('/setting-get-banner')
        .then(res => { 
            setBanners(res.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    }, []);
  
    
    useEffect(() => {
        if (banners.length > 0 && !tableRef.current.DataTable) {
            $(tableRef.current).DataTable();
        } else {
            if ($.fn.DataTable.isDataTable('#table-1')) {
                $(tableRef.current).DataTable().destroy();
            }
        }
    }, [banners]);


    // delete roles
    const handleDeleteRole = (roleId) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this banner!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                http.delete(`/delete-single-banner/${roleId}`)
                   .then(response => {
                       if(response.data.status !== 201){
                        setBanners(prevRoles => prevRoles.filter(banners => banners.id !== roleId));
                       }
                        
                        swal(response.data.msg, {
                            icon: response.data.alert,
                        });
                   })
                   .catch(error => {
                        swal('Oops! Something went wrong while deleting the banner.', {
                            icon: 'error',
                        });
                   });
                swal('Poof! The banner has been deleted!', {
                    icon: 'success',
                });
            } else {
                swal('The banner is safe!');
            }
        });
    };

  return (
    <div>
        <div className="main-content">
          <section className="section">
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                    <div className="card card-primary">
                        <div className="card-body">
                            <div className="row d-flex">
                                <div className="mb-2">
                                    <Link to="/admin/settings" className="btn border btn-sm mr-2">Web Info</Link>
                                </div>
                                <div className="mb-2">
                                    <Link to="/admin/settings/banner-list"  className="btn btn-primary btn-sm mr-2">Banner</Link>
                                </div>
                                <div className="mb-2">
                                    <Link to="/admin/settings/footer-list" className="btn border btn-sm mr-2">Footer</Link>
                                </div>
                                <div className="mb-2">
                                    <Link to="/admin/settings/terms-list" className="btn border btn-sm mr-2">Terms & Conditions</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div className="card">
                    <div className="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <h5>Banner List</h5>
                            <div class="text-end">
                                <Link to='/admin/settings/banner' className="btn btn-primary btn-sm">Add New Banner</Link>
                            </div>
                        </div>
                      <div className="table-responsive">
                        <table className="table table-striped" id="table-1" ref={tableRef}>
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Title</th>
                              <th>Status</th>
                              <th>Image</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {banners.map((banner, index) => (
                              <tr key={banner.id}>
                                <td>{index + 1}</td>
                                <td>{banner.bannerTitle}</td>
                                <td>
                                    {banner.status === 1 && <span className='badge badge-primary'>Active</span> }
                                    {banner.status === 0 && <span className='badge badge-danger'>De-Active</span> }
                                </td>
                                <td><img alt="" src={httpCPage + '/backend/img/' + banner.banner} width="100" /></td>
                                <td>
                                {userPermission.includes('home_all_edit') && (<Link to={`/admin/setting/banner-edit/${banner.id}`} className="editbtn mr-1">
                                      <button className="btn btn-warning">
                                        <i className="fas me-2 fa-edit text-white"></i>
                                      </button>
                                    </Link>
                                  )}
                                  {userPermission.includes('home_delete') && (<Link to='' className="editbtn">
                                        <button className="btn btn-danger" onClick={() => handleDeleteRole(banner.id)}>
                                            <i className="fas me-2 fa-trash text-white"></i>
                                        </button>
                                    </Link>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  )
}

export default BannerList