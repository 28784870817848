import React, { useState, useEffect } from 'react';
import { GoMoveToTop } from "react-icons/go";

const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const scrollThreshold = 400;
      setIsVisible(scrollTop > scrollThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {isVisible && (
        <div className="d-block scroll-to-top scroll-to-target" data-target="html" onClick={scrollToTop}>
          <GoMoveToTop />
        </div>
      )}
    </div>
  );
};

export default ScrollUp;
