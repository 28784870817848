import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import ScrollUp from './tools/ScrollUp'
import ChatBot from './tools/ChatBot'

const HomeLayout = () => {
  useEffect(() => {

    const addScript = (src) => {
      const script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }
    
    addScript(`${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`)

    return () => {
      // Cleanup stylesheets
      document.head.querySelectorAll('link').forEach(link => {
        if (link.href.includes('/admin_assets/css/')) {
          document.head.removeChild(link)
        }
      })

      // Cleanup scripts
      document.body.querySelectorAll('script').forEach(script => {
        if (script.src.includes('/admin_assets/js/') || script.src.includes('/admin_assets/bundles/')) {
          document.body.removeChild(script)
        }
      })
    }
  }, [])

  return (
    <div>
      <Header />
      <Outlet />
      <ScrollUp />
      <ChatBot />
      <Footer />
    </div>
  )
}

export default HomeLayout
