import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../api/Api';
import { ServerUrl } from '../api/ServerUrl';
import { FaRegCirclePlay } from "react-icons/fa6";
import { FrontUrl } from '../api/FrontUrl';
import CanvasModal from '../tools/CanvasModal';


const Banner = () => {
    const { http } = Api();
    const [banners, setBanners] = useState([]);
    const [notice, setNotice] = useState([]);

    useEffect(() => {
        http.get('/front-get-banner')
            .then(res => {
                setBanners(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    useEffect(() => {
        http.get('/front-get-notice')
            .then(res => {
                setNotice(res.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, []);

    

    const [showVideo, setShowVideo] = useState(false);

  const handleKnowMoreClick = () => {
    document.body.classList.add('overflow-hidden');
    setShowVideo(true);
  };

  const handleCloseModal = () => {
    document.body.classList.remove('overflow-hidden');
    setShowVideo(false);
  };
    

    return (
        <div>
            <section className="main-slider">
                <div className="slider-box">
                    <div className="backwhitebox">
                        <div className="topbox d-flex align-items-center">
                            <div className="container text-white">
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 d-flex flex-column justify-content-center order-2 order-md-1 pt-4 pt-lg-0 order-lg-1">
                                       <div className="banner-text-box mt-5">
                                            <h1>{banners.bannerTitle}</h1>
                                            <h2>{banners.bannerDescription}</h2>
                                            <div className="btns-box mt-2">
                                                <button onClick={handleKnowMoreClick} className="theme-btn banner-button">
                                                <span className="txt">Know more</span>
                                                <FaRegCirclePlay className='banner-play-icon' />
                                                </button>
                                            </div>
                                            
                                            {showVideo && (
                                                <CanvasModal className="VideoModal" onClose={handleCloseModal} videoUrl={`${FrontUrl}/assets/video/Foot Balance Technology - Experience The Pedorthic Difference.mp4`} />
                                            )}
                                       </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 order-1 order-md-2 order-lg-2">
                                        <div className="banner-image-box ms-2">
                                            <img src={`${ServerUrl}/backend/img/${banners.banner}`} className='bannerImg' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {notice.note &&
                <div className="noteboxfront">
                    <section className='topMessage shadow-lg'>
                        <div className="topMessageBox py-2 mx-5 px-5 pt-3">
                            <p>{notice.note}</p>
                        </div>
                    </section>

                    <div className="container py-4 MessageBoxSmall">
                        <section className='topMessageSmall p-3 rounded'>
                            <div className="topMessageBoxSmall">
                                <p>{notice.note}</p>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </div>
    );
}

export default Banner;
